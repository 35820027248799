import {Link} from 'react-router-dom';
import {ReactComponent as UserIcon} from 'assets/images/icons/user.svg';
import {ReactComponent as CaretUpIcon} from 'assets/images/icons/caret-up.svg';
import Table from 'utils/Table/Table';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';
import {userWalletInfoFilterParams, userWalletInfoHeadings} from '../../helpers/enums';
import {walletExportHeadings} from 'components/Dashboard/Transactions/enums';
import TableFooter from 'utils/Table/TableFooter/TableFooter';
import EmptyData from 'utils/Table/EmptyData';

const UserWalletInfo = ({
    title,
    wallets = [],
    showFilter,
    filterProps = {},
    pageInfo,
    hideMore,
}) => {
    const getWalletInfo = () =>
        wallets?.map(wallet => ({
            icon: <UserIcon height="32px" width="32px" />,
            reference: wallet?.reference,
            account_name: wallet?.account_name,
            account_number: wallet?.account_number,
            bank_name: wallet?.bank_name,
            details: (
                <>
                    <Link to={`/wallets/${wallet?.user_id}`}>
                        <CaretUpIcon className="rot-90 primary pointer" />
                    </Link>
                </>
            ),
        }));

    return (
        <SectionContainer>
            <SectionWrapper
                title={title}
                filterId="user-wallet-info-filter"
                filterParams={showFilter ? userWalletInfoFilterParams : undefined}
                showDownload
                reportHeadings={walletExportHeadings}
                downloadKey="account"
                {...filterProps}
            >
                {wallets?.length ? (
                    <>
                        <Table
                            headings={userWalletInfoHeadings}
                            data={getWalletInfo()}
                            rowClick={() => {}}
                        />

                        {pageInfo && <TableFooter {...pageInfo} />}
                    </>
                ) : (
                    <EmptyData
                        title="No Wallet Found"
                        subtitle="All Users wallet be displayed here"
                    />
                )}
            </SectionWrapper>
        </SectionContainer>
    );
};

export default UserWalletInfo;
