import axios from 'axios';
import {fetchUserData} from '../storage/sessionStorage';

const baseURL = 'https://meritrade-api.blusalt.net';

const API = axios.create({baseURL: baseURL});

API.interceptors.request.use(config => {
    config.headers = {'Content-Type': 'multipart/form-data'};

    const {token} = fetchUserData();

    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

API.interceptors.response.use(
    async response => response,
    async error => {
        return Promise.reject(error);
    }
);

export default API;
