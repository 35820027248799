import React from 'react';

//Components
import Modal from '../../../../../utils/Modal/Modal';

//Utils
import {SECONDARY} from '../../../../constants';
import EditUserForm from './EditUserForm/EditUserForm';

const EditUserModal = ({show, data, toggle, headerType = SECONDARY}) => {
    return (
        <Modal show={show} size="xl" title={`Edit User`} toggle={toggle} type={headerType}>
            <EditUserForm data={data} toggle={toggle} />
        </Modal>
    );
};

export default EditUserModal;
