import {gql} from '@apollo/client';

export const LOGIN_USER = gql`
    mutation LoginUser($data: AdminLoginInput!) {
        admin_login(data: $data) {
            first_name
            last_name
            role
            token
        }
    }
`;
