import DatePicker from 'react-datepicker';
import {ReactComponent as DateIcon} from '../../assets/images/icons/calender.svg';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({
    label,
    min,
    max,
    name,
    placeholder,
    value,
    error,
    onChange,
    required,
    cls,
    ...rest
}) => {
    const isError = error ? 'error' : '';
    const isRequired = required ? 'required' : '';

    return (
        <div className={`form-group ${cls}`}>
            {label && (
                <label htmlFor={name} className={`form-group-label ${isRequired}`}>
                    {label}
                </label>
            )}

            <div className={`input-wrapper ${isError} d-flex align-items-center`}>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    todayButton="Select Today"
                    isClearable
                    clearButtonTitle="Clear selected date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperPlacement="auto"
                    selected={value}
                    minDate={min}
                    maxDate={max}
                    placeholderText={placeholder}
                    className="w-100"
                    {...rest}
                    onChange={onChange}
                />

                <div className="px-2">
                    <DateIcon height="15" />
                </div>
            </div>

            {typeof error === 'string' && <small className="input-text-error">{error}</small>}
        </div>
    );
};

export default CustomDatePicker;
