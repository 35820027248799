import {combineReducers} from 'redux';
import {RESET_APP} from '../actionTypes/app';

const allReducer = combineReducers({});

const rootReducer = (state, action) => {
    if (action.type === RESET_APP) {
        state = undefined;
    }

    return allReducer(state, action);
};

export default rootReducer;
