import {useQuery} from '@apollo/client';
import CustomerTable from './CustomerTable/CustomerTable';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';
import usePageProps from 'hooks/usePageProps';
import {GET_USERS} from 'api/queries/customers';
import {customerFilterParams, customersExportHeadings} from './helpers/enums';

const Customers = () => {
    const {
        limit,
        query,
        filters,
        currentPage,
        handlePagination,
        handleFilters,
        clearFilters,
        handleLimitChange,
    } = usePageProps({
        filterParams: customerFilterParams,
    });

    const queryParams = {
        variables: {
            created_at: 'asc',
            limit: parseInt(limit),
            offset: currentPage * limit,
            ...query,
        },
    };

    const getCustomersQuery = useQuery(GET_USERS, queryParams);

    return (
        <SectionContainer>
            <SectionWrapper
                filters={filters}
                title={`All Customers (${
                    getCustomersQuery?.data?.user_aggregate?.aggregate?.count || 0
                })`}
                headerChildrenCls="wrapper__mt-3-mobile"
                headerChildrenNoWrap
                filterParams={customerFilterParams}
                filterId="customers-filter"
                navWideMobile
                showDownload
                clearFilters={clearFilters}
                handleFilters={handleFilters}
                query={GET_USERS}
                queryParams={queryParams}
                reportHeadings={customersExportHeadings}
                downloadKey="user"
            >
                <ContentWrapper errorTitle="Error fetching users" currentQuery={getCustomersQuery}>
                    <CustomerTable
                        limit={limit}
                        currentPage={currentPage}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                        data={getCustomersQuery?.data}
                    />
                </ContentWrapper>
            </SectionWrapper>
        </SectionContainer>
    );
};

export default Customers;
