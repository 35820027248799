const EmptyData = ({Icon, title, subtitle, btn}) => {
    const msg = (
        <span>
            You can start by creating one for yourself <br /> or on behalf of your customers
        </span>
    );

    return (
        <div className="mx-auto py-5 my-5 w-100 w-lg-50 flex__center flex__column text-center">
            {Icon && <Icon />}

            <h3 className="heading-1 fw-bold pt-3 pb-2">{title}</h3>
            <span className="flex__center px-0 px-lg-5 mx-0 mx-lg-5 fw-bold gray">
                {subtitle || msg}
            </span>

            <div className="flex__center pt-4">{btn && btn}</div>
        </div>
    );
};

export default EmptyData;
