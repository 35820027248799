import {ReactComponent as SwapIcon} from 'assets/images/icons/swap.svg';
import {ReactComponent as CompanyIcon} from 'assets/images/icons/company.svg';
import {ReactComponent as CustomerIcon} from 'assets/images/icons/customer.svg';
import {ReactComponent as RocketIcon} from 'assets/images/icons/charts/rocket.svg';
import {ReactComponent as DocumentIcon} from 'assets/images/icons/charts/document.svg';
import {ReactComponent as CreditCardIcon} from 'assets/images/icons/charts/credit-card.svg';

import MarketVolChartTab from '../VolumeCharts/MarketVolChartTab/MarketVolChartTab';

// Options

export const volumeChartsOptions = [
    'Daily',
    'Weekly',
    'Monthly',
    'Quarterly',
    'Biannually',
    'Annually',
];

// Headings

export const topUsersHeading = [
    'Logo',
    'Name',
    'Symbol',
    'Last Price (₦)',
    'Current Value (₦)',
    'Total Value (₦)',
    'Daily P/L (₦)',
    'Total P/L (₦)',
];

// Component Enums

const marketVolEnums = [
    {
        icon: <DocumentIcon />,
        id: 'Stock',
        progress: 30,
        value: 306,
    },
    {
        icon: <CreditCardIcon />,
        id: 'Money Market',
        progress: 90,
        value: 877,
    },
    {
        icon: <RocketIcon />,
        id: 'Bonds',
        progress: 72,
        value: 12,
    },
];

export const marketVolTabID = ['stock', 'money_market', 'bond'];

export const marketVolTabsList = marketVolEnums.map((vol, index) => (
    <MarketVolChartTab
        icon={vol.icon}
        key={index}
        progress={vol.progress}
        title={vol.id}
        value={vol.value}
    />
));

export const statsList = [
    {
        title: 'Total Customers',
        icon: <CustomerIcon />,
        progress: 65,
        value: 24601088,
    },
    {
        title: 'Total Companies',
        icon: <CompanyIcon />,
        progress: 30,
        value: 159204,
    },
    {
        title: 'Total Transactions',
        icon: <SwapIcon />,
        progress: 87,
        value: 1217903,
    },
    {
        title: 'Total Cash Volume',
        icon: <CustomerIcon />,
        progress: 54,
        value: 356584743,
    },
];

export const tabList = ['top_gainers', 'top_losers'];

export const Icons = {
    SwapIcon,
    CompanyIcon,
    CreditCardIcon,
    RocketIcon,
    DocumentIcon,
    CustomerIcon,
};
