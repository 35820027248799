import {gql} from '@apollo/client';

export const PAYMENT_TRANSACTIONS = gql`
    query PaymentTransactions(
        $limit: Int
        $offset: Int
        $created_at: order_by
        $user_id: Int_comparison_exp = {}
        $type: String_comparison_exp = {}
        $reference: uuid_comparison_exp = {}
        $completed: Boolean_comparison_exp = {}
        $time_range: timestamptz_comparison_exp = {}
    ) {
        payment_transaction(
            limit: $limit
            offset: $offset
            where: {
                user_id: $user_id
                reference: $reference
                type: $type
                created_at: $time_range
                completed: $completed
            }
            order_by: {created_at: $created_at}
        ) {
            amount
            completed
            created_at
            reference
            type
            channel
            user_id
            user {
                first_name
                last_name
            }
        }
        payment_transaction_aggregate(
            where: {user_id: $user_id, reference: $reference, type: $type, created_at: $time_range}
        ) {
            aggregate {
                count
            }
        }
    }
`;

export const PAYMENT_TRANSACTIONS_AGGREGATE = gql`
    query PaymentTransactionAggregate {
        payment_transaction_aggregate(where: {completed: {_eq: true}}) {
            aggregate {
                sum {
                    amount
                }
            }
        }
    }
`;

export const TODAY_PAYMENT_TRANSACTIONS = gql`
    query PaymentTransactionAggregate($time_range: timestamptz_comparison_exp = {}) {
        payment_transaction_aggregate(where: {completed: {_eq: true}, created_at: $time_range}) {
            aggregate {
                sum {
                    amount
                }
            }
        }
    }
`;
