import {FormControl, Select} from '@material-ui/core';
import {titleCase} from '../../helpers';

const SmallSelect = ({
    cls = 'd-flex small_select',
    name,
    value,
    formLabel = 'Age',
    label,
    onChange,
    options,
    rawOptions,
}) => {
    return (
        <span className={cls}>
            <FormControl variant="outlined">
                {label && <label className="mb-2 pb-1 fw-bold text--gray">{label}</label>}
                <Select
                    native
                    value={value}
                    onChange={onChange}
                    label={formLabel}
                    inputProps={{name}}
                >
                    <option aria-label="None" value="" />
                    {options?.map(option => (
                        <option value={option} key={option}>
                            {!rawOptions ? titleCase(option) : option}
                        </option>
                    ))}
                </Select>
            </FormControl>
        </span>
    );
};

export default SmallSelect;
