import {useEffect, useState} from 'react';
import {getRowSize} from 'storage/localStorage';
import {transformFilters, defaultFilterValues} from 'utils/helpers';

export default function usePageProps({filterParams = [], defaultFilters = {}}) {
    const [query, setQuery] = useState({});

    const [filters, setFilters] = useState({...defaultFilters});
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(() => getRowSize());

    useEffect(() => {
        Object.keys(defaultFilters)?.length > 0 &&
            transformFilters(defaultFilters, filterParams, setQuery);
    }, [defaultFilters, filterParams]);

    const handlePagination = ({selected}) => setCurrentPage(selected);

    const handleFilters = (filter = {}) => {
        const {data = {}} = filter;
        const newFilters = {...filters, ...data};
        setCurrentPage(0);
        setFilters(prev => ({...prev, ...data}));
        transformFilters(newFilters, filterParams, setQuery);
    };

    const clearFilters = () => {
        const newFilters = {
            ...defaultFilterValues(filterParams),
            ...defaultFilters,
        };

        setFilters(prev => ({...prev, ...newFilters}));
        transformFilters(newFilters, filterParams, setQuery);
        setCurrentPage(0);
    };

    const handleLimitChange = limit => {
        setCurrentPage(0);
        setLimit(limit);
    };

    return {
        filters,
        currentPage,
        handlePagination,
        limit,
        query,
        handleFilters,
        clearFilters,
        handleLimitChange,
    };
}
