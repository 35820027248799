import {useState} from 'react';
import {Accordion} from 'react-bootstrap';

const AccountType = ({value, user, setUser}) => {
    const [dir, setDir] = useState('down');

    const handleToggle = () => {
        if (dir === 'down') {
            setUser(user);
            setDir('up');
        } else {
            setUser({});
            setDir('down');
        }
    };
    return (
        <div className="flex__between">
            <span>{value}</span>
            <Accordion.Button className="col-2" onClick={handleToggle}></Accordion.Button>
        </div>
    );
};

export default AccountType;
