import {
    PROFILE,
    KYC,
    BANKING,
    PORTFOLIO,
    ORDERS,
    STATEMENTS,
    WATCHLIST,
    ALL,
    BUY,
    SELL,
} from '../../../constants';

// Options

export const customerOptions = ['Edit Profile', 'Deactivate'];

// Table Headings

export const customersHeadings = [
    '',
    'Username',
    'Email',
    'Wallet ID',
    'Phone Number',
    'Account Type',
];

// TabLists

export const detailsTabList = [PROFILE, KYC, BANKING, PORTFOLIO, ORDERS, STATEMENTS, WATCHLIST];

export const customerFilterParams = [
    {
        name: 'val',
        label: 'Val',
        query: '_iregex',
        hide: true,
    },
    {
        name: 'username',
        label: 'Username',
        query: '_iregex',
    },
    {
        name: 'email',
        label: 'Email',
        query: '_iregex',
    },
    {
        name: 'accountType',
        label: 'Account Type',
        placeholder: 'Type Here',
        query: '_eq',
        options: [
            {
                label: 'Individual',
                value: 'INDIVIDUAL',
            },
            {
                label: 'Organization',
                value: 'ORGANIZATION',
            },
        ],
    },
];

export const customersExportHeadings = {
    id: 'ID',
    first_name: 'Firstname',
    last_name: 'Lastname',
    email: 'Email',
    phone: 'Phone Number',
    type_id: 'Account Type',
};

export const orderExportHeadings = {
    reference: 'Transaction Reference',
    'user.first_name': 'First Name',
    'user.last_name': 'Last Name',
    amount: 'Amount Funded',
    type: 'Transaction Type',
    channel: 'Payment Method',
    completed: 'Transaction Status',
    created_at: 'Date',
};

export const ordersHeadings = [
    'Order No',
    'Date',
    'Order Type',
    'Total',
    'Symbol',
    'Price Type',
    'Terms',
    'Order Status',
    'Quantity',
    'Quantity Filled',
    'Quantity Pending',
    '',
];

export const orderFilterParams2 = [
    {
        name: 'accountType',
        label: 'Account Type',
        placeholder: 'Type Here',
        query: '_eq',
        options: [
            {
                label: 'Individual',
                value: 'INDIVIDUAL',
            },
            {
                label: 'Organization',
                value: 'ORGANIZATION',
            },
        ],
    },
];

export const OrderStatusOptions = [
    {label: ALL, value: ALL},
    {
        label: 'Executed',
        value: 'EXECUTED',
    },
    {
        label: 'Expired',
        value: 'EXPIRED',
    },
    {
        label: 'Cancelled',
        value: 'CANCELLED',
    },
    {
        label: 'Order is being executed',
        value: 'EXECUTING',
    },
];

export const OrderTypeOptions = [
    {label: ALL, value: ALL},
    {label: BUY, value: 'BUY'},
    {label: SELL, value: 'SELL'},
];

export const orderStatementHeadings = [
    'Contract No',
    'Order No',
    'Transaction Date',
    'Price',
    'Quality',
    'Symbol',
    'Contract Amount (NGN)',
    'Download',
];

export const cashHeading = [
    'Name',
    'Description',
    'Currency',
    'Debit',
    'Credit',
    'Balance',
    'Transaction Date',
    'Value Date',
];
