import {toast} from 'react-toastify';
import {ERROR, SUCCESS, WARNING, INFO} from '../../../components/constants';
import 'react-toastify/dist/ReactToastify.css';

const Toast = ({msg, variant, position = 'top-left'}) => {
    const options = {
        position,
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        limit: 1,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
    };

    toast.dismiss();

    switch (variant) {
        case SUCCESS:
            toast.success(msg, options);
            break;

        case ERROR:
        default:
            toast.error(msg, options);
            break;

        case WARNING:
            toast.warn(msg, options);
            break;

        case INFO:
            toast.info(msg, options);
            break;
    }
};

export default Toast;
