import {gql} from '@apollo/client';

export const AGGREGATE_COUNT = gql`
    query AggregateCounts {
        allUsers: user_aggregate {
            aggregate {
                count
            }
        }
        payment_transaction_aggregate {
            aggregate {
                sum {
                    amount
                }
            }
        }
        organizationUsers: user_aggregate(where: {type_id: {_eq: ORGANIZATION}}) {
            aggregate {
                count
            }
        }
    }
`;
