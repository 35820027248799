import React, {useState} from 'react';
import {ReactComponent as EyeIcon} from '../../../assets/images/icons/eye-off.svg';

const Password = ({
    id,
    name,
    value,
    error,
    label,
    onChange,
    placeholder = 'Password',
    required,
    ...rest
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const isError = error ? 'error' : '';
    const isRequired = required ? 'required' : '';

    return (
        <div className="form-group">
            {label && (
                <label htmlFor={name} className={`form-group-label ${isRequired}`}>
                    {label}
                </label>
            )}

            <div className={`input-wrapper ${isError} d-flex align-items-center`}>
                <input
                    type={showPassword ? 'text' : 'password'}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    autoComplete="off"
                    onChange={onChange}
                    {...rest}
                />

                <div className={`px-2 ${showPassword ? 'show__password' : ''}`}>
                    <EyeIcon width="30px" onClick={() => setShowPassword(!showPassword)} />
                </div>
            </div>

            {typeof error === 'string' && <small className="input-text-error">{error}</small>}
        </div>
    );
};

export default Password;
