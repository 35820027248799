import React from 'react';
import KYCItem from '../KYCItem/KYCItem';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';

const BankInfo = ({account = {}}) => {
    const {account_name, account_number, bank_name} = account || {};
    const acct = [
        account_name ?? 'Account Name',
        account_number ?? 'Account Number',
        bank_name ?? 'Bank Name',
    ];

    return (
        <SectionWrapper>
            <div className="flex__between flex-wrap">
                {acct?.map((item, index) => (
                    <KYCItem cls="wrapper__width_31_perc" item={item} key={index} />
                ))}
            </div>
        </SectionWrapper>
    );
};

export default BankInfo;
