const CustomInputBox = ({
    type = 'text',
    name,
    label,
    labelIcon,
    placeholder = 'Type Here',
    value,
    readonly,
    error,
    hide,
    icon,
    iconText,
    onChange,
    onFocus,
    required,
    question,
    cls,
    disabled,
    grayInput,
    min,
    ...rest
}) => {
    const isError = error ? 'error' : ' ';
    const isRequired = required ? 'required' : ' ';
    const isDisabled = disabled ? 'disabled' : ' ';
    const otherRest = type === 'number' ? {min: min ?? 0} : {};

    return (
        <div className={`form-group ${cls}`}>
            {label && (
                <label htmlFor={name} className={`form-group-label d-flex ${isRequired}`}>
                    {label}
                    {question && <div className="question">?</div>}
                    {labelIcon && <div className="ms-2 pointer">{labelIcon}</div>}
                </label>
            )}
            <div
                className={`input-wrapper ${
                    grayInput ? 'gray-input' : ''
                } flex__center ${isDisabled} ${isError} `}
            >
                {icon && <div className="ps-3 pe-3">{icon}</div>}
                {!hide && (
                    <input
                        className={grayInput ? 'gray-input' : ''}
                        type={type}
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        readOnly={readonly}
                        autoComplete="off"
                        disabled={disabled}
                        onChange={onChange}
                        {...rest}
                        {...otherRest}
                    />
                )}
                {iconText && <div className="px-4">{iconText}</div>}
            </div>
            {typeof error === 'string' && <small className="input-text-error">{error}</small>}
        </div>
    );
};

export default CustomInputBox;
