import React, {useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import ValuationBalance from './ValuationBalance';
import Table from 'utils/Table/Table';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';
import {toCurrency} from 'utils/helpers';
import {portfolioInfoHeading} from '../../common/helpers/enums';
import {GET_PORTFOLIOS} from 'api/queries/portfolio';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import EmptyData from 'utils/Table/EmptyData';
import CustomSelect from 'utils/Select/CustomSelect/CustomSelect';

const CustomerPortfolio = ({bordered, account, accounts, setAccount, customerId}) => {
    const [fetchPortfolio, getPortfolioQuery] = useLazyQuery(GET_PORTFOLIOS);

    useEffect(() => {
        accounts?.portfolio_accounts && handleFetch(accounts?.portfolio_accounts[0]?.id);
        // eslint-disable-next-line
    }, [accounts]);

    const handleSelect = selected => {
        setAccount(selected);
        handleFetch(selected?.id);
    };

    const handleFetch = id => {
        fetchPortfolio({
            variables: {customerId, id},
        });
    };

    const {portfolio} = getPortfolioQuery?.data || {};

    const getTableData = () => {
        const getColor = gain => (gain >= 0 ? 'text-success' : 'text-danger');
        return portfolio?.map(port => ({
            name: port?.securityLabel,
            id: port?.securityName,
            lastPrice: toCurrency(port?.marketPrice),
            quantity: port?.quantityHeld,
            pricePaid: toCurrency(port?.costBasis),
            currentValue: toCurrency(port?.marketValue),
            totalCost: toCurrency(port?.quantityHeld * port?.costBasis * port?.priceValueMultiple),
            totalPL: <span className={getColor(port?.gain)}>{toCurrency(port?.gain)}</span>,
            totalPL2: <span className={getColor(port?.percentGain)}>{port?.percentGain}</span>,
        }));
    };

    return (
        <>
            {!bordered && (
                <div className="flex__between mt-4 mb-4 mx-4 px-3">
                    <span className="heading7">Summary</span>
                    <CustomSelect
                        name="portfolio"
                        formGroupCls="wrapper__width_31_perc zero-height"
                        onChange={value => handleSelect(value)}
                        options={accounts?.portfolio_accounts || []}
                        placeholder="Select Portfolio"
                        value={account}
                        getOptionLabel={({label}) => label}
                    />
                </div>
            )}
            <div
                className={`wrapper__section${bordered ? '_bordered' : ' mx-4'} bg-white px-4 py-4`}
            >
                <SectionContainer>
                    <SectionWrapper cls="flex__between flex-wrap" dashed>
                        <ValuationBalance
                            account={account}
                            cash_balance={accounts?.cash_balance}
                            portfolios={portfolio}
                        />
                    </SectionWrapper>
                </SectionContainer>

                <ContentWrapper
                    errorTitle="Error fetching customer portfolio"
                    currentQuery={getPortfolioQuery}
                    cls="mt-3"
                >
                    {portfolio?.length ? (
                        <Table
                            cls="wrapper__z-index-neg-25 table__portfolio_table"
                            data={getTableData()}
                            headings={portfolioInfoHeading}
                            rowClick={() => {}}
                        />
                    ) : (
                        <EmptyData
                            title="No Portfolio found for this customer"
                            subtitle="Customer Portfolios will be displayed here"
                        />
                    )}
                </ContentWrapper>
            </div>
        </>
    );
};

export default CustomerPortfolio;
