import {timeRangeFormat} from 'utils/helpers';

export const PAGE_INFO = 'meritrade_admin_page_info';
export const CURRENT_FILTERS = 'meritrade_admin_current_filters';

export const NORMAL = 'NORMAL';
export const PRIMARY = 'PRIMARY';
export const HEADLESS = 'HEADLESS';
export const SECONDARY = 'SECONDARY';
export const HEADLESS_CLOSE_ROUNDED = 'HEADLESS_CLOSE_ROUNDED';

export const INFO = 'INFO';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const WARNING = 'WARNING';

export const TRADE = 'TRADE';
export const WALLET = 'WALLET';

export const ALL = 'All';
export const FUND = 'Fund';
export const TRANSFER = 'Transfer';
export const WITHDRAWAL = 'Withdrawal';
export const ALL_TRANSACTIONS = 'All_Transactions';
export const WITHDRAW = 'Withdraw';

export const SELL = 'Sell';
export const CASH = 'Cash';
export const USER = 'User';
export const MARGIN = 'Margin';
export const BUY = 'Buy';

export const BONDS = 'Bonds';
export const STOCKS = 'Stocks';
export const STOCK_NGX = 'Stock (NGX)';
export const STOCK_NASD = 'Stock (NASD)';
export const MONEY_MARKET = 'Money Market';
export const PORTFOLIO_NGX = 'Portfolio (NGX)';
export const PORTFOLIO_NASD = 'Portfolio (NASD)';

export const BENEFITS = 'BENEFITS';
export const REPORTS = 'REPORTS';

export const LIST = 'LIST';
export const DETAILS = 'DETAILS';

export const WEEK = 'WEEK';
export const YEAR = 'YEAR';
export const MONTH = 'MONTH';
export const TODAY = 'TODAY';
export const QUARTER = 'QUARTER';
export const BIANNUAL = 'BIANNUAL';

export const PROFILE = 'Profile';
export const KYC = 'KYC';
export const BANKING = 'Banking';
export const INVESTMENT = 'Investment';
export const PORTFOLIO = 'Portfolio';
export const TRANSACTIONS = 'Transactions';
export const ORDERS = 'Orders';
export const STATEMENTS = 'Statements';
export const WATCHLIST = 'Watchlist';

export const ROLES = {
    SUPER_ADMIN: 'super_admin',
    CUSTOMER_SUPPORT: 'customer_support',
    OPERATIONS: 'operations',
};

export const defaultDateFilters = {
    from: timeRangeFormat(new Date(), true),
    to: timeRangeFormat(new Date()),
};

export const defaultDateFiltersNew = {
    start_date: defaultDateFilters.from,
    end_date: defaultDateFilters.to,
};

export const ACCEPT = 'ACCEPT';
export const DECLINE = 'DECLINE';
export const CREATED = 'CREATED';
export const DECLINED = 'DECLINED';
export const APPROVED = 'APPROVED';
