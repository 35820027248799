import {useQuery} from '@apollo/client';
import {GET_WATCHLIST} from 'api/queries/watchList';
import Accordion from 'utils/Accordion/Accordion';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';
import WatchListDetails from './WatchListDetails/WatchListDetails';
import EmptyData from 'utils/Table/EmptyData';
import {titleCase} from 'utils/helpers';

const WatchList = ({customerId}) => {
    const getWatchListQuery = useQuery(GET_WATCHLIST, {variables: {id: customerId}});
    const {watchlist = []} = getWatchListQuery?.data || {};

    const watchListAccordion = watchlist?.map(v => ({
        title: titleCase(v.name),
        body: <WatchListDetails key={v?.name} items={v?.watchlist_items} />,
    }));

    return (
        <SectionContainer cls="mx-4 mt-4">
            <ContentWrapper
                errorTitle="Error fetching customer watch-list"
                currentQuery={getWatchListQuery}
            >
                {watchlist?.length ? (
                    <Accordion
                        cls="customer-accordion"
                        id="watchlist-accordion"
                        itemsList={watchListAccordion}
                    />
                ) : (
                    <EmptyData
                        title="No Watch-list Available Yet"
                        subtitle="All Users watch-list will be displayed here"
                    />
                )}
            </ContentWrapper>
        </SectionContainer>
    );
};

export default WatchList;
