import React, {useState} from 'react';
import CustomSelect from 'utils/Select/CustomSelect/CustomSelect';
import CustomDatePicker from 'utils/CustomDatePicker/CustomDatePicker';
import {formValidator} from 'utils/FormValidator/FormValidator';
import {checkSameDay, titleCase} from 'utils/helpers';
import {OrderStatusOptions, OrderTypeOptions} from '../../helpers/enums';
import {ALL} from 'components/constants';

const all = {label: ALL, value: 'All'};

const CustomerOrderFilters = ({accounts, currentFilters, getOrders, setIsToday, customerId}) => {
    const [isFilter, setIsFilter] = useState(false);
    const [filters, setFilters] = useState(currentFilters);

    let allAccounts = accounts.map(v => ({
        ...v,
        value: v.id,
        label: `${titleCase(v.portfolio_type)} - ${v.exchange} (${v.id})`,
    }));
    allAccounts = [{...all}, ...allAccounts];

    const handleDate = (name, value) => {
        handleForm(name, value);
        checkSameDay(new Date(), value) ? setIsToday(true) : setIsToday(false);
    };

    const handleForm = (name, value) => {
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (filters) {
            setIsFilter(true);
            const {isValid} = formValidator(filters);
            if (isValid) {
                setIsFilter(true);
                getOrders({
                    variables: {arg: {...getAllFilters(filters)}},
                });
            }
        }
    };

    const getAllFilters = allFilters => {
        const {type, status, portfolio_id} = allFilters || {};
        return {
            ...allFilters,
            admin_account_id: parseInt(customerId),
            type: type?.value === ALL ? '' : type?.value,
            status: status?.value === ALL ? '' : status?.value,
            portfolio_id: portfolio_id?.value === ALL ? '' : portfolio_id?.id,
        };
    };

    const clearFilter = () => {
        setIsFilter(false);
        setIsToday(true);
        setFilters(currentFilters);
        getOrders({
            variables: {arg: {...getAllFilters(currentFilters)}},
        });
    };

    return (
        <form
            className="col-12 d-flex flex__between_tab flex-wrap pt-3 transition--ease"
            onSubmit={handleSubmit}
        >
            <CustomSelect
                formGroupCls="me-4 col-12 col-sm-5 col-lg-2"
                label="Portfolio"
                name="portfolio_id"
                onChange={value => handleForm('portfolio_id', value)}
                options={allAccounts}
                placeholder="Select portfolio"
                value={filters?.portfolio_id}
            />
            <CustomSelect
                formGroupCls="me-4 col-12 col-sm-5 col-lg-2"
                label="Order Type"
                name="type"
                onChange={value => handleForm('type', value)}
                options={OrderTypeOptions}
                placeholder="Select type"
                value={filters?.type}
            />
            <CustomSelect
                formGroupCls="me-4 col-12 col-sm-5 col-lg-2"
                label="Order Status"
                name="status"
                onChange={value => handleForm('status', value)}
                options={OrderStatusOptions}
                placeholder="Select Status"
                value={filters?.status}
            />

            <CustomDatePicker
                cls="me-4 col-12 col-sm-5 col-lg-2"
                label="Start Date"
                name="start_date"
                placeholder="Start"
                max={new Date()}
                value={filters?.start_date}
                onChange={e => handleDate('start_date', e)}
            />

            <CustomDatePicker
                label="End Date"
                name="end_date"
                placeholder="End"
                min={filters?.start_date}
                max={new Date()}
                value={filters?.end_date}
                cls="me-4 col-12 col-sm-5 col-lg-2"
                onChange={e => handleDate('end_date', e)}
            />

            <div className="col-6 col-sm-5 col-lg-2 me-4 flex__start__vertical__center">
                <button className="btn btn--lg btn--form btn--primary">Search</button>
            </div>
            {isFilter && (
                <div className="col flex__start__vertical__center">
                    <button
                        onClick={clearFilter}
                        className="btn btn--sm btn--form btn--danger px-4"
                    >
                        Clear
                    </button>
                </div>
            )}
        </form>
    );
};

export default CustomerOrderFilters;
