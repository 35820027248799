import {ReactComponent as UbaIcon} from '../../../../assets/images/icons/logos/uba.svg';
import {ReactComponent as ArlaIcon} from '../../../../assets/images/icons/logos/arla.svg';
import {ReactComponent as DanoIcon} from '../../../../assets/images/icons/logos/dano.svg';
import {ReactComponent as MiloIcon} from '../../../../assets/images/icons/logos/milo.svg';
import {ReactComponent as UberIcon} from '../../../../assets/images/icons/logos/uber.svg';
import {ReactComponent as OandoIcon} from '../../../../assets/images/icons/logos/oando.svg';
import {ReactComponent as AirtelIcon} from '../../../../assets/images/icons/logos/airtel.svg';
import {ReactComponent as ZenithIcon} from '../../../../assets/images/icons/logos/zenith.svg';
import {ReactComponent as DominosIcon} from '../../../../assets/images/icons/logos/dominos.svg';
import {ReactComponent as SpotifyIcon} from '../../../../assets/images/icons/logos/spotify.svg';
import {ReactComponent as FirstBankIcon} from '../../../../assets/images/icons/logos/first-bank.svg';
import {ReactComponent as BusinessDayIcon} from '../../../../assets/images/icons/logos/business-day.svg';

export const topGainersList = [
    {
        currentValue: 148900.63,
        dailyPL: 166.31,
        name: 'Arla',
        id: 'ARLA',
        lastPrice: 7.5,
        logo: <ArlaIcon />,
        totalValue: 197600,
        totalPL: 46.44,
    },
    {
        currentValue: 875.4,
        dailyPL: 71.11,
        name: 'Airtel',
        id: 'ARTL',
        lastPrice: 7.5,
        logo: <AirtelIcon />,
        totalValue: 880,
        totalPL: 46.44,
    },
    {
        currentValue: 148900.63,
        dailyPL: -166.31,
        name: 'Business Day',
        id: 'BNDY',
        lastPrice: 7.5,
        logo: <BusinessDayIcon />,
        totalValue: 197600,
        totalPL: 46.44,
    },
    {
        currentValue: 154095.43,
        dailyPL: 123.22,
        name: 'Dano',
        id: 'DNO',
        lastPrice: 4.3,
        logo: <DanoIcon />,
        totalValue: 244484,
        totalPL: 0.0,
    },
    {
        currentValue: 574395.43,
        dailyPL: -32,
        name: 'FBN',
        id: 'FBN',
        lastPrice: 5.6,
        logo: <FirstBankIcon />,
        totalValue: 459321,
        totalPL: 12.98,
    },
    {
        currentValue: 548320.43,
        dailyPL: -88.43,
        name: 'Milo',
        id: 'MLO',
        lastPrice: 9.7,
        logo: <MiloIcon />,
        totalValue: 87.43,
        totalPL: 23.43,
    },
    {
        currentValue: 148900.63,
        dailyPL: 166.31,
        name: 'Arla',
        id: 'ARLA',
        lastPrice: 7.5,
        logo: <ArlaIcon />,
        totalValue: 197600,
        totalPL: 46.44,
    },
    {
        currentValue: 875.4,
        dailyPL: 71.11,
        name: 'Airtel',
        id: 'ARTL',
        lastPrice: 7.5,
        logo: <AirtelIcon />,
        totalValue: 880,
        totalPL: 46.44,
    },
    {
        currentValue: 148900.63,
        dailyPL: 166.31,
        name: 'Business Day',
        id: 'BNDY',
        lastPrice: 7.5,
        logo: <BusinessDayIcon />,
        totalValue: 197600,
        totalPL: 46.44,
    },
    {
        currentValue: 154095.43,
        dailyPL: 123.22,
        name: 'Dano',
        id: 'DNO',
        lastPrice: 4.3,
        logo: <DanoIcon />,
        totalValue: 244484,
        totalPL: 0,
    },
    {
        currentValue: 574395.43,
        dailyPL: 32,
        name: 'FBN',
        id: 'FBN',
        lastPrice: 5.6,
        logo: <FirstBankIcon />,
        totalValue: 459321,
        totalPL: 12.98,
    },
    {
        currentValue: 548320.43,
        dailyPL: -88.43,
        name: 'Milo',
        id: 'MLO',
        lastPrice: 9.7,
        logo: <MiloIcon />,
        totalValue: 87.43,
        totalPL: 23.43,
    },
];

export const topLosersList = [
    {
        currentValue: 348320.43,
        dailyPL: -188.43,
        name: 'Oando',
        id: 'OND',
        lastPrice: 3.7,
        logo: <OandoIcon />,
        totalValue: 823437.43,
        totalPL: -23.43,
    },
    {
        currentValue: 954720.43,
        dailyPL: -843.43,
        name: 'UBA',
        id: 'UBA',
        lastPrice: 7.7,
        logo: <UbaIcon />,
        totalValue: 883437.43,
        totalPL: -484.13,
    },
    {
        currentValue: 96320.43,
        dailyPL: 138.43,
        name: 'Zenith',
        id: 'ZNTH',
        lastPrice: 3.7,
        logo: <ZenithIcon />,
        totalValue: 853437.43,
        totalPL: -57.0,
    },
    {
        currentValue: 347498.0,
        dailyPL: 268.43,
        name: "Domino's",
        id: 'DNO',
        lastPrice: 6.7,
        logo: <DominosIcon />,
        totalValue: 223437.43,
        totalPL: -99.03,
    },
    {
        currentValue: 965320.43,
        dailyPL: -98.73,
        name: 'Uber',
        id: 'UBR',
        lastPrice: 12.7,
        logo: <UberIcon />,
        totalValue: 437.43,
        totalPL: -12.43,
    },
    {
        currentValue: 948320.43,
        dailyPL: -58.43,
        name: 'Spotify',
        id: 'STF',
        lastPrice: 3.7,
        logo: <SpotifyIcon />,
        totalValue: 3437.43,
        totalPL: -10.43,
    },
    {
        currentValue: 348320.43,
        dailyPL: -188.43,
        name: 'Oando',
        id: 'OND',
        lastPrice: 3.7,
        logo: <OandoIcon />,
        totalValue: 823437.43,
        totalPL: -23.43,
    },
    {
        currentValue: 954720.43,
        dailyPL: -843.43,
        name: 'UBA',
        id: 'UBA',
        lastPrice: 7.7,
        logo: <UbaIcon />,
        totalValue: 883437.43,
        totalPL: -484.13,
    },
    {
        currentValue: 96320.43,
        dailyPL: 138.43,
        name: 'Zenith',
        id: 'ZNTH',
        lastPrice: 3.7,
        logo: <ZenithIcon />,
        totalValue: 853437.43,
        totalPL: -57.0,
    },
    {
        currentValue: 347498.0,
        dailyPL: 268.43,
        name: "Domino's",
        id: 'DNO',
        lastPrice: 6.7,
        logo: <DominosIcon />,
        totalValue: 223437.43,
        totalPL: -99.03,
    },
    {
        currentValue: 965320.43,
        dailyPL: -98.73,
        name: 'Uber',
        id: 'UBR',
        lastPrice: 12.7,
        logo: <UberIcon />,
        totalValue: 437.43,
        totalPL: -12.43,
    },
    {
        currentValue: 948320.43,
        dailyPL: -58.43,
        name: 'Spotify',
        id: 'STF',
        lastPrice: 3.7,
        logo: <SpotifyIcon />,
        totalValue: 3437.43,
        totalPL: -10.43,
    },
];

// Market Volume Chart Data

export const totalMarketVolChartData = [
    {
        month: 'Jan',
        value: 800000,
    },
    {
        month: 'Feb',
        value: 600500,
    },
    {
        month: 'Mar',
        value: 410000,
    },
    {
        month: 'Apr',
        value: 980000,
    },
    {
        month: 'May',
        value: 680000,
    },
    {
        month: 'Jun',
        value: 850000,
    },
    {
        month: 'Jul',
        value: 550000,
    },
    {
        month: 'Aug',
        value: 405000,
    },
    {
        month: 'Sept',
        value: 700000,
    },
];

export const totalCashVolChartData = [
    {
        month: 'Jan',
        stocks: 10000000,
        bonds: 10000000,
        moneyMarket: 10000000,
    },
    {
        month: 'Feb',
        stocks: 16000500,
        bonds: 65005000,
        moneyMarket: 8000000,
    },
    {
        month: 'Mar',
        stocks: 41000000,
        bonds: 14000000,
        moneyMarket: 50000000,
    },
    {
        month: 'Apr',
        stocks: 98000000,
        bonds: 61080000,
        moneyMarket: 38000000,
    },
    {
        month: 'May',
        stocks: 68000000,
        bonds: 50000000,
        moneyMarket: 80000000,
    },
    {
        month: 'Jun',
        stocks: 85000000,
        bonds: 75000000,
        moneyMarket: 60000000,
    },
    {
        month: 'Jul',
        stocks: 55000000,
        bonds: 50050000,
        moneyMarket: 42000000,
    },
    {
        month: 'Aug',
        stocks: 40500000,
        bonds: 74005000,
        moneyMarket: 65000000,
    },
    {
        month: 'Sept',
        stocks: 70000000,
        bonds: 78500000,
        moneyMarket: 15000000,
    },
    {
        month: 'Oct',
        stocks: 72000000,
        bonds: 72000000,
        moneyMarket: 80000000,
    },
];
