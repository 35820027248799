import React, {useState} from 'react';
import {ReactComponent as DocumentIcon} from 'assets/images/icons/document.svg';
import FilePreviewModal from '../../../common/modals/FilePreviewModal/FilePreviewModal';

const FilePreviewButton = ({file}) => {
    const [openModal, setOpenModal] = useState(false);
    const toggleModal = () => setOpenModal(prev => !prev);

    return (
        <>
            <div
                className="wrapper__small_pill d-flex wrapper__file_pill me-4 pointer"
                onClick={toggleModal}
            >
                <DocumentIcon className="me-3" />
                <div className="wrapper__overflow">{file?.title}</div>
            </div>

            {openModal && (
                <FilePreviewModal
                    acceptAction={() => {}}
                    declineAction={() => {}}
                    file={file}
                    headerTitle="Report Information"
                    show={openModal}
                    showFileOptions
                    toggle={toggleModal}
                />
            )}
        </>
    );
};

export default FilePreviewButton;
