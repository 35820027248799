import StatsRow from './StatsRow/StatsRow';
// import TopStocks from "./TopStocks/TopStocks";
import VolumeCharts from './VolumeCharts/VolumeCharts';
import RecentWalletTransactions from '../common/Transactions/RecentWalletTransactions';

const DashContent = () => {
    return (
        <>
            <StatsRow />
            <VolumeCharts />
            {/* <TopStocks /> */}
            <RecentWalletTransactions />
        </>
    );
};

export default DashContent;
