import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useApolloClient} from '@apollo/client';
import $ from 'jquery';
import {Switch, Route, withRouter, Redirect, useLocation} from 'react-router-dom';
import {resetApp} from 'redux/actions/appActions';
import Sidebar from './Layout/Sidebar/Sidebar';
import Subroutes from './Layout/Subroutes/Subroutes';
import Header from './Layout/Header/Header';
import Routes from './routes';

import './Dashboard.scss';
import {deleteUserData} from 'storage/sessionStorage';

const Dashboard = ({history, location}) => {
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const client = useApolloClient();

    const headerName = Routes.find(route => {
        if (route?.dropDowns?.length > 0) {
            return route?.dropDowns?.find(({path}) => path === pathname);
        }
        return route?.path === pathname;
    })?.name;

    useEffect(() => {
        $('#sidebar-toggler').on('click', function () {
            $('.sidebar').toggleClass('active');
            $('.small-sidebar').toggleClass('active');
            $('.main').toggleClass('active');
        });
    }, []);

    const handleLogout = async () => {
        deleteUserData();
        client.clearStore();
        dispatch(resetApp());
        history.replace('/auth/login');
    };

    const getRoutes = () => (
        <Switch>
            <Subroutes routes={Routes} />
            <Route exact path="*" render={() => <Redirect to="/" />} />
        </Switch>
    );

    return (
        <div className="position-relative">
            <section className="container">
                <Sidebar location={location} history={history} handleLogout={handleLogout} />

                <div className="main">
                    <Header headerName={headerName} />
                    <div className="content bg-main">{getRoutes()}</div>
                </div>
            </section>
        </div>
    );
};

export default withRouter(Dashboard);
