import React from 'react';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';

const FacialComparison = ({account = {}}) => {
    const fcc = account?.facial_comparison_confidence || '';

    return (
        <SectionWrapper>
            <div className="flex__between flex-wrap">
                <h4>
                    Facial Comparison Confidence:{' '}
                    <b>
                        {fcc || 'None'} {fcc ? '%' : ''}
                    </b>
                </h4>
            </div>
        </SectionWrapper>
    );
};

export default FacialComparison;
