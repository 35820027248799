import React, {useState} from 'react';
import {useMutation} from '@apollo/client';

//SVG Icons

//Components
import Toast from 'utils/Alert/Toast/Toast';
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';
import CustomInputBox from 'utils/CustomInputBox/CustomInputBox';
import CustomSelect from 'utils/Select/CustomSelect/CustomSelect';
import CustomDatePicker from 'utils/CustomDatePicker/CustomDatePicker';
import CustomBuiltSelect from 'utils/Select/CustomBuiltSelect/CustomBuiltSelect';

//Utils
import {getError} from 'utils/helpers';
import {SUCCESS} from 'components/constants';
import {GET_ALL_COMPANIES} from 'api/queries/stocks';
import {formValidator} from 'utils/FormValidator/FormValidator';
import {EDIT_CORPORATE_BENEFITS} from 'api/mutations/corporate';
import {interimOptions} from 'components/Dashboard/Research/helpers/enums';

const EditBenefitForm = ({toggle, data}) => {
    const [formErrors, setFormErrors] = useState({});
    const [request, setRequest] = useState({
        ...data,
        final: interimOptions.find(option => option?.value === data?.final),
        closure_date: new Date(data?.closure_date),
        declaration_date: new Date(data?.declaration_date),
        qualification_date: new Date(data?.qualification_date),
    });

    const handleSelect = (name, value) => {
        setRequest(prev => ({
            ...prev,
            [name]: value,
        }));

        setFormErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const handleChange = ({target: {name, value}}) => handleSelect(name, value);

    const [editCorporateBenefits, {loading}] = useMutation(EDIT_CORPORATE_BENEFITS, {
        refetchQueries: ['GetCorporateBenefits'],
        onCompleted: () => {
            Toast({
                msg: 'Benefit created successfuly',
                position: 'top-right',
                variant: SUCCESS,
            });
            toggle();
        },
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    const handleSubmit = e => {
        e.preventDefault();
        const {formErrors, isValid} = formValidator(request);

        if (isValid) {
            const payload = {...request};
            payload.stock_id = payload?.stock.id;
            payload.final = payload?.final?.value;

            delete payload?.stock;

            return editCorporateBenefits({
                variables: {...payload},
            });
        }
        return setFormErrors(formErrors);
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className="flex__between flex-wrap">
                <CustomBuiltSelect
                    cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                    query={GET_ALL_COMPANIES}
                    queryName="stock"
                    label="Company"
                    name="stock"
                    placeholder="Choose a Company"
                    error={formErrors?.stock}
                    selected={request?.stock || ''}
                    handleSelect={value => handleSelect('stock', value)}
                    getLabel={v => (v?.label ? `${v?.label}` : '')}
                    required
                />
                <CustomInputBox
                    cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                    error={formErrors?.dividend_declared}
                    label="Dividend Declared"
                    name="dividend_declared"
                    onChange={handleChange}
                    placeholder="Type Here"
                    required
                    value={request?.dividend_declared}
                    type="number"
                />
            </div>

            <div className="flex__between flex-wrap">
                <CustomInputBox
                    cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                    error={formErrors?.bonus}
                    label="Bonus"
                    name="bonus"
                    onChange={handleChange}
                    placeholder="Type Here"
                    required
                    value={request?.bonus}
                    type="number"
                    min="0"
                />
                <CustomInputBox
                    cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                    error={formErrors?.dividend_yield}
                    label="Dividend Yield"
                    name="dividend_yield"
                    onChange={handleChange}
                    placeholder="Type Here"
                    required
                    value={request?.dividend_yield}
                    type="number"
                    max="100"
                />
            </div>

            <div className="flex__between flex-wrap">
                <CustomSelect
                    formGroupCls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                    error={formErrors?.final}
                    label="Interim/Final"
                    name="final"
                    onChange={value => handleSelect('final', value)}
                    placeholder="Type Here"
                    required
                    options={interimOptions}
                    value={request?.final}
                />
                <CustomDatePicker
                    cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                    error={formErrors?.declaration_date}
                    label="Declaration Date"
                    name="declaration_date"
                    onChange={value => handleSelect('declaration_date', value)}
                    placeholder="Type Here"
                    required
                    value={request?.declaration_date}
                />
            </div>

            <div className="flex__between flex-wrap">
                <CustomDatePicker
                    cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                    error={formErrors?.qualification_date}
                    label="Qualification Date"
                    name="qualification_date"
                    onChange={value => handleSelect('qualification_date', value)}
                    placeholder="Type Here"
                    required
                    value={request?.qualification_date}
                />
                <CustomDatePicker
                    cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                    error={formErrors?.closure_date}
                    label="Closure/Payment/AGM Date"
                    name="closure_date"
                    onChange={value => handleSelect('closure_date', value)}
                    placeholder="Type Here"
                    required
                    value={request?.closure_date}
                />
            </div>

            <div className="flex__center flex__column">
                <div className="wrapper__width_47_perc wrapper__col-12_mobile mt-2">
                    <button
                        disabled={loading}
                        type="submit"
                        className="btn btn--lg btn--primary text--large-sm py-5"
                    >
                        {loading ? <CustomSpinner /> : 'Update'}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default EditBenefitForm;
