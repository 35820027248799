import {ReactComponent as CircledArrowDownIcon} from 'assets/images/icons/circled-arrow-down.svg';
import Table from 'utils/Table/Table';
import {walletHeading} from '../../../common/helpers/enums';
import {toLocaleString} from 'utils/helpers';
import {titleCase} from 'utils/helpers';
import {timeDateFormat} from 'utils/helpers';
import {FUND} from 'components/constants';
import TableFooter from 'utils/Table/TableFooter/TableFooter';
import EmptyData from 'utils/Table/EmptyData';
import StatusPill from 'utils/Pills/StatusPill/StatusPill';

const WalletTable = ({
    data,
    limit,
    currentPage,
    handleLimitChange,
    handlePagination,
    hideFooter,
}) => {
    const {
        payment_transaction,
        payment_transaction_aggregate: {
            aggregate: {count: total},
        },
    } = data;

    const len = total === 0 || !Boolean(payment_transaction.length);

    const totalPages = Math.ceil(total / limit) || 1;

    const getTransactionsData = () =>
        payment_transaction?.map(transaction => ({
            icon: (
                <CircledArrowDownIcon
                    className={`transition-linear ${
                        titleCase(transaction?.type) === FUND ? 'primary' : 'danger rot-180'
                    }`}
                    height="25px"
                    width="25px"
                />
            ),
            reference: transaction.reference,
            name: `${titleCase(transaction?.user?.first_name)} ${titleCase(
                transaction?.user?.last_name
            )}`,
            amountFunded: (
                <Cell
                    type={titleCase(transaction?.type)}
                    data={`NGN ${toLocaleString(transaction?.amount)}`}
                />
            ),
            accountFunded: transaction?.accountFunded || 'N/A',
            transactionType: <Cell type={titleCase(transaction?.type)} />,
            paymentMethod: titleCase(transaction?.channel),
            completed: (
                <StatusPill
                    status={transaction?.completed ? 'Success' : 'Pending'}
                    success="Success"
                    warning="Pending"
                />
            ),
            date: `${timeDateFormat(transaction?.created_at)?.date} ${
                timeDateFormat(transaction?.created_at)?.time
            }`,
        }));

    return (
        <div
            className="tab-pane fade show active pt-4"
            id="pending"
            role="tabpanel"
            aria-labelledby="pending-tab"
        >
            {!len ? (
                <>
                    <Table
                        data={getTransactionsData()}
                        headings={walletHeading}
                        rowClick={() => {}}
                    />
                    {!hideFooter && (
                        <TableFooter
                            currentPage={currentPage || 0}
                            totalPages={totalPages}
                            handlePagination={handlePagination}
                            handleLimitChange={handleLimitChange}
                        />
                    )}
                </>
            ) : (
                <EmptyData
                    title="No Wallet Transaction Found"
                    subtitle="All wallet transactions will be displayed here"
                />
            )}
        </div>
    );
};
const Cell = ({type, data}) => {
    const color = type === FUND ? 'text--primary' : 'text-danger';

    return <div className={color}>{data || titleCase(type)}</div>;
};

export default WalletTable;
