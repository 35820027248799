//SVG Icons
import {ReactComponent as UserIcon} from 'assets/images/icons/user.svg';

//Components
import Table from 'utils/Table/Table';
import EmptyData from 'utils/Table/EmptyData';
import FileAttached from '../../FileAttached/FileAttached';
import TableFooter from 'utils/Table/TableFooter/TableFooter';

//Utils
import {timeDateFormat} from 'utils/helpers';
import {requestsHeading} from '../../helpers/enums';

const ApprovedTable = ({data, limit, currentPage, handleLimitChange, handlePagination}) => {
    const {
        margin_request,
        margin_request_aggregate: {
            aggregate: {count: total},
        },
    } = data;

    const len = total === 0 || !Boolean(margin_request.length);

    const totalPages = Math.ceil(total / limit) || 1;

    const getApprovedData = () =>
        margin_request?.map(request => ({
            icon: <UserIcon width="32px" height="32px" />,
            name: `${request?.user?.first_name} ${request?.user?.last_name}`,
            email: request?.user?.email,
            id: request?.user?.id,
            createdAt: `${timeDateFormat(request?.created_at)?.date} ${
                timeDateFormat(request?.created_at)?.time
            }`,
            fileAttached: <FileAttached request={request} />,
        }));

    return (
        <div
            className="tab-pane fade show active"
            id="pending"
            role="tabpanel"
            aria-labelledby="pending-tab"
        >
            {!len ? (
                <>
                    <Table
                        data={getApprovedData()}
                        headings={requestsHeading}
                        rowClick={() => {}}
                    />

                    <TableFooter
                        currentPage={currentPage || 0}
                        totalPages={totalPages}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                    />
                </>
            ) : (
                <EmptyData
                    title="No Approved Requests Yet"
                    subtitle="All Approved Requests will be displayed here"
                />
            )}
        </div>
    );
};

export default ApprovedTable;
