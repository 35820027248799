import Table from '../../../../utils/Table/Table';
import SearchForm from '../../../../utils/CustomInputBox/SearchForm/SearchForm';
import SectionWrapper from '../../../../utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from '../../../../utils/Section/SectionContainer/SectionContainer';

import {investmentData} from '../helpers/data';
import {toLocaleString, toPercentagePrice, toPrice} from '../../../../utils/helpers';
import {investmentHeadings, statInvOptions} from '../helpers/enums';

const getInvestmentData = () =>
    investmentData?.map(investment => ({
        logo: investment?.logo,
        name: investment?.name,
        symbol: investment?.id,
        valuation: investment?.valuation,
        dailyPL: toPrice(investment?.dailyPL),
        dailyPLPerc: toPercentagePrice(investment?.dailyPLPerc),
        totalPL: `₦${toLocaleString(investment?.totalPL)}`,
        totalPLPerc: `${investment?.totalPLPerc}%`,
    }));

const HeaderChildren = () => (
    <div className="col-3">
        <SearchForm name="investment-search" placeholder="Search Investments" smallSearch />
    </div>
);

const Investment = () => (
    <SectionContainer>
        <SectionWrapper
            headerChildren={<HeaderChildren />}
            rawOptions
            selectInputOptions={statInvOptions}
        >
            <Table
                cls="pt-4 table__short_height"
                headings={investmentHeadings}
                data={getInvestmentData()}
                rowClick={() => {}}
            />
        </SectionWrapper>
    </SectionContainer>
);

export default Investment;
