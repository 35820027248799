import Table from 'utils/Table/Table';
import {ReactComponent as MeriLogo} from 'assets/images/icons/meri-logo.svg';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SearchForm from 'utils/CustomInputBox/SearchForm/SearchForm';
import {investmentHeadings} from '../../helpers/enums';
import {toPrice} from 'utils/helpers';
import EmptyData from 'utils/Table/EmptyData';

const getWatchListData = items =>
    items?.map(data => ({
        image: data?.stock?.logo_url ? (
            <img src={data?.stock?.logo_url} alt="" className="fluid-mg" />
        ) : (
            <MeriLogo width={30} height={30} />
        ),
        name: data?.stock?.label,
        id: data?.stock?.name,
        exchange: data?.stock?.exchange,
        currency: data?.stock?.currency,
        valuation: data?.stock?.price,
        dailyPL: toPrice(data?.stock?.price_change),
        dailyPL2: toPrice(data?.stock?.price_change_p),
    }));

const WatchListDetails = ({items = []}) => {
    return (
        <SectionWrapper>
            {items?.length ? (
                <Table
                    cls="pt-4 table__short_height"
                    headings={investmentHeadings}
                    data={getWatchListData(items)}
                    rowClick={() => {}}
                />
            ) : (
                <EmptyData
                    title="No Watch-list item found "
                    subtitle="All watch list items will be displayed here"
                />
            )}
        </SectionWrapper>
    );
};

export default WatchListDetails;
