import {Accordion} from 'react-bootstrap';

import AccordionTableRow from './AccordionTableRow';

const AccordionTableBody = ({accBody, data}) => (
    <Accordion as="tbody" className="table__body">
        {data.map((row, index) => (
            <AccordionTableRow accHeader={row} accBody={accBody} id={index} key={index} />
        ))}
    </Accordion>
);

export default AccordionTableBody;
