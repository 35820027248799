import {useState} from 'react';
import {useQuery} from '@apollo/client';

//Components
import ApprovedTable from './ApprovedTable/ApprovedTable';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';

//Utils
import usePageProps from 'hooks/usePageProps';
import routes from 'components/Dashboard/routes';
import {GET_MARGIN_REQUESTS} from 'api/queries/request';
import {APPROVED, MARGIN} from 'components/constants';
import {requestFilterParams, requestsReportHeadings} from '../helpers/enums';

const ApprovedContent = () => {
    const [requestType, setRequestType] = useState(MARGIN);

    const requestRoutes = routes.find(route => route.name === 'Requests');

    const {
        limit,
        query,
        filters,
        currentPage,
        handlePagination,
        handleFilters,
        clearFilters,
        handleLimitChange,
    } = usePageProps({
        filterParams: requestFilterParams,
    });

    const queryParams = {
        variables: {
            limit: parseInt(limit),
            offset: currentPage * limit,
            status: {_eq: APPROVED},
            ...query,
            time_range: {...query?.from, ...query?.to},
        },
    };

    const getMarginRequestsQuery = useQuery(GET_MARGIN_REQUESTS, {
        ...queryParams,
    });

    return (
        <SectionContainer>
            <SectionWrapper
                filters={filters}
                clearFilters={clearFilters}
                handleFilters={handleFilters}
                filterId="requests-filter"
                filterParams={requestFilterParams}
                routes={requestRoutes}
                headerChildrenCls="col-lg-6"
                headerChildren={<></>}
                navWideMobile
                rawOptions
                // selectInputOptions={selectOptions}
                selectInputWidth="large"
                showDownload
                selectCustomHandlerFn={setRequestType}
                selectCustomValue={requestType}
                query={GET_MARGIN_REQUESTS}
                downloadKey={'margin_request'}
                queryParams={queryParams}
                reportHeadings={requestsReportHeadings}
            >
                <ContentWrapper
                    errorTitle="Error fetching margin requests"
                    currentQuery={getMarginRequestsQuery}
                >
                    <ApprovedTable
                        limit={limit}
                        currentPage={currentPage}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                        data={getMarginRequestsQuery?.data}
                    />
                </ContentWrapper>
            </SectionWrapper>
        </SectionContainer>
    );
};

export default ApprovedContent;
