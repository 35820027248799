import React from 'react';

import SectionHeader from '../extras/SectionHeader';

const SectionWrapper = ({
    filters = {},
    defaultFilters = {},
    clearFilters = () => {},
    handleFilters = () => {},
    allowSelectInMultiTab,
    cls = 'bg__white',
    children,
    dashed,
    filterId,
    filterParams,
    headerChildren,
    headerChildrenCls,
    headerChildrenNoWrap,
    iconAreaWidthMax,
    largeSelectMobile,
    navId,
    navWideMobile,
    placeholder,
    rawOptions,
    selectCustomDispValue,
    selectCustomHandlerFn,
    selectCustomValue,
    selectInputWidth,
    selectInputOptions,
    selectCustomName,
    showDownload,
    title,
    routes,
    titleChildren,
    query,
    downloadKey,
    reportHeadings,
    queryParams = {},
    setIsToday = () => {},
}) => {
    const wrapperChoice = dashed ? 'wrapper__section_dashed' : 'wrapper__section';
    return (
        <div className={`${wrapperChoice} col-12 px-4 py-4 ${cls}`}>
            <SectionHeader
                filters={filters}
                defaultFilters={defaultFilters}
                handleFilters={handleFilters}
                clearFilters={clearFilters}
                routes={routes}
                allowSelectInMultiTab={allowSelectInMultiTab}
                filterId={filterId}
                filterParams={filterParams}
                headerChildren={headerChildren}
                headerChildrenCls={headerChildrenCls}
                headerChildrenNoWrap={headerChildrenNoWrap}
                iconAreaWidthMax={iconAreaWidthMax}
                largeSelectMobile={largeSelectMobile}
                navId={navId}
                navWideMobile={navWideMobile}
                placeholder={placeholder}
                selectCustomDispValue={selectCustomDispValue}
                selectInputWidth={selectInputWidth}
                selectInputOptions={selectInputOptions}
                selectCustomHandlerFn={selectCustomHandlerFn}
                selectCustomValue={selectCustomValue}
                selectCustomName={selectCustomName}
                showDownload={showDownload}
                rawOptions={rawOptions}
                title={title}
                titleChildren={titleChildren}
                query={query}
                downloadKey={downloadKey}
                reportHeadings={reportHeadings}
                queryParams={queryParams}
                setIsToday={setIsToday}
            />
            {children}
        </div>
    );
};

export default SectionWrapper;
