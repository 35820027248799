import React from 'react';
import {titleCase} from '../helpers';

const NxNavTabs = ({
    action = () => {},
    btnList,
    cls,
    rawTitle,
    iconList,
    id,
    navId = 'myTab',
    navWideMobile,
    tabList = [],
}) => (
    <span className={`nav-tabs-container ${navWideMobile ? 'nav-wide-mobile' : ''}`}>
        <ul className={`nav nav-tabs wrapper__overflow ${cls}`} id={navId} role="tablist">
            {tabList?.map((name, i) => {
                const icon = iconList ? iconList[i] : '';
                const indexedID = id ? `${name}${id}` : name;
                const tab = btnList ? btnList[i] : `${rawTitle ? name : titleCase(name)}`;
                return i === 0 ? (
                    <Tab
                        active="active"
                        id={indexedID}
                        tab={tab}
                        Icon={icon}
                        key={i}
                        handleClick={() => action(tab)}
                    />
                ) : (
                    <Tab
                        id={indexedID}
                        tab={tab}
                        Icon={icon}
                        key={i}
                        handleClick={() => action(tab)}
                    />
                );
            })}
        </ul>
    </span>
);

const Tab = ({active, id, tab, Icon, handleClick}) => (
    <li className="nav-item" role="presentation">
        <button
            className={`nav-link ${active}`}
            id={`${id}-tab`}
            data-bs-toggle="tab"
            data-bs-target={`#${id}`}
            role="tab"
            aria-controls={id}
            onClick={handleClick}
            href={`#${id}`}
        >
            {Icon && <Icon className="me-1 mb-1" />} {tab}
        </button>
    </li>
);

export default NxNavTabs;
