import React from 'react';

const AccordionTableCell = ({tag, data, style}) =>
    React.createElement(
        tag,
        {className: `text-nowrap${tag === 'th' ? ' text-capitalize' : ''}`, style},
        data
    );

export default AccordionTableCell;
