import {useState} from 'react';

//SVG Icons
import {ReactComponent as UserIcon} from 'assets/images/icons/user.svg';

//Components
import EmptyData from 'utils/Table/EmptyData';
import AccountType from '../AccountType/AccountType';
import TableFooter from 'utils/Table/TableFooter/TableFooter';
import AccordionTable from 'utils/AccordionTable/AccordionTable';
import CustomerDetails from '../CustomerDetails/CustomerDetails';

//Utils
import {customersHeadings} from '../helpers/enums';

const CustomerTable = ({data, limit, currentPage, handleLimitChange, handlePagination}) => {
    const {
        user,
        user_aggregate: {
            aggregate: {count: total},
        },
    } = data;

    const [singleUser, setSingleUser] = useState({});

    const len = total === 0 || !Boolean(user.length);

    const totalPages = Math.ceil(total / limit) || 1;

    const getUsersData = () => {
        const details = user?.map((user, id) => ({
            icon: <UserIcon height="32px" width="32px" />,
            username: user?.username,
            email: user?.email,
            walletID: user?.walletId || 'N/A',
            phoneNo: user?.phone,
            accountType: (
                <AccountType value={user?.type_id} id={id} user={user} setUser={setSingleUser} />
            ),
        }));
        return details;
    };

    return (
        <div
            className="tab-pane fade show active"
            id="corporate_benefits"
            role="tabpanel"
            aria-labelledby="corporate_benefits-tab"
        >
            {!len ? (
                <>
                    <AccordionTable
                        accBody={<CustomerDetails duplicates={user?.length} data={singleUser} />}
                        data={getUsersData()}
                        headings={customersHeadings}
                    />
                    <TableFooter
                        currentPage={currentPage || 0}
                        totalPages={totalPages}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                    />
                </>
            ) : (
                <EmptyData title="No Users Yet" subtitle="All Users will be displayed here" />
            )}
        </div>
    );
};

export default CustomerTable;
