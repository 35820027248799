import React from 'react';
import DeleteCorporateBenfitButton from '../../buttons/DeleteCorporateBenfitButton/DeleteCorporateBenfitButton';
import EditCorporateBenefitButton from '../../buttons/EditCorporateBenefitButton/EditCorporateBenefitButton';
import AccessControl from 'components/App/AccessControl/AccessControl';
import {allPermissions} from 'components/App/AccessControl/permissions';

const CBActions = ({date, data}) => (
    <div className="flex__between">
        <div>{date}</div>
        <AccessControl allowedPermissions={[allPermissions.CAN_MODIFY_CORPORATE_BENEFITS]}>
            <div className="ms-5">
                <EditCorporateBenefitButton data={data} />
                <DeleteCorporateBenfitButton data={data} />
            </div>
        </AccessControl>
    </div>
);

export default CBActions;
