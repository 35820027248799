import React from 'react';

const StatusPill = ({fail, status, success, warning}) => {
    const cls =
        status === success
            ? 'primary__light'
            : status === fail
            ? 'danger__light'
            : status === warning
            ? 'warning__light'
            : 'warning__light';

    return <div className={`wrapper__small_pill fw-bold text-center me-4 ${cls}`}>{status}</div>;
};

export default StatusPill;
