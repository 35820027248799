import Table from 'utils/Table/Table';
import {ReactComponent as UserIcon} from 'assets/images/icons/user.svg';
import {reportsHeading} from '../../helpers/enums';
import AddReportButton from '../../buttons/AddNewButton/AddReportButton';
import EmptyData from 'utils/Table/EmptyData';
import TableFooter from 'utils/Table/TableFooter/TableFooter';
import {timeDateFormat} from 'utils/helpers';
import FilePreviewButton from '../../buttons/FilePreviewButton/FilePreviewButton';
import DeletePortfolioReportButton from '../../buttons/DeletePortfolioReportButton/DeletePortfolioReportButton';
import EditPortfolioReportButton from '../../buttons/EditPortfolioReportButton/EditPortfolioReportButton';
import AccessControl from 'components/App/AccessControl/AccessControl';
import {allPermissions} from 'components/App/AccessControl/permissions';

const PortfolioReportTable = ({data, limit, currentPage, handleLimitChange, handlePagination}) => {
    const {
        portfolio_report,
        portfolio_report_aggregate: {
            aggregate: {count: total},
        },
    } = data;

    const len = total === 0 || !Boolean(portfolio_report.length);

    const totalPages = Math.ceil(total / limit) || 1;

    const getReports = () =>
        portfolio_report?.map(data => ({
            img: <UserIcon height="32px" width="32px" />,
            id: data?.id,
            title: data?.title,
            createdAt: `${timeDateFormat(data?.created_at)?.date} ${
                timeDateFormat(data?.created_at)?.time
            }`,
            fileAttached: (
                <div className="flex__between">
                    <FilePreviewButton file={data} />
                    <AccessControl
                        allowedPermissions={[allPermissions.CAN_MODIFY_PORTFOLIO_REPORTS]}
                    >
                        <EditPortfolioReportButton data={data} />
                        <DeletePortfolioReportButton id={data?.id} />
                    </AccessControl>
                </div>
            ),
        }));

    return (
        <div
            className="tab-pane fade show active"
            id="corporate_benefits"
            role="tabpanel"
            aria-labelledby="corporate_benefits-tab"
        >
            {!len ? (
                <>
                    <Table
                        cls="mt-3"
                        data={getReports()}
                        headings={reportsHeading}
                        rowClick={() => {}}
                    />
                    <TableFooter
                        currentPage={currentPage || 0}
                        totalPages={totalPages}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                    />
                </>
            ) : (
                <EmptyData
                    title="No Portfolio Report Found"
                    subtitle="All Portfolio Reports will be displayed here"
                    btn={
                        <AccessControl
                            allowedPermissions={[allPermissions.CAN_ADD_PORTFOLIO_REPORTS]}
                        >
                            <AddReportButton />
                        </AccessControl>
                    }
                />
            )}
        </div>
    );
};

export default PortfolioReportTable;
