import PageSizeSelect from 'utils/PageSizeSelect/PageSizeSelect';
import Pagination from 'utils/Pagination/Pagination';

const TableFooter = ({currentPage, totalPages, handlePagination, handleLimitChange}) => {
    return (
        <div className="pt-5 flex__between">
            <PageSizeSelect onSizeChange={handleLimitChange} />
            <Pagination
                forcePage={Number(currentPage)}
                totalPages={totalPages}
                paginate={handlePagination}
            />
        </div>
    );
};

export default TableFooter;
