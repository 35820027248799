import React, {useEffect} from 'react';
import ReactECharts from 'echarts-for-react';

import NavTabs from '../../../../../utils/NavTabs/NavTabs';
import SectionWrapper from '../../../../../utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from '../../../../../utils/Section/SectionContainer/SectionContainer';

import {loadingOption, marketVolChartOptions} from '../../helpers/chartEnums';
import {marketVolTabsList, marketVolTabID, volumeChartsOptions} from '../../helpers/enums';

const TotalMarketVolume = () => {
    let timer;

    useEffect(() => {
        return () => clearTimeout(timer);
    });

    function onChartReady(echarts) {
        timer = setTimeout(function () {
            echarts.hideLoading();
        }, 3000);
    }

    return (
        <SectionContainer cls="flex__column col-md-6 col-12">
            <SectionWrapper
                largeSelectMobile
                selectInputOptions={volumeChartsOptions}
                title="Total Market Volume"
            >
                <div className="tab-content" id="myTabContent">
                    {marketVolTabID.map((volume, index) => (
                        <div
                            className={`tab-pane fade show ${index === 0 ? 'active' : ''}`}
                            key={index}
                            id={volume}
                            role="tabpanel"
                            aria-labelledby={`${volume}-tab`}
                        >
                            <ReactECharts
                                className="wrapper__chart"
                                loadingOption={loadingOption}
                                onChartReady={onChartReady}
                                option={marketVolChartOptions}
                                opts={{renderer: 'svg'}}
                                showLoading={true}
                                style={{height: '253px', width: '100%'}}
                            />
                        </div>
                    ))}
                </div>
                <NavTabs
                    btnList={marketVolTabsList}
                    cls="vol-chart-nav-tabs col-md-9 col-12"
                    tabList={marketVolTabID}
                />
            </SectionWrapper>
        </SectionContainer>
    );
};

export default TotalMarketVolume;
