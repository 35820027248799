import {useLazyQuery} from '@apollo/client';

//SVG Icons
import {ReactComponent as DownloadIcon} from 'assets/images/icons/download.svg';

//Components
import Toast from 'utils/Alert/Toast/Toast';
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';

//Utils
import {downloadReport} from 'utils/helpers';

const DownloadBtn = ({query, downloadKey, reportHeadings, queryParams = {}}) => {
    const [getDownloads, {loading}] = useLazyQuery(query, {
        fetchPolicy: 'no-cache',
        onCompleted: async data => {
            try {
                await downloadReport(reportHeadings, data[downloadKey]);
            } catch (e) {
                Toast({
                    msg: 'Error generating report',
                    position: 'top-right',
                });
            }
        },
    });

    return (
        <>
            {loading ? (
                <CustomSpinner cls="me-4 spinner-green" />
            ) : (
                <DownloadIcon
                    onClick={() =>
                        getDownloads({
                            ...queryParams,
                        })
                    }
                    className="pointer me-4"
                    height={25}
                    width={25}
                />
            )}
        </>
    );
};

export default DownloadBtn;
