import React from 'react';

import FilePreviewButton from '../buttons/FilePreviewButton/FilePreviewButton';

const FileAttached = ({request, extra}) => (
    <div className="wrapper__file_attached">
        <div className="flex__between">
            <FilePreviewButton request={request} />
            {extra}
        </div>
    </div>
);

export default FileAttached;
