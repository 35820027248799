import {gql} from '@apollo/client';

export const DELETE_CORPORATE_BENEFITS = gql`
    mutation deleteCorporateBenefits($id: uuid!) {
        delete_corporate_benefit(where: {id: {_eq: $id}}) {
            returning {
                id
            }
        }
    }
`;

export const ADD_CORPORATE_BENEFITS = gql`
    mutation addCorporateBenefits(
        $stock_id: Int!
        $bonus: numeric!
        $final: Boolean!
        $closure_date: date!
        $declaration_date: date!
        $dividend_yield: numeric!
        $qualification_date: date!
        $dividend_declared: numeric!
    ) {
        insert_corporate_benefit_one(
            object: {
                bonus: $bonus
                closure_date: $closure_date
                declaration_date: $declaration_date
                dividend_declared: $dividend_declared
                dividend_yield: $dividend_yield
                final: $final
                qualification_date: $qualification_date
                stock_id: $stock_id
            }
        ) {
            id
        }
    }
`;

export const EDIT_CORPORATE_BENEFITS = gql`
    mutation editCorporateBenefits(
        $id: uuid!
        $stock_id: Int!
        $bonus: numeric!
        $final: Boolean!
        $closure_date: date!
        $declaration_date: date!
        $dividend_yield: numeric!
        $qualification_date: date!
        $dividend_declared: numeric!
    ) {
        update_corporate_benefit(
            where: {id: {_eq: $id}}
            _set: {
                bonus: $bonus
                closure_date: $closure_date
                declaration_date: $declaration_date
                dividend_declared: $dividend_declared
                dividend_yield: $dividend_yield
                final: $final
                qualification_date: $qualification_date
                stock_id: $stock_id
            }
        ) {
            returning {
                id
            }
        }
    }
`;
