import {ReactComponent as ArlaIcon} from '../../../../assets/images/icons/logos/arla.svg';
import {ReactComponent as DanoIcon} from '../../../../assets/images/icons/logos/dano.svg';
import {ReactComponent as AirtelIcon} from '../../../../assets/images/icons/logos/airtel.svg';
import {ReactComponent as BusinessDayIcon} from '../../../../assets/images/icons/logos/business-day.svg';

export const tradeData = [
    {
        orderNo: '#11609494',
        date: '06/10/21',
        orderType: 'Buy',
        quantity: 54,
        symbol: 'Transcorp',
        priceType: 'Limit',
        term: 10,
        orderStatus: 'Executed',
        marketStatus: 'Success',
    },
    {
        orderNo: '#00272204',
        date: '05/10/21',
        orderType: 'Sell',
        quantity: 21,
        symbol: 'Eterna',
        priceType: 'Limit',
        term: 7,
        orderStatus: 'Pending',
        marketStatus: 'Success',
    },
    {
        orderNo: '#11696434',
        date: '02/10/21',
        orderType: 'Buy',
        quantity: 5,
        symbol: 'Oando',
        priceType: 'Market',
        term: 3,
        orderStatus: 'Executed',
        marketStatus: 'Pending',
    },
    {
        orderNo: '#00123843',
        date: '01/10/21',
        orderType: 'Buy',
        quantity: 12,
        symbol: 'Oando',
        priceType: 'Market',
        term: 3,
        orderStatus: 'Cancelled',
        marketStatus: 'Cancelled',
    },
    {
        orderNo: '#02388437',
        date: '01/10/21',
        orderType: 'Sell',
        quantity: 8,
        symbol: 'Oando',
        priceType: 'Limit',
        term: 3,
        orderStatus: 'Pending',
        marketStatus: 'Cancelled',
    },
    {
        orderNo: '#03588437',
        date: '02/09/21',
        orderType: 'Sell',
        quantity: 12,
        symbol: 'Spotify',
        priceType: 'Limit',
        term: 3,
        orderStatus: 'Pending',
        marketStatus: 'Cancelled',
    },
    {
        orderNo: '#12398494',
        date: '30/09/21',
        orderType: 'Sell',
        quantity: 14,
        symbol: 'Oando',
        priceType: 'Market',
        term: 12,
        orderStatus: 'Executed',
        marketStatus: 'Success',
    },
    {
        orderNo: '#00954894',
        date: '30/09/21',
        orderType: 'Buy',
        quantity: 34,
        symbol: 'Eterna',
        priceType: 'Limit',
        term: 2,
        orderStatus: 'Executed',
        marketStatus: 'Pending',
    },
    {
        orderNo: '#11609494',
        date: '29/09/21',
        orderType: 'Buy',
        quantity: 47,
        symbol: 'Transcorp',
        priceType: 'Market',
        term: 14,
        orderStatus: 'Executed',
        marketStatus: 'Cancelled',
    },
    {
        orderNo: '#11609494',
        date: '06/10/21',
        orderType: 'Buy',
        quantity: 54,
        symbol: 'Transcorp',
        priceType: 'Limit',
        term: 10,
        orderStatus: 'Executed',
        marketStatus: 'Success',
    },
    {
        orderNo: '#00272204',
        date: '05/10/21',
        orderType: 'Sell',
        quantity: 21,
        symbol: 'Eterna',
        priceType: 'Limit',
        term: 7,
        orderStatus: 'Pending',
        marketStatus: 'Success',
    },
    {
        orderNo: '#11696434',
        date: '02/10/21',
        orderType: 'Buy',
        quantity: 5,
        symbol: 'Oando',
        priceType: 'Market',
        term: 3,
        orderStatus: 'Executed',
        marketStatus: 'Pending',
    },
    {
        orderNo: '#11696434',
        date: '02/10/21',
        orderType: 'Sell',
        quantity: 22,
        symbol: 'Spotify',
        priceType: 'Market',
        term: 3,
        orderStatus: 'Pending',
        marketStatus: 'Pending',
    },
    {
        orderNo: '#00123843',
        date: '01/10/21',
        orderType: 'Buy',
        quantity: 12,
        symbol: 'Oando',
        priceType: 'Market',
        term: 3,
        orderStatus: 'Cancelled',
        marketStatus: 'Cancelled',
    },
    {
        orderNo: '#02388437',
        date: '01/10/21',
        orderType: 'Sell',
        quantity: 8,
        symbol: 'Oando',
        priceType: 'Limit',
        term: 3,
        orderStatus: 'Pending',
        marketStatus: 'Pending',
    },
    {
        orderNo: '#12398494',
        date: '30/09/21',
        orderType: 'Sell',
        quantity: 14,
        symbol: 'Oando',
        priceType: 'Market',
        term: 12,
        orderStatus: 'Pending',
        marketStatus: 'Pending',
    },
    {
        orderNo: '#00954894',
        date: '30/09/21',
        orderType: 'Buy',
        quantity: 34,
        symbol: 'Eterna',
        priceType: 'Limit',
        term: 2,
        orderStatus: 'Executed',
        marketStatus: 'Success',
    },
    {
        orderNo: '#11609494',
        date: '29/09/21',
        orderType: 'Buy',
        quantity: 47,
        symbol: 'Transcorp',
        priceType: 'Market',
        term: 14,
        orderStatus: 'Executed',
        marketStatus: 'Cancelled',
    },
];

export const walletFundData = [
    {
        name: 'Jane Doe B.',
        id: '#11609494',
        amountFunded: 40500,
        accountFunded: 'Margin',
        transactionType: 'Fund',
        date: '28 Jun., 2020 - 14:20pm',
    },
    {
        name: 'Steven Akpo',
        id: '#02722044',
        amountFunded: 120350.25,
        accountFunded: 'Cash',
        transactionType: 'Fund',
        date: '12 Aug., 2020 - 21:00pm',
    },
    {
        name: 'Sarudeen Bode',
        id: '#10328439',
        amountFunded: 91120.43,
        accountFunded: 'Cash',
        transactionType: 'Fund',
        date: '01 Jan., 2020 - 07:10pm',
    },
    {
        name: 'Felicia Jones',
        id: '#00605434',
        amountFunded: 300250.75,
        accountFunded: 'Margin',
        transactionType: 'Fund',
        date: '28 Jun., 2020 - 13:20pm',
    },
    {
        name: 'John Doe',
        id: '#00605494',
        amountFunded: 540500,
        accountFunded: 'Cash',
        transactionType: 'Fund',
        date: '26 Jun., 2020 - 12:20pm',
    },
    {
        name: 'James Daniel',
        id: '#40054494',
        amountFunded: 789500,
        accountFunded: 'Cash',
        transactionType: 'Fund',
        date: '28 Jun., 2020 - 11:20pm',
    },
    {
        name: 'Daniel Loyde',
        id: '#00609494',
        amountFunded: 410500,
        accountFunded: 'Cash',
        transactionType: 'Fund',
        date: '28 Jun., 2020 - 08:20pm',
    },
    {
        name: 'Sven Stevenson',
        id: '#02855494',
        amountFunded: 94500,
        accountFunded: 'Margin',
        transactionType: 'Fund',
        date: '26 Jun., 2020 - 14:20pm',
    },
    {
        name: 'Jane Doe B.',
        id: '#00329494',
        amountFunded: 240500,
        accountFunded: 'Margin',
        transactionType: 'Fund',
        date: '26 Jun., 2020 - 04:20pm',
    },
    {
        name: 'Jane Doe B.',
        id: '#11609494',
        amountFunded: 40500,
        accountFunded: 'Margin',
        transactionType: 'Fund',
        date: '28 Jun., 2020 - 16:20pm',
    },
    {
        name: 'Steven Akpo',
        id: '#02722044',
        amountFunded: 120350.25,
        accountFunded: 'Cash',
        transactionType: 'Fund',
        date: '12 Aug., 2020 - 21:00pm',
    },
    {
        name: 'Sarudeen Bode',
        id: '#10328439',
        amountFunded: 91120.43,
        accountFunded: 'Cash',
        transactionType: 'Fund',
        date: '01 Jan., 2020 - 07:10pm',
    },
    {
        name: 'Felicia Jones',
        id: '#00605434',
        amountFunded: 300250.75,
        accountFunded: 'Margin',
        transactionType: 'Fund',
        date: '28 Jun., 2020 - 19:20pm',
    },
    {
        name: 'John Doe',
        id: '#00605494',
        amountFunded: 540500,
        accountFunded: 'Cash',
        transactionType: 'Fund',
        date: '26 Jun., 2020 - 12:20pm',
    },
    {
        name: 'James Daniel',
        id: '#40054494',
        amountFunded: 789500,
        accountFunded: 'Cash',
        transactionType: 'Fund',
        date: '28 Jun., 2020 - 14:20pm',
    },
    {
        name: 'Daniel Loyde',
        id: '#00609494',
        amountFunded: 410500,
        accountFunded: 'Cash',
        transactionType: 'Fund',
        date: '28 Jun., 2020 - 08:20pm',
    },
    {
        name: 'Sven Stevenson',
        id: '#02855494',
        amountFunded: 94500,
        accountFunded: 'Margin',
        transactionType: 'Fund',
        date: '26 Jun., 2020 - 14:20pm',
    },
    {
        name: 'Jane Doe B.',
        id: '#00329494',
        amountFunded: 240500,
        accountFunded: 'Margin',
        transactionType: 'Fund',
        date: '26 Jun., 2020 - 04:20pm',
    },
];

export const walletWithdrawalData = [
    {
        name: 'Jane Doe B.',
        id: '#11609494',
        amountFunded: 130500,
        accountFunded: 'Margin',
        transactionType: 'Withdrawal',
        date: '28 Jun., 2020 - 17:20pm',
    },
    {
        name: 'Steven Akpo',
        id: '#02722044',
        amountFunded: 150350.25,
        accountFunded: 'Cash',
        transactionType: 'Withdrawal',
        date: '12 Aug., 2020 - 20:00pm',
    },
    {
        name: 'Sarudeen Bode',
        id: '#10328439',
        amountFunded: 123498.43,
        accountFunded: 'Cash',
        transactionType: 'Withdrawal',
        date: '01 Jan., 2020 - 07:10pm',
    },
    {
        name: 'Felicia Jones',
        id: '#00605434',
        amountFunded: 1234250.75,
        accountFunded: 'Margin',
        transactionType: 'Withdrawal',
        date: '28 Jun., 2020 - 14:20pm',
    },
    {
        name: 'John Doe',
        id: '#00605494',
        amountFunded: 9675500,
        accountFunded: 'Cash',
        transactionType: 'Withdrawal',
        date: '26 Jun., 2020 - 12:20pm',
    },
    {
        name: 'James Daniel',
        id: '#40054494',
        amountFunded: 2943500,
        accountFunded: 'Cash',
        transactionType: 'Withdrawal',
        date: '28 Jun., 2020 - 22:20pm',
    },
    {
        name: 'Daniel Loyde',
        id: '#00609494',
        amountFunded: 1230500,
        accountFunded: 'Cash',
        transactionType: 'Withdrawal',
        date: '28 Jun., 2020 - 08:20pm',
    },
    {
        name: 'Sven Stevenson',
        id: '#02855494',
        amountFunded: 94500,
        accountFunded: 'Margin',
        transactionType: 'Withdrawal',
        date: '26 Jun., 2020 - 14:20pm',
    },
    {
        name: 'Jane Doe B.',
        id: '#00329494',
        amountFunded: 240500,
        accountFunded: 'Margin',
        transactionType: 'Withdrawal',
        date: '26 Jun., 2020 - 04:20pm',
    },
    {
        name: 'Jane Doe B.',
        id: '#11609494',
        amountFunded: 40500,
        accountFunded: 'Margin',
        transactionType: 'Withdrawal',
        date: '28 Jun., 2020 - 22:50pm',
    },
    {
        name: 'Steven Akpo',
        id: '#02722044',
        amountFunded: 120350.25,
        accountFunded: 'Cash',
        transactionType: 'Withdrawal',
        date: '12 Aug., 2020 - 21:00pm',
    },
    {
        name: 'Sarudeen Bode',
        id: '#10328439',
        amountFunded: 91120.43,
        accountFunded: 'Cash',
        transactionType: 'Withdrawal',
        date: '01 Jan., 2020 - 07:10pm',
    },
    {
        name: 'Felicia Jones',
        id: '#00605434',
        amountFunded: 300250.75,
        accountFunded: 'Margin',
        transactionType: 'Withdrawal',
        date: '28 Jun., 2020 - 14:20pm',
    },
    {
        name: 'John Doe',
        id: '#00605494',
        amountFunded: 540500,
        accountFunded: 'Cash',
        transactionType: 'Withdrawal',
        date: '26 Jun., 2020 - 12:20pm',
    },
    {
        name: 'James Daniel',
        id: '#40054494',
        amountFunded: 789500,
        accountFunded: 'Cash',
        transactionType: 'Withdrawal',
        date: '28 Jun., 2020 - 14:20pm',
    },
    {
        name: 'Daniel Loyde',
        id: '#00609494',
        amountFunded: 410500,
        accountFunded: 'Cash',
        transactionType: 'Withdrawal',
        date: '28 Jun., 2020 - 08:20pm',
    },
    {
        name: 'Sven Stevenson',
        id: '#02855494',
        amountFunded: 94500,
        accountFunded: 'Margin',
        transactionType: 'Withdrawal',
        date: '26 Jun., 2020 - 14:20pm',
    },
    {
        name: 'Jane Doe B.',
        id: '#00329494',
        amountFunded: 240500,
        accountFunded: 'Margin',
        transactionType: 'Withdrawal',
        date: '26 Jun., 2020 - 04:20pm',
    },
];

export const ordersData = [
    {
        date: '06/10/21',
        id: 1,
        orderNo: '#11609494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Executing',
        symbol: 'Transcorp',
        term: 10,
        type: 'Buy',
        username: 'James Abo',
        quantity: 54,
    },
    {
        date: '05/10/21',
        id: 2,
        orderNo: '#01005444',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Executing',
        symbol: 'Eterna',
        term: 7,
        type: 'Sell',
        username: 'Jen Zik',
        quantity: 21,
    },
    {
        date: '05/10/21',
        id: 3,
        orderNo: '#22786494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Executing',
        symbol: 'Oando',
        term: 3,
        type: 'Buy',
        username: 'Sam Ehi',
        quantity: 5,
    },
    {
        date: '04/10/21',
        id: 4,
        orderNo: '#34937394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Executing',
        symbol: "Domino's",
        term: 3,
        type: 'Buy',
        username: 'Adewale Alna',
        quantity: 12,
    },
    {
        date: '07/10/21',
        id: 5,
        orderNo: '#00037494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Executing',
        symbol: 'Oando',
        term: 9,
        type: 'Sell',
        username: 'Chinedu Eb',
        quantity: 80,
    },
    {
        date: '02/10/21',
        id: 6,
        orderNo: '#10944494',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Executing',
        symbol: 'Spotify',
        term: 14,
        type: 'Sell',
        username: 'Joe Blaze',
        quantity: 14,
    },
    {
        date: '01/10/21',
        id: 7,
        orderNo: '#11609494',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Executing',
        symbol: 'Oando',
        term: 10,
        type: 'Buy',
        username: 'Jimmy Abo',
        quantity: 54,
    },
    {
        date: '08/09/21',
        id: 8,
        orderNo: '#34937394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Executed',
        symbol: "Domino's",
        term: 3,
        type: 'Sell',
        username: 'Adewale Aderoti',
        quantity: 11,
    },
    {
        date: '22/08/21',
        id: 9,
        orderNo: '#00037494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Executed',
        symbol: 'First Bank',
        term: 4,
        type: 'Sell',
        username: 'Eze Eb',
        quantity: 60,
    },
    {
        date: '05/09/21',
        id: 10,
        orderNo: '#01005444',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Executing',
        symbol: 'Eterna',
        term: 19,
        type: 'Buy',
        username: 'Janet Zik',
        quantity: 21,
    },
    {
        date: '15/09/21',
        id: 11,
        orderNo: '#22786494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Executing',
        symbol: 'Oando',
        term: 3,
        type: 'Buy',
        username: 'Sam Ehi',
        quantity: 5,
    },
    {
        date: '04/09/21',
        id: 12,
        orderNo: '#34937394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Executing',
        symbol: "Domino's",
        term: 15,
        type: 'Sell',
        username: 'Adewale Aderoti',
        quantity: 11,
    },
    {
        date: '22/09/21',
        id: 13,
        orderNo: '#00037494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Executing',
        symbol: 'First Bank',
        term: 2,
        type: 'Sell',
        username: 'Eze Eb',
        quantity: 60,
    },
    {
        date: '12/10/21',
        id: 14,
        orderNo: '#34937394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Executed',
        symbol: "Domino's",
        term: 3,
        type: 'Buy',
        username: 'Adewale Alna',
        quantity: 12,
    },
    {
        date: '15/10/21',
        id: 15,
        orderNo: '#00037494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Executed',
        symbol: 'Oando',
        term: 9,
        type: 'Sell',
        username: 'Chinedu Eb',
        quantity: 80,
    },
    {
        date: '18/09/21',
        id: 16,
        orderNo: '#10944494',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Executed',
        symbol: 'Spotify',
        term: 10,
        type: 'Sell',
        username: 'Joe Blaze',
        quantity: 14,
    },
    {
        date: '02/09/21',
        id: 17,
        orderNo: '#10944494',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Executing',
        symbol: 'Spotify',
        term: 10,
        type: 'Buy',
        username: 'Joe Blaze',
        quantity: 14,
    },
    {
        date: '06/10/21',
        id: 18,
        orderNo: '#11609494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Executed',
        symbol: 'Transcorp',
        term: 10,
        type: 'Buy',
        username: 'James Abo',
        quantity: 54,
    },
    {
        date: '05/11/21',
        id: 19,
        orderNo: '#01005444',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Executed',
        symbol: 'Eterna',
        term: 7,
        type: 'Sell',
        username: 'Jen Zik',
        quantity: 21,
    },
    {
        date: '05/09/21',
        id: 20,
        orderNo: '#22786494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Executed',
        symbol: 'Oando',
        term: 3,
        type: 'Buy',
        username: 'Sam Ehi',
        quantity: 5,
    },
    {
        date: '01/08/21',
        id: 21,
        orderNo: '#11609494',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Executed',
        symbol: 'Oando',
        term: 25,
        type: 'Buy',
        username: 'Janet Abo',
        quantity: 54,
    },
    {
        date: '05/07/21',
        id: 22,
        orderNo: '#01005444',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Executed',
        symbol: 'Eterna',
        term: 7,
        type: 'Sell',
        username: 'James Doonie',
        quantity: 21,
    },
    {
        date: '15/09/21',
        id: 23,
        orderNo: '#22786494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Executed',
        symbol: 'Oando',
        term: 7,
        type: 'Buy',
        username: 'Sam Ehi',
        quantity: 5,
    },
    {
        date: '16/08/21',
        id: 24,
        orderNo: '#10944494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Executed',
        symbol: 'Spotify',
        term: 15,
        type: 'Sell',
        username: 'Joe Blaze',
        quantity: 14,
    },
    {
        date: '08/09/21',
        id: 25,
        orderNo: '#33658394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Executing',
        symbol: 'Arla',
        term: 2,
        type: 'Buy',
        username: 'James Fox',
        quantity: 11,
    },
    {
        date: '22/08/21',
        id: 26,
        orderNo: '#09737494',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Executed',
        symbol: 'GT Bank',
        term: 7,
        type: 'Buy',
        username: 'Ernest Eze',
        quantity: 60,
    },
    {
        date: '08/09/21',
        id: 27,
        orderNo: '#98437394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Executing',
        symbol: "Domino's",
        term: 11,
        type: 'Sell',
        username: 'Adewale Aderoti',
        quantity: 11,
    },
    {
        date: '22/08/21',
        id: 28,
        orderNo: '#02037654',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Executed',
        symbol: 'First Bank',
        term: 5,
        type: 'Sell',
        username: 'Erwin Eb',
        quantity: 30,
    },
    {
        date: '08/09/21',
        id: 29,
        orderNo: '#09938394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Executed',
        symbol: "Domino's",
        term: 13,
        type: 'Sell',
        username: 'Adewale Aderoti',
        quantity: 11,
    },
    {
        date: '22/08/21',
        id: 30,
        orderNo: '#09837494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Executing',
        symbol: 'Dano',
        term: 8,
        type: 'Sell',
        username: 'Eze Eb',
        quantity: 60,
    },
    {
        date: '06/10/21',
        id: 1,
        orderNo: '#11609494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Cancelled',
        symbol: 'Transcorp',
        term: 10,
        type: 'Buy',
        username: 'James Abo',
        quantity: 54,
    },
    {
        date: '05/10/21',
        id: 2,
        orderNo: '#01005444',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Cancelled',
        symbol: 'Eterna',
        term: 7,
        type: 'Sell',
        username: 'Jen Zik',
        quantity: 21,
    },
    {
        date: '05/10/21',
        id: 3,
        orderNo: '#22786494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Cancelled',
        symbol: 'Oando',
        term: 3,
        type: 'Buy',
        username: 'Sam Ehi',
        quantity: 5,
    },
    {
        date: '04/10/21',
        id: 4,
        orderNo: '#34937394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Cancelled',
        symbol: "Domino's",
        term: 3,
        type: 'Buy',
        username: 'Adewale Alna',
        quantity: 12,
    },
    {
        date: '07/10/21',
        id: 5,
        orderNo: '#00037494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Cancelled',
        symbol: 'Oando',
        term: 9,
        type: 'Sell',
        username: 'Chinedu Eb',
        quantity: 80,
    },
    {
        date: '02/10/21',
        id: 6,
        orderNo: '#10944494',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Cancelled',
        symbol: 'Spotify',
        term: 14,
        type: 'Sell',
        username: 'Joe Blaze',
        quantity: 14,
    },
    {
        date: '01/10/21',
        id: 7,
        orderNo: '#11609494',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Cancelled',
        symbol: 'Oando',
        term: 10,
        type: 'Buy',
        username: 'Jimmy Abo',
        quantity: 54,
    },
    {
        date: '08/09/21',
        id: 8,
        orderNo: '#34937394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Expired',
        symbol: "Domino's",
        term: 3,
        type: 'Sell',
        username: 'Adewale Aderoti',
        quantity: 11,
    },
    {
        date: '22/08/21',
        id: 9,
        orderNo: '#00037494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Expired',
        symbol: 'First Bank',
        term: 4,
        type: 'Sell',
        username: 'Eze Eb',
        quantity: 60,
    },
    {
        date: '05/09/21',
        id: 10,
        orderNo: '#01005444',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Cancelled',
        symbol: 'Eterna',
        term: 19,
        type: 'Buy',
        username: 'Janet Zik',
        quantity: 21,
    },
    {
        date: '15/09/21',
        id: 11,
        orderNo: '#22786494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Cancelled',
        symbol: 'Oando',
        term: 3,
        type: 'Buy',
        username: 'Sam Ehi',
        quantity: 5,
    },
    {
        date: '04/09/21',
        id: 12,
        orderNo: '#34937394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Cancelled',
        symbol: "Domino's",
        term: 15,
        type: 'Sell',
        username: 'Adewale Aderoti',
        quantity: 11,
    },
    {
        date: '22/09/21',
        id: 13,
        orderNo: '#00037494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Cancelled',
        symbol: 'First Bank',
        term: 2,
        type: 'Sell',
        username: 'Eze Eb',
        quantity: 60,
    },
    {
        date: '12/10/21',
        id: 14,
        orderNo: '#34937394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Expired',
        symbol: "Domino's",
        term: 3,
        type: 'Buy',
        username: 'Adewale Alna',
        quantity: 12,
    },
    {
        date: '15/10/21',
        id: 15,
        orderNo: '#00037494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Expired',
        symbol: 'Oando',
        term: 9,
        type: 'Sell',
        username: 'Chinedu Eb',
        quantity: 80,
    },
    {
        date: '18/09/21',
        id: 16,
        orderNo: '#10944494',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Expired',
        symbol: 'Spotify',
        term: 10,
        type: 'Sell',
        username: 'Joe Blaze',
        quantity: 14,
    },
    {
        date: '02/09/21',
        id: 17,
        orderNo: '#10944494',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Cancelled',
        symbol: 'Spotify',
        term: 10,
        type: 'Buy',
        username: 'Joe Blaze',
        quantity: 14,
    },
    {
        date: '06/10/21',
        id: 18,
        orderNo: '#11609494',
        marketStatus: 'Closed',
        priceType: 'Limit',
        status: 'Expired',
        symbol: 'Transcorp',
        term: 10,
        type: 'Buy',
        username: 'James Abo',
        quantity: 54,
    },
    {
        date: '05/11/21',
        id: 19,
        orderNo: '#01005444',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Expired',
        symbol: 'Eterna',
        term: 7,
        type: 'Sell',
        username: 'Jen Zik',
        quantity: 21,
    },
    {
        date: '05/09/21',
        id: 20,
        orderNo: '#22786494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Expired',
        symbol: 'Oando',
        term: 3,
        type: 'Buy',
        username: 'Sam Ehi',
        quantity: 5,
    },
    {
        date: '01/08/21',
        id: 21,
        orderNo: '#11609494',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Expired',
        symbol: 'Oando',
        term: 25,
        type: 'Buy',
        username: 'Janet Abo',
        quantity: 54,
    },
    {
        date: '05/07/21',
        id: 22,
        orderNo: '#01005444',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Expired',
        symbol: 'Eterna',
        term: 7,
        type: 'Sell',
        username: 'James Doonie',
        quantity: 21,
    },
    {
        date: '15/09/21',
        id: 23,
        orderNo: '#22786494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Expired',
        symbol: 'Oando',
        term: 7,
        type: 'Buy',
        username: 'Sam Ehi',
        quantity: 5,
    },
    {
        date: '16/08/21',
        id: 24,
        orderNo: '#10944494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Expired',
        symbol: 'Spotify',
        term: 15,
        type: 'Sell',
        username: 'Joe Blaze',
        quantity: 14,
    },
    {
        date: '08/09/21',
        id: 25,
        orderNo: '#33658394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Cancelled',
        symbol: 'Arla',
        term: 2,
        type: 'Buy',
        username: 'James Fox',
        quantity: 11,
    },
    {
        date: '22/08/21',
        id: 26,
        orderNo: '#09737494',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Expired',
        symbol: 'GT Bank',
        term: 7,
        type: 'Buy',
        username: 'Ernest Eze',
        quantity: 60,
    },
    {
        date: '08/09/21',
        id: 27,
        orderNo: '#98437394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Cancelled',
        symbol: "Domino's",
        term: 11,
        type: 'Sell',
        username: 'Adewale Aderoti',
        quantity: 11,
    },
    {
        date: '22/08/21',
        id: 28,
        orderNo: '#02037654',
        marketStatus: 'Open',
        priceType: 'Limit',
        status: 'Expired',
        symbol: 'First Bank',
        term: 5,
        type: 'Sell',
        username: 'Erwin Eb',
        quantity: 30,
    },
    {
        date: '08/09/21',
        id: 29,
        orderNo: '#09938394',
        marketStatus: 'Closed',
        priceType: 'Market',
        status: 'Expired',
        symbol: "Domino's",
        term: 13,
        type: 'Sell',
        username: 'Adewale Aderoti',
        quantity: 11,
    },
    {
        date: '22/08/21',
        id: 30,
        orderNo: '#09837494',
        marketStatus: 'Open',
        priceType: 'Market',
        status: 'Cancelled',
        symbol: 'Dano',
        term: 8,
        type: 'Sell',
        username: 'Eze Eb',
        quantity: 60,
    },
];

export const portfolioInfoData = [
    {
        dailyPL: -166.31,
        currentValue: 148900.63,
        lastPrice: 7.5,
        name: 'Eterna Oil & Gas',
        pricePaid: 5.7,
        quantity: 54,
        symbol: 'ETRNA',
        totalCost: 197600,
        change: -46.44,
    },
    {
        dailyPL: 71.11,
        currentValue: 875.4,
        lastPrice: 4.5,
        name: 'Transcorp',
        pricePaid: 7.37,
        quantity: 21,
        symbol: 'TRANSCORP',
        totalCost: 880,
        change: +46.44,
    },
];

export const portfolioInfoFootings = [
    {
        dailyPL: null,
        currentValue: 149701.03,
        lastPrice: null,
        name: 'Portfolio',
        pricePaid: null,
        quantity: null,
        symbol: null,
        totalCost: 198200.0,
    },
    {
        dailyPL: null,
        currentValue: null,
        lastPrice: null,
        name: 'Cash',
        pricePaid: null,
        quantity: null,
        symbol: null,
        totalCost: 194.25,
    },
];

export const investmentData = [
    {
        dailyPL: 46.44,
        dailyPLPerc: -7.02,
        id: 'ARLA',
        logo: <ArlaIcon height="32px" width="49px" />,
        name: 'Arla Foods',
        totalPL: 166.31,
        totalPLPerc: 18.57,
        valuation: 157180,
    },
    {
        dailyPL: -46.44,
        dailyPLPerc: 10.32,
        id: 'AIRTL',
        logo: <AirtelIcon height="30px" width="34px" />,
        name: 'Airtel Nigeria',
        totalPL: 145.27,
        totalPLPerc: 20.32,
        valuation: 240000.43,
    },
    {
        dailyPL: -4.93,
        dailyPLPerc: 18.57,
        id: 'BSNDY',
        logo: <BusinessDayIcon height="13px" width="56px" />,
        name: 'Business Day',
        totalPL: 127.94,
        totalPLPerc: 10,
        valuation: 179843.84,
    },
    {
        dailyPL: 23.4,
        dailyPLPerc: -11.98,
        id: 'DNO',
        logo: <DanoIcon height="27px" width="42px" />,
        name: 'Dano',
        totalPL: 149.32,
        totalPLPerc: 34,
        valuation: 350500.43,
    },
    {
        dailyPL: 46.44,
        dailyPLPerc: -7.02,
        id: 'ARLA',
        logo: <ArlaIcon height="32px" width="49px" />,
        name: 'Arla Foods',
        totalPL: 166.31,
        totalPLPerc: 18.57,
        valuation: 157180,
    },
    {
        dailyPL: -46.44,
        dailyPLPerc: 10.32,
        id: 'AIRTL',
        logo: <AirtelIcon height="30px" width="34px" />,
        name: 'Airtel Nigeria',
        totalPL: 145.27,
        totalPLPerc: 20.32,
        valuation: 240000.43,
    },
    {
        dailyPL: -4.93,
        dailyPLPerc: 18.57,
        id: 'BSNDY',
        logo: <BusinessDayIcon height="13px" width="56px" />,
        name: 'Business Day',
        totalPL: 127.94,
        totalPLPerc: 10,
        valuation: 179843.84,
    },
    {
        dailyPL: 23.4,
        dailyPLPerc: -11.98,
        id: 'DNO',
        logo: <DanoIcon height="27px" width="42px" />,
        name: 'Dano',
        totalPL: 149.32,
        totalPLPerc: 34,
        valuation: 350500.43,
    },
    {
        dailyPL: 46.44,
        dailyPLPerc: -7.02,
        id: 'ARLA',
        logo: <ArlaIcon height="32px" width="49px" />,
        name: 'Arla Foods',
        totalPL: 166.31,
        totalPLPerc: 18.57,
        valuation: 157180,
    },
    {
        dailyPL: -46.44,
        dailyPLPerc: 10.32,
        id: 'AIRTL',
        logo: <AirtelIcon height="30px" width="34px" />,
        name: 'Airtel Nigeria',
        totalPL: 145.27,
        totalPLPerc: 20.32,
        valuation: 240000.43,
    },
    {
        dailyPL: -4.93,
        dailyPLPerc: 18.57,
        id: 'BSNDY',
        logo: <BusinessDayIcon height="13px" width="56px" />,
        name: 'Business Day',
        totalPL: 127.94,
        totalPLPerc: 10,
        valuation: 179843.84,
    },
    {
        dailyPL: 23.4,
        dailyPLPerc: -11.98,
        id: 'DNO',
        logo: <DanoIcon height="27px" width="42px" />,
        name: 'Dano',
        totalPL: 149.32,
        totalPLPerc: 34,
        valuation: 350500.43,
    },
    {
        dailyPL: 46.44,
        dailyPLPerc: -7.02,
        id: 'ARLA',
        logo: <ArlaIcon height="32px" width="49px" />,
        name: 'Arla Foods',
        totalPL: 166.31,
        totalPLPerc: 18.57,
        valuation: 157180,
    },
    {
        dailyPL: -46.44,
        dailyPLPerc: 10.32,
        id: 'AIRTL',
        logo: <AirtelIcon height="30px" width="34px" />,
        name: 'Airtel Nigeria',
        totalPL: 145.27,
        totalPLPerc: 20.32,
        valuation: 240000.43,
    },
    {
        dailyPL: -4.93,
        dailyPLPerc: 18.57,
        id: 'BSNDY',
        logo: <BusinessDayIcon height="13px" width="56px" />,
        name: 'Business Day',
        totalPL: 127.94,
        totalPLPerc: 10,
        valuation: 179843.84,
    },
    {
        dailyPL: 23.4,
        dailyPLPerc: -11.98,
        id: 'DNO',
        logo: <DanoIcon height="27px" width="42px" />,
        name: 'Dano',
        totalPL: 149.32,
        totalPLPerc: 34,
        valuation: 350500.43,
    },
    {
        dailyPL: 46.44,
        dailyPLPerc: -7.02,
        id: 'ARLA',
        logo: <ArlaIcon height="32px" width="49px" />,
        name: 'Arla Foods',
        totalPL: 166.31,
        totalPLPerc: 18.57,
        valuation: 157180,
    },
    {
        dailyPL: -46.44,
        dailyPLPerc: 10.32,
        id: 'AIRTL',
        logo: <AirtelIcon height="30px" width="34px" />,
        name: 'Airtel Nigeria',
        totalPL: 145.27,
        totalPLPerc: 20.32,
        valuation: 240000.43,
    },
    {
        dailyPL: -4.93,
        dailyPLPerc: 18.57,
        id: 'BSNDY',
        logo: <BusinessDayIcon height="13px" width="56px" />,
        name: 'Business Day',
        totalPL: 127.94,
        totalPLPerc: 10,
        valuation: 179843.84,
    },
    {
        dailyPL: 23.4,
        dailyPLPerc: -11.98,
        id: 'DNO',
        logo: <DanoIcon height="27px" width="42px" />,
        name: 'Dano',
        totalPL: 149.32,
        totalPLPerc: 34,
        valuation: 350500.43,
    },
    {
        dailyPL: 46.44,
        dailyPLPerc: -7.02,
        id: 'ARLA',
        logo: <ArlaIcon height="32px" width="49px" />,
        name: 'Arla Foods',
        totalPL: 166.31,
        totalPLPerc: 18.57,
        valuation: 157180,
    },
    {
        dailyPL: -46.44,
        dailyPLPerc: 10.32,
        id: 'AIRTL',
        logo: <AirtelIcon height="30px" width="34px" />,
        name: 'Airtel Nigeria',
        totalPL: 145.27,
        totalPLPerc: 20.32,
        valuation: 240000.43,
    },
    {
        dailyPL: -4.93,
        dailyPLPerc: 18.57,
        id: 'BSNDY',
        logo: <BusinessDayIcon height="13px" width="56px" />,
        name: 'Business Day',
        totalPL: 127.94,
        totalPLPerc: 10,
        valuation: 179843.84,
    },
    {
        dailyPL: 23.4,
        dailyPLPerc: -11.98,
        id: 'DNO',
        logo: <DanoIcon height="27px" width="42px" />,
        name: 'Dano',
        totalPL: 149.32,
        totalPLPerc: 34,
        valuation: 350500.43,
    },
];

export const purchaseSellData = [
    {
        contractNo: 'James Abo',
        orderNo: '#11609494',
        transDate: '06/10/21',
        price: 'Buy',
        quantity: 54,
        symbol: 'Transcorp',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Abo',
        orderNo: '#11609494',
        transDate: '06/10/21',
        price: 'Buy',
        quantity: 54,
        symbol: 'Transcorp',
        contractAmt: 'Limit',
        orderStatus: 'Failed',
    },
    {
        contractNo: 'Andrew Abo',
        orderNo: '#238509494',
        transDate: '06/10/21',
        price: 'Sell',
        quantity: 22,
        symbol: 'First Bank',
        contractAmt: 'Market',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Abo',
        orderNo: '#11609494',
        transDate: '06/10/21',
        price: 'Buy',
        quantity: 54,
        symbol: 'Transcorp',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Labo',
        orderNo: '#00209494',
        transDate: '11/10/21',
        price: 'Sell',
        quantity: 21,
        symbol: 'Eterna',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'John Doe',
        orderNo: '#28439494',
        transDate: '12/10/21',
        price: 'Buy',
        quantity: 5,
        symbol: 'Oando',
        contractAmt: 'Market',
        orderStatus: 'Failed',
    },
    {
        contractNo: 'Jimmy Philips',
        orderNo: '#98409494',
        transDate: '13/10/21',
        price: 'Buy',
        quantity: 12,
        symbol: 'Oando',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Abo',
        orderNo: '#11604830',
        transDate: '14/10/21',
        price: 'Sell',
        quantity: 54,
        symbol: 'Transcorp',
        contractAmt: 'Market',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'Mariam Udu',
        orderNo: '#05439494',
        transDate: '15/10/21',
        price: 'Buy',
        quantity: 65,
        symbol: 'Domino',
        contractAmt: 'Market',
        orderStatus: 'Failed',
    },
    {
        contractNo: 'John Abo',
        orderNo: '#05043494',
        transDate: '16/10/21',
        price: 'Buy',
        quantity: 2,
        symbol: 'Spotify',
        contractAmt: 'Limit',
        orderStatus: 'Failed',
    },
    {
        contractNo: 'Layla Croft',
        orderNo: '#03832494',
        transDate: '17/10/21',
        price: 'Buy',
        quantity: 54,
        symbol: 'Arla',
        contractAmt: 'Market',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'John Doe',
        orderNo: '#28439494',
        transDate: '12/10/21',
        price: 'Sell',
        quantity: 5,
        symbol: 'Oando',
        contractAmt: 'Market',
        orderStatus: 'Failed',
    },
    {
        contractNo: 'Jimmy Philips',
        orderNo: '#98409494',
        transDate: '13/10/21',
        price: 'Buy',
        quantity: 12,
        symbol: 'Oando',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Abo',
        orderNo: '#11604830',
        transDate: '14/10/21',
        price: 'Buy',
        quantity: 70,
        symbol: 'Transcorp',
        contractAmt: 'Market',
        orderStatus: 'Failed',
    },
    {
        contractNo: 'Mariam Udu',
        orderNo: '#05439494',
        transDate: '15/10/21',
        price: 'Sell',
        quantity: 65,
        symbol: 'Domino',
        contractAmt: 'Market',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'John Abo',
        orderNo: '#05043494',
        transDate: '16/10/21',
        price: 'Sell',
        quantity: 2,
        symbol: 'Spotify',
        contractAmt: 'Limit',
        orderStatus: 'Failed',
    },
    {
        contractNo: 'Layla Croft',
        orderNo: '#03832494',
        transDate: '17/10/21',
        price: 'Sell',
        quantity: 54,
        symbol: 'Arla',
        contractAmt: 'Market',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Abo',
        orderNo: '#11609494',
        transDate: '06/10/21',
        price: 'Buy',
        quantity: 54,
        symbol: 'Transcorp',
        contractAmt: 'Limit',
        orderStatus: 'Failed',
    },
    {
        contractNo: 'Andrew Abo',
        orderNo: '#238509494',
        transDate: '06/10/21',
        price: 'Buy',
        quantity: 22,
        symbol: 'First Bank',
        contractAmt: 'Market',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Abo',
        orderNo: '#11609494',
        transDate: '06/10/21',
        price: 'Sell',
        quantity: 154,
        symbol: 'Transcorp',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Labo',
        orderNo: '#00209494',
        transDate: '11/10/21',
        price: 'Sell',
        quantity: 21,
        symbol: 'Eterna',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'John Doe',
        orderNo: '#28439494',
        transDate: '12/10/21',
        price: 'Buy',
        quantity: 5,
        symbol: 'Oando',
        contractAmt: 'Market',
        orderStatus: 'Failed',
    },
    {
        contractNo: 'Jimmy Philips',
        orderNo: '#98409494',
        transDate: '13/10/21',
        price: 'Sell',
        quantity: 12,
        symbol: 'Oando',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Abo',
        orderNo: '#11604830',
        transDate: '14/10/21',
        price: 'Sell',
        quantity: 54,
        symbol: 'Transcorp',
        contractAmt: 'Market',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'Jimmy Philips',
        orderNo: '#98409494',
        transDate: '13/10/21',
        price: 'Sell',
        quantity: 12,
        symbol: 'Oando',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Abo',
        orderNo: '#11604830',
        transDate: '14/10/21',
        price: 'Sell',
        quantity: 54,
        symbol: 'Transcorp',
        contractAmt: 'Market',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'Jimmy Philips',
        orderNo: '#98409494',
        transDate: '13/10/21',
        price: 'Sell',
        quantity: 12,
        symbol: 'Oando',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Abo',
        orderNo: '#11604830',
        transDate: '14/10/21',
        price: 'Sell',
        quantity: 54,
        symbol: 'Transcorp',
        contractAmt: 'Market',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'Jimmy Philips',
        orderNo: '#98409494',
        transDate: '13/10/21',
        price: 'Sell',
        quantity: 12,
        symbol: 'Oando',
        contractAmt: 'Limit',
        orderStatus: 'Successful',
    },
    {
        contractNo: 'James Abo',
        orderNo: '#11604830',
        transDate: '14/10/21',
        price: 'Sell',
        quantity: 54,
        symbol: 'Transcorp',
        contractAmt: 'Market',
        orderStatus: 'Successful',
    },
];

export const cashData = [
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'NGN',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'NGN',
        debit: 50000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'USD',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'USD',
        debit: 10000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'EUR',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'EUR',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'NGN',
        debit: 10000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'NGN',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'NGN',
        debit: 10000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'NGN',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'EUR',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'EUR',
        debit: 10000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'EUR',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'EUR',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'USD',
        debit: 10000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'USD',
        debit: 10000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'NGN',
        debit: 15000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'NGN',
        debit: 345000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'USD',
        debit: 345000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'USD',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'NGN',
        debit: 2000,
        credit: 30000,
        balance: 25483.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'NGN',
        debit: 2000,
        credit: 30000,
        balance: 3453.75,
        transDate: '06/10/21',
        valueDate: '06/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Opening Bal',
        currency: 'RDN',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '10/10/21',
        valueDate: '10/10/21',
    },
    {
        name: 'CSH-TRNS',
        desc: 'Closing Bal',
        currency: 'RDN',
        debit: 2000,
        credit: 30000,
        balance: 25122.75,
        transDate: '10/10/21',
        valueDate: '10/10/21',
    },
];
