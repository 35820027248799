import {gql} from '@apollo/client';

export const GET_CORPORATE_BENEFITS = gql`
    query GetCorporateBenefits(
        $limit: Int
        $offset: Int
        $bonus: numeric_comparison_exp = {}
        $final: Boolean_comparison_exp = {}
        $company: String_comparison_exp = {}
    ) {
        corporate_benefit(
            limit: $limit
            offset: $offset
            where: {bonus: $bonus, final: $final, stock: {label: $company}}
        ) {
            id
            dividend_declared
            bonus
            dividend_yield
            final
            declaration_date
            qualification_date
            closure_date
            stock {
                id
                label
                currency
            }
        }
        corporate_benefit_aggregate(
            where: {bonus: $bonus, final: $final, stock: {label: $company}}
        ) {
            aggregate {
                count
            }
        }
    }
`;
