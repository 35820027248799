import {useState} from 'react';
import {useQuery} from '@apollo/client';
import NxNavTabs from 'utils/NavTabs/NxNavTabs';
import KYCComponent from 'components/Dashboard/common/KYC/KYC';
import Banking from 'components/Dashboard/common/Banking/Banking';
import Profile from 'components/Dashboard/common/Profile/Profile';
import WatchList from 'components/Dashboard/common/WatchList/WatchList';
import Investment from 'components/Dashboard/common/Investment/Investment';
// import Statements from "components/Dashboard/common/Statements/Statements";
import Transactions from 'components/Dashboard/common/Transactions/Transactions';
import {GET_PORTFOLIO_ACCOUNTS} from 'api/queries/portfolio';
import {
    PROFILE,
    KYC,
    BANKING,
    INVESTMENT,
    PORTFOLIO,
    TRANSACTIONS,
    ORDERS,
    STATEMENTS,
    WATCHLIST,
} from '../../../constants';
import {detailsTabList} from '../helpers/enums';
import CustomerPortfolio from './CustomerPortfolio';
import CustomerOrders from './CustomerOrders/CustomerOrders';
import CustomerOrderStatement from './CustomerOrderStatement/CustomerOrderStatement';

const CustomerDetails = ({duplicates, data = {}}) => {
    const id = Math.floor(Math.random() * (duplicates * 3));
    const [account, setAccount] = useState([]);

    const getPortfolioAccountQuery = useQuery(GET_PORTFOLIO_ACCOUNTS, {
        variables: {customerId: data?.id},
        onCompleted: ({portfolio_accounts}) => {
            if (portfolio_accounts?.length) setAccount?.(portfolio_accounts[0]);
        },
    });

    const detailsComponentsList = [
        {
            component: <Profile data={data} />,
            id: PROFILE,
        },
        {
            component: <KYCComponent data={data} />,
            id: KYC,
        },
        {
            component: <Banking data={data} />,
            id: BANKING,
        },
        {
            component: <Investment data={data} />,
            id: INVESTMENT,
        },
        {
            component: (
                <CustomerPortfolio
                    accounts={getPortfolioAccountQuery?.data}
                    account={account}
                    setAccount={setAccount}
                    customerId={data?.id}
                />
            ),
            id: PORTFOLIO,
        },
        {
            component: <Transactions tableCls="table__short_height" data={data} />,
            id: TRANSACTIONS,
        },
        {
            component: (
                <CustomerOrders
                    tableCls="table__short_height"
                    accounts={getPortfolioAccountQuery?.data?.portfolio_accounts}
                    customerId={data?.id}
                />
            ),
            id: ORDERS,
        },
        {
            component: (
                <CustomerOrderStatement
                    accounts={getPortfolioAccountQuery?.data?.portfolio_accounts}
                    cash_accounts={getPortfolioAccountQuery?.data?.cash_accounts}
                    customerId={data?.id}
                />
            ),
            id: STATEMENTS,
        },
        {
            component: <WatchList customerId={data?.id} />,
            id: WATCHLIST,
        },
    ];

    return (
        <div className="wrapper__customer_details py-4">
            <NxNavTabs cls="mx-4" tabList={detailsTabList} id={id} rawTitle />
            <div className="tab-content" id="myTabContent">
                {detailsComponentsList.map((detail, index) => (
                    <div
                        key={index}
                        className={`tab-pane fade show ${index === 0 ? 'active' : ''}`}
                        id={`${detail.id}${id}`}
                        role="tabpanel"
                        aria-labelledby={`${detail.id}${id}-tab`}
                    >
                        {detail.component}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomerDetails;
