import BioInfo from './BioInfo/BioInfo';
import BankInfo from './BankInfo/BankInfo';
import NextOfKinInfo from './NextOfKinInfo/NextOfKinInfo';
import Accordion from '../../../../utils/Accordion/Accordion';
import SectionContainer from '../../../../utils/Section/SectionContainer/SectionContainer';
import FacialComparison from './FacialComparison/FacialComparison';

const KYC = ({data}) => {
    const KYCAccordionEnums = [
        {
            title: 'BVN Information',
            body: <BioInfo data={data} />,
        },
        {
            title: 'NIN Information',
            body: <BioInfo data={data} />,
        },
        {
            title: 'Next of Kin',
            body: <NextOfKinInfo customerId={data?.id} />,
        },
        {
            title: 'Bank Information',
            body: <BankInfo account={data?.account} />,
        },
        {
            title: 'Facial Comparison',
            body: <FacialComparison account={data?.kyc_information} />,
        },
    ];
    return (
        <SectionContainer cls="mx-4 mt-4">
            <Accordion
                cls="customer-accordion"
                id="customer-accordion"
                itemsList={KYCAccordionEnums}
            />
        </SectionContainer>
    );
};

export default KYC;
