import React from 'react';

import KYCItem from '../KYCItem/KYCItem';
import SectionWrapper from '../../../../../utils/Section/SectionWrapper/SectionWrapper';

const BioInfo = ({data}) => {
    const bioInfoItems = [
        data?.title || 'Title Not Available',
        data?.first_name || 'Firstname Not Available',
        data?.middle_name || 'Middlename Not Available',
        data?.last_name || 'Lastname Not Available',
        data?.email || 'Email Not Available',
        data?.phone || 'Phone Number Not Available',
        data?.dob || 'DOB Not Available',
        data?.kyc_information?.mothers_maiden_name || 'Maiden Name Not Available',
        data?.gender || 'Gender Not Available',
        data?.kyc_information?.nationality || 'Nationality Not Available',
        data?.kyc_information?.state_of_origin || 'State of Origin Not Available',
        data?.kyc_information?.lga_of_origin || 'LGA Not Available',
        data?.kyc_information?.residence_street || 'Address Not Available',
        data?.kyc_information?.residence_city || 'City Not Available',
        data?.kyc_information?.residence_state || 'Residence Not Available',
        'Clearing House Number',
        data?.kyc_information?.religion || 'Religion Not Available',
        data?.kyc_information?.postal_code || 'Postal Code Not Available',
        data?.kyc_information?.profession || 'Profession Not Available',
        data?.kyc_information?.employer_name || 'Employer Name Not Available',
        data?.kyc_information?.employer_address || 'Employer Address Not Available',
        data?.kyc_information?.marital_status || 'Marital Status Not Available',
    ];
    return (
        <SectionWrapper>
            <div className="wrapper__overflow wrapper__customer__details">
                <div className="flex__between flex-wrap">
                    {bioInfoItems.map((item, index) => (
                        <KYCItem cls="wrapper__width_31_perc" item={item} key={index} />
                    ))}
                </div>
            </div>
        </SectionWrapper>
    );
};

export default BioInfo;
