import {ReactComponent as HelpIcon} from 'assets/images/icons/help.svg';
import BioInfo from '../KYC/BioInfo/BioInfo';
import BankInfo from '../KYC/BankInfo/BankInfo';
import Documents from '../Profile/Documents/Documents';
import Investment from '../Profile/Investment/Investment';
import NextOfKinInfo from '../KYC/NextOfKinInfo/NextOfKinInfo';
import PersonalInfo from '../Profile/PersonalInfo/PersonalInfo';
import EditButton from '../WatchList/buttons/EditButton/EditButton';
import AddNewButton from '../WatchList/buttons/AddNewButton/AddNewButton';
import DeleteButton from '../WatchList/buttons/DeleteButton/DeleteButton';
import WatchListDetails from '../WatchList/WatchListDetails/WatchListDetails';

import {
    CASH,
    BONDS,
    MARGIN,
    BUY,
    SELL,
    STOCK_NGX,
    STOCK_NASD,
    PORTFOLIO_NGX,
    PORTFOLIO_NASD,
    MONEY_MARKET,
    FUND,
    WITHDRAW,
    ALL,
} from '../../../constants';

// Table Headings

export const userWalletInfoHeadings = [
    '',
    'Wallet Reference',
    'Wallet Name',
    'Account Number',
    'Bank Name',
    '',
];

export const cashHeading = [
    'Name',
    'Description',
    'Currency',
    'Debit',
    'Credit',
    'Balance',
    'Trans Date',
    'Value Date',
];

export const investmentHeadings = [
    'Logo',
    'Name',
    'Symbol',
    'Exchange',
    'Currency',
    'Price',
    'Daily P/L (N)',
    'Daily P/L (%)',
];

export const orderHeading = [
    'Username',
    'Order No.',
    'Date',
    'Order Type',
    'Quantity',
    'Symbol',
    'Price Type',
    'Term',
    'Order Status',
];

export const purchaseSellHeading = [
    'Contract No.',
    'Order No.',
    'Trans Date',
    'Price',
    'Quantity',
    'Symbol',
    'Contract Amount',
    'Order Status',
];

export const statementHeading = [purchaseSellHeading, purchaseSellHeading, cashHeading];

export const tradeHeading = [
    'Order No.',
    'Date',
    'Order Type',
    'Quantity',
    'Symbol',
    'Price Type',
    'Term',
    'Order Status',
    'Market Status',
];

export const walletHeading = [
    ' ',
    'Transaction Reference',
    'Wallet Name',
    'Amount Funded',
    'Account Funded',
    'Transaction Type',
    'Payment Method',
    'Transaction Status',
    'Date',
];

export const portfolioInfoHeading = [
    'Name',
    'Symbol',
    'Last Price (₦)',
    'Quantity',
    'Price Paid (₦)',
    'Current Value (₦)',
    'Total Cost (₦)',
    'Total P/L (₦)',
    'Total P/L (%)',
];

// Items

export const bankInfoItems = ['Account Name', 'Account Number', 'Bank Name'];

export const filesList = [
    'Passport Photo',
    "National Passport/Driver's License",
    'Scanned Utility Bill',
    'Signature',
];

export const nextOfKinItems = [
    'Name of Kin',
    'Next of Kin Email Address',
    'Next of Kin Phone Number',
    'Next of Kin Relationship',
];

export const bioInfoItems = [
    'Title',
    'First Name',
    'Middle Name',
    'Last Name',
    'Email Address',
    'Mobile Phone',
    'Date of Birth',
    "Mother's Maiden Name",
    'Gender',
    'Nationality',
    'State of Origin',
    'Local Govt. of Origin',
    'Street',
    'City',
    'State',
    'Clearing House Number',
    'Region',
    'Postal Code',
    'Profession',
    'Employer Name',
    'Employer Address',
    'Marital Status',
    'Postal Code',
    'Profession',
];

export const portfolioEval = [
    {
        title: 'Total Valuation',
        value: 5091422.5,
    },
    {
        title: 'Total P/L',
        value: 47502.5,
        valueCls: 'text--danger',
    },
    {
        title: 'Available Balance',
        value: 1612880.11,
    },
    {
        title: 'Total Balance',
        value: 2384854.43,
    },
];

// Form Defaults

export const personalInfoFormDefaults = {
    firstName: '',
    lastName: '',
    username: '',
    phoneNumber: '',
    email: '',
    accountType: null,
    securityQuestion: '',
    securityAnswer: '',
};

// Component Enums

export const profileAccordionEnums = [
    {
        title: 'Personal Information',
        body: <PersonalInfo />,
    },
    {
        title: 'Documents',
        body: <Documents />,
    },
    {
        title: 'Investments',
        body: <Investment />,
    },
];

export const KYCAccordionEnums = [
    {
        title: 'BVN Information',
        body: <BioInfo />,
    },
    {
        title: 'NIN Information',
        body: <BioInfo />,
    },
    {
        title: 'Next of Kin',
        body: <NextOfKinInfo />,
    },
    {
        title: 'Bank Information',
        body: <BankInfo />,
    },
];

// Options

export const accountTypeOptions = [
    {label: 'Cash', value: 'Cash'},
    {label: 'Crypto', value: 'Crypto'},
    {label: 'Currency', value: 'Currency'},
];

export const statInvOptions = [STOCK_NGX, STOCK_NASD, MARGIN, MONEY_MARKET, BONDS];

export const portfolioOptions = [MARGIN, PORTFOLIO_NGX, PORTFOLIO_NASD, BONDS, MONEY_MARKET];

export const changeTimeOptions = ['Today', 'Week', 'Month', 'Quarter', 'Biannual', 'Year'];

export const walletOptions = [ALL, FUND, WITHDRAW];

export const filterOptions = ['Equity', 'Quality', 'Company'];

export const tradeOptions = ['All', 'Success', 'Pending', 'Cancelled'];

export const transactionsFilterOptions = ['Equity', 'Quality', 'Company'];

// TabLists

export const orderTabList = ['executing', 'executed', 'cancelled', 'expired'];

export const statementsTabList = [BUY, SELL, CASH];

export const transactionsTabList = ['trade', 'wallet'];

// * Filter Parameters

export const ordersFilterParams = [
    {
        name: 'orderType',
        label: 'Order Type',
        options: [
            {label: 'Buy', value: 'Buy'},
            {label: 'Sell', value: 'Sell'},
        ],
    },
    {
        name: 'quantity',
        label: 'Quantity',
    },
    {
        name: 'symbol',
        label: 'Symbol',
    },
    {
        name: 'priceType',
        label: 'Price Type',
        options: [
            {label: 'Limit', value: 'Limit'},
            {label: 'Market', value: 'Market'},
        ],
    },
    {
        name: 'term',
        label: 'Term',
    },
];

export const userWalletInfoFilterParams = [
    {
        name: 'reference',
        label: 'Reference',
        query: '_iregex',
    },
    {
        name: 'account_number',
        label: 'Account Number',
        query: '_iregex',
    },
    {
        name: 'account_name',
        label: 'Account Name',
        query: '_iregex',
    },
    {
        name: 'bank_name',
        label: 'Bank Name',
        query: '_iregex',
    },
];

export const walletTransFilterParams = [
    ...userWalletInfoFilterParams,
    {
        name: 'account-funded',
        label: 'Account Funded',
        options: [
            {label: 'Margin', value: 'Margin'},
            {label: 'Cash', value: 'Cash'},
        ],
    },
    {
        name: 'transaction-type',
        label: 'Transaction Type',
        options: [
            {label: 'Fund', value: 'Fund'},
            {label: 'Transfer', value: 'Transfer'},
        ],
    },
];

export const transactionsTradeFilterParams = [
    {
        name: 'order-no',
        label: 'Order No',
    },
    {
        name: 'order-type',
        label: 'Order Type',
        options: [
            {label: 'Buy', value: 'Buy'},
            {label: 'Sell', value: 'Sell'},
        ],
    },
    {
        name: 'symbol',
        label: 'Symbol',
    },
    {
        name: 'price-type',
        label: 'Price Type',
        options: [
            {label: 'Limit', value: 'Limit'},
            {label: 'Market', value: 'Market'},
        ],
    },
    {
        name: 'order-status',
        label: 'Order Status',
        options: [
            {label: 'Executed', value: 'Executed'},
            {label: 'Pending', value: 'Pending'},
            {label: 'Cancelled', value: 'Cancelled'},
        ],
    },
    {
        name: 'market-status',
        label: 'Market Status',
        options: [
            {label: 'Success', value: 'Success'},
            {label: 'Pending', value: 'Pending'},
            {label: 'Cancelled', value: 'Cancelled'},
        ],
    },
];

export const transactionsWalletFilterParams = [
    {
        name: 'wallet-name',
        label: 'Wallet Name',
    },
    {
        name: 'account-funded',
        label: 'Account Funded',
        options: [
            {label: 'Margin', value: 'Margin'},
            {label: 'Cash', value: 'Cash'},
        ],
    },
    {
        name: 'transaction-type',
        label: 'Transaction Type',
        options: [
            {label: 'Fund', value: 'Fund'},
            {label: 'Transfer', value: 'Transfer'},
        ],
    },
];

// Form Layouts

export const personalInfoFormParams = [
    {
        name: 'firstName',
        label: 'First Name',
        required: true,
    },
    {
        name: 'lastName',
        label: 'Last Name',
        required: true,
    },
    {
        name: 'username',
        label: 'Username',
        required: true,
    },
    {
        name: 'phoneNumber',
        label: 'Phone Number',
        required: true,
    },
    {
        name: 'email',
        label: 'Email',
        required: true,
    },
    {
        name: 'accountType',
        label: 'Account Type',
        required: true,
        options: accountTypeOptions,
    },
    {
        name: 'securityQuestion',
        label: 'Security Question',
        labelIcon: <HelpIcon height="13px" width="13px" />,
    },
    {
        name: 'securityAnswer',
        label: 'Security Answer',
        labelIcon: <HelpIcon height="13px" width="13px" />,
    },
];
