import {ReactComponent as Home} from '../../assets/images/icons/sidebar/home.svg';
import {ReactComponent as RequestIcon} from '../../assets/images/icons/sidebar/request.svg';
import {ReactComponent as CustomerIcon} from '../../assets/images/icons/sidebar/customer.svg';
import {ReactComponent as ResearchIcon} from '../../assets/images/icons/sidebar/research.svg';
import {ReactComponent as SwapIcon} from '../../assets/images/icons/sidebar/swap.svg';
import {ReactComponent as UserIcon} from '../../assets/images/icons/sidebar/users.svg';
import {ReactComponent as WalletIcon} from '../../assets/images/icons/sidebar/wallet.svg';

import DashContent from './DashContent/DashContent';
import Customers from './Customers/Customers';
import Users from './Users/UsersContent/UsersContent';
import Wallets from './Wallets/Wallets';
import WalletDetails from './Wallets/WalletDetails/WalletDetails';
import {allAccessLevel, allPermissions} from 'components/App/AccessControl/permissions';
import CorporateBenefitContent from './Research/CorporateBenefitContent/CorporateBenefitContent';
import PortfolioReportContent from './Research/PortfolioReportContent/PortfolioReportContent';
import PendingContent from './Requests/PendingContent/PendingContent';
import ApprovedContent from './Requests/ApprovedContent/ApprovedContent';
import WalletContent from './Transactions/WalletContent/WalletContent';

const routes = [
    {
        name: 'Dashboard',
        path: '/',
        component: DashContent,
        exact: true,
        icon: Home,
        accessLevel: allAccessLevel,
    },
    {
        name: 'Requests',
        path: '/requests',
        exact: true,
        icon: RequestIcon,
        accessLevel: [allPermissions.CAN_VIEW_REQUESTS],
        dropDowns: [
            {
                name: 'Pending Requests',
                path: '/requests',
                component: PendingContent,
                exact: true,
                accessLevel: [allPermissions.CAN_VIEW_REQUESTS],
            },
            {
                name: 'Approved Requests',
                path: '/requests/approved',
                component: ApprovedContent,
                exact: true,
                accessLevel: [allPermissions.CAN_VIEW_REQUESTS],
            },
        ],
    },
    {
        name: 'Customers',
        path: '/customers',
        component: Customers,
        exact: true,
        icon: CustomerIcon,
        accessLevel: [allPermissions.CAN_VIEW_CUSTOMERS],
    },
    {
        name: 'Transactions',
        path: '/transactions',
        component: WalletContent,
        exact: true,
        icon: SwapIcon,
        accessLevel: [allPermissions.CAN_VIEW_TRANSACTIONS],
    },
    {
        name: 'Wallets',
        path: '/wallets',
        component: Wallets,
        exact: true,
        icon: WalletIcon,
        accessLevel: [allPermissions.CAN_VIEW_WALLETS],
    },
    {
        name: 'Wallets User',
        path: '/wallets/:user_id',
        component: WalletDetails,
        exact: true,
        icon: WalletIcon,
        hide: true,
        accessLevel: allAccessLevel,
    },
    {
        name: 'Research',
        path: '/research',
        exact: true,
        icon: ResearchIcon,
        accessLevel: [allPermissions.CAN_VIEW_CORPORATE_BENEFITS],
        dropDowns: [
            {
                name: 'Corporate Benefits',
                path: '/research',
                component: CorporateBenefitContent,
                exact: true,
                accessLevel: [allPermissions.CAN_VIEW_CORPORATE_BENEFITS],
            },
            {
                name: 'Portfolio Reports',
                path: '/research/portfolio-reports',
                component: PortfolioReportContent,
                exact: true,
                accessLevel: [allPermissions.CAN_VIEW_CORPORATE_BENEFITS],
            },
        ],
    },
    {
        name: 'Users',
        path: '/users',
        component: Users,
        exact: true,
        icon: UserIcon,
        accessLevel: [allPermissions.CAN_VIEW_ADMIN],
    },
];

export default routes;
