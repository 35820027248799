import React, {useState} from 'react';
import {ReactComponent as TrashIcon} from 'assets/images/icons/trash.svg';
import DeleteCorporateBenefitModal from '../../modals/DeleteCorporateBenefitModal/DeleteCorporateBenefitModal';

const DeleteCorporateBenfitButton = ({data}) => {
    const [openModal, setOpenModal] = useState(false);
    const toggleModal = () => setOpenModal(prev => !prev);

    return (
        <>
            <TrashIcon className="pointer danger" onClick={toggleModal} />

            {openModal && (
                <DeleteCorporateBenefitModal data={data} show={openModal} toggle={toggleModal} />
            )}
        </>
    );
};

export default DeleteCorporateBenfitButton;
