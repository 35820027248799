import {useState} from 'react';

//SVG Icons
import {ReactComponent as UserIcon} from 'assets/images/icons/user.svg';
import {ReactComponent as BrailleDotIcon} from 'assets/images/icons/brailledot.svg';

//Components
import Table from 'utils/Table/Table';
import EmptyData from 'utils/Table/EmptyData';
import Dropdown from 'utils/Dropdown/Dropdown';
import FileAttached from '../../FileAttached/FileAttached';
import TableFooter from 'utils/Table/TableFooter/TableFooter';
import AccessControl from 'components/App/AccessControl/AccessControl';
import ActionRequestModal from '../../modals/ActionRequestModal/ActionRequestModal';
// import CancelRequestButton from "../../buttons/CancelRequestButton/CancelRequestButton";

//Utils
import {titleCase} from 'utils/helpers';
import {ACCEPT} from 'components/constants';
import {DECLINE} from 'components/constants';
import {timeDateFormat} from 'utils/helpers';
import {requestOptions, requestsHeading} from '../../helpers/enums';
import {allPermissions} from 'components/App/AccessControl/permissions';

const PendingTable = ({data, limit, currentPage, handleLimitChange, handlePagination}) => {
    const {
        margin_request,
        margin_request_aggregate: {
            aggregate: {count: total},
        },
    } = data;

    const [selectedRow, setSelectedRow] = useState();

    const len = total === 0 || !Boolean(margin_request.length);

    const totalPages = Math.ceil(total / limit) || 1;

    const [modal, setModal] = useState({
        [ACCEPT]: false,
        [DECLINE]: false,
    });

    const toggleModal = state => {
        setModal({
            ...modal,
            [state]: !modal[state],
        });
    };

    const handleDropDown = (i, request) => {
        setSelectedRow(request);
        toggleModal(requestOptions[i]);
    };

    const getPendingData = () =>
        margin_request?.map(request => ({
            icon: <UserIcon width="32px" height="32px" />,
            name: `${request?.user?.first_name} ${request?.user?.last_name}`,
            email: request?.user?.email,
            id: request?.user?.id,
            createdAt: `${timeDateFormat(request?.created_at)?.date} ${
                timeDateFormat(request?.created_at)?.time
            }`,
            fileAttached: (
                <FileAttached
                    request={request}
                    extra={
                        <AccessControl allowedPermissions={[allPermissions.CAN_APPROVE_REQUEST]}>
                            <div className="d-flex">
                                {/* <CancelRequestButton request={request} /> */}
                                <Dropdown
                                    action={i => handleDropDown(i, request)}
                                    cls="ms-5"
                                    icon={
                                        <BrailleDotIcon
                                            className="gray"
                                            height="11px"
                                            width="3px"
                                        />
                                    }
                                    id="request-options"
                                    options={requestOptions?.map(option => titleCase(option))}
                                />
                            </div>
                        </AccessControl>
                    }
                />
            ),
        }));

    return (
        <div
            className="tab-pane fade show active"
            id="pending"
            role="tabpanel"
            aria-labelledby="pending-tab"
        >
            {!len ? (
                <>
                    <Table data={getPendingData()} headings={requestsHeading} rowClick={() => {}} />

                    <TableFooter
                        currentPage={currentPage || 0}
                        totalPages={totalPages}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                    />

                    {modal[ACCEPT] && (
                        <ActionRequestModal
                            type={ACCEPT}
                            show={modal[ACCEPT]}
                            data={selectedRow}
                            toggle={() => toggleModal(ACCEPT)}
                        />
                    )}

                    {modal[DECLINE] && (
                        <ActionRequestModal
                            type={DECLINE}
                            show={modal[DECLINE]}
                            data={selectedRow}
                            toggle={() => toggleModal(DECLINE)}
                        />
                    )}
                </>
            ) : (
                <EmptyData
                    title="No Pending Requests Yet"
                    subtitle="All Pending Requests will be displayed here"
                />
            )}
        </div>
    );
};

export default PendingTable;
