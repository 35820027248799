import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';
import {defaultDateFilters} from '../../../constants';
import WalletTable from './WalletTable/WalletTable';
import {PAYMENT_TRANSACTIONS} from 'api/queries/transactions';
import {useQuery} from '@apollo/client';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import usePageProps from 'hooks/usePageProps';
import {
    walletTransactionFilterParams as filterParams,
    walletTransactionsExportHeadings,
} from '../enums';

const WalletContent = () => {
    const {
        limit,
        query,
        filters,
        currentPage,
        handlePagination,
        handleFilters,
        clearFilters,
        handleLimitChange,
    } = usePageProps({
        filterParams,
        defaultFilters: defaultDateFilters,
    });
    const [isToday, setIsToday] = useState(true);
    const {user_id} = useParams();

    const queryParams = {
        variables: {
            created_at: 'asc',
            limit: parseInt(limit),
            offset: currentPage * limit,
            ...query,
            time_range: {...query?.from, ...query?.to},
            user_id: user_id ? {_eq: parseInt(user_id) || 0} : {},
        },
    };

    console.log();

    const getPaymentTransactionsQuery = useQuery(PAYMENT_TRANSACTIONS, queryParams);

    return (
        <SectionContainer>
            <SectionWrapper
                title={`${user_id ? '' : 'All'} Wallet Transactions (${
                    getPaymentTransactionsQuery?.data?.payment_transaction_aggregate?.aggregate
                        .count || 0
                }) ${isToday ? ' - (Today)' : ' - (Date Range)'}`}
                allowSelectInMultiTab
                filterParams={filterParams}
                headerChildrenCls="col-md-4"
                largeSelectMobile
                showDownload
                filters={filters}
                defaultFilters={defaultDateFilters}
                clearFilters={clearFilters}
                handleFilters={handleFilters}
                query={PAYMENT_TRANSACTIONS}
                queryParams={queryParams}
                reportHeadings={walletTransactionsExportHeadings}
                downloadKey="payment_transaction"
                setIsToday={setIsToday}
            >
                <ContentWrapper
                    errorTitle="Error fetching wallet transactions"
                    currentQuery={getPaymentTransactionsQuery}
                >
                    <WalletTable
                        limit={limit}
                        currentPage={currentPage}
                        isFilter={Boolean(filters)}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                        data={getPaymentTransactionsQuery?.data}
                    />
                </ContentWrapper>
            </SectionWrapper>
        </SectionContainer>
    );
};

export default WalletContent;
