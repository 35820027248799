import {getError} from 'utils/helpers';
import MeriLoader from 'utils/Loader/MeriLoader/MeriLoader';
import FailedState from 'utils/StateStatus/FailedState';

const ContentWrapper = ({
    errorTitle,
    currentQuery,
    cls = '',
    children,
    isResolve,
    CustomLoader,
}) => {
    const renderByStatus = () => {
        const {loading, error, refetch} = currentQuery || {};

        if (loading) {
            if (CustomLoader) {
                return CustomLoader;
            }
            return (
                <div
                    className={`py-5 w-100 d-flex justify-content-center align-items-center ${cls}`}
                >
                    <MeriLoader showLogo={false} cls="mt-0" />
                </div>
            );
        }

        if (error)
            return (
                <>
                    {isResolve ? (
                        <small className="input-text-error p-0">{errorTitle}</small>
                    ) : (
                        <div className="table__error">
                            <FailedState
                                onRetry={refetch}
                                title={errorTitle}
                                errorMsg={getError(error)}
                            />
                        </div>
                    )}
                </>
            );

        return <>{children}</>;
    };
    return <>{renderByStatus()}</>;
};

export default ContentWrapper;
