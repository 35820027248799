import {gql} from '@apollo/client';

export const CREATE_ADMIN_USER = gql`
    mutation CreateAdminUser($data: admin_user_insert_input!) {
        insert_admin_user_one(object: $data) {
            id
        }
    }
`;

export const DELETE_ADMIN_USER = gql`
    mutation DeleteAdminUser($id: Int!) {
        delete_admin_user_by_pk(id: $id) {
            id
        }
    }
`;

export const EDIT_ADMIN_USER = gql`
    mutation UpdateAdminUser($data: admin_user_set_input!, $id: Int!) {
        update_admin_user_by_pk(pk_columns: {id: $id}, _set: $data) {
            id
        }
    }
`;
