import React from 'react';

import TotalCashVolume from './TotalCashVolume/TotalCashVolume';
import TotalMarketVolume from './TotalMarketVolume/TotalMarketVolume';

const VolumeCharts = () => (
    <div className="d-flex flex-wrap">
        <TotalMarketVolume />
        <TotalCashVolume />
    </div>
);

export default VolumeCharts;
