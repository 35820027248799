import React from 'react';
import {useQuery} from '@apollo/client';
import Stat from '../common/Stat/Stat';
import {walletStats} from '../helpers/enums';
import {PAYMENT_TRANSACTIONS_AGGREGATE, TODAY_PAYMENT_TRANSACTIONS} from 'api/queries/transactions';
import {toCurrency} from 'utils/helpers';
import {defaultDateFilters} from 'components/constants';

const WalletStats = () => {
    const variables = {
        time_range: {
            from: {_gte: defaultDateFilters.from},
            to: {_lte: defaultDateFilters.to},
        },
    };

    const getTransactionQuery = useQuery(PAYMENT_TRANSACTIONS_AGGREGATE);

    const getTodayTransactionQuery = useQuery(TODAY_PAYMENT_TRANSACTIONS, {variables});

    const {amount} = getTransactionQuery?.data?.payment_transaction_aggregate?.aggregate?.sum || {};
    const {amount: todayAmount} =
        getTodayTransactionQuery?.data?.payment_transaction_aggregate?.aggregate?.sum || {};

    return (
        <div className="d-flex wrapper__overflow wrapper__wallet_stats_wrapper">
            <Stat
                stat={{
                    title: 'Total Wallet Inflow',
                    total: toCurrency(amount || 0),
                    today: toCurrency(todayAmount || 0),
                    pillCls: 'primary__light',
                    textCls: 'text--primary',
                    arrowCls: 'primary',
                }}
            />

            {walletStats?.map((stat, index) => (
                <Stat stat={stat} key={index} />
            ))}
        </div>
    );
};

export default WalletStats;
