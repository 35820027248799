import {totalCashVolChartData, totalMarketVolChartData} from './data';

export const loadingOption = {
    text: 'Updating Chart...',
    color: '#078950',
    textColor: '#828282',
    maskColor: '#ffffff93',
    zlevel: 0,
};

export const marketVolChartOptions = {
    backgroundColor: '#078950',
    borderRadius: 13,
    color: ['#ffffff'],
    grid: {
        bottom: 25,
        containLabel: true,
        left: 20,
        right: 20,
        top: 25,
    },
    xAxis: {
        type: 'category',
        data: totalMarketVolChartData.map(volume => volume.month),
    },
    yAxis: {
        type: 'value',
    },
    series: [
        {
            barWidth: 6.62,
            barMaxWidth: 6.62,
            data: totalMarketVolChartData.map(volume => volume.value),
            name: 'market volume',
            roundCap: true,
            itemStyle: {
                borderColor: '#FFFFFF',
                borderRadius: 7,
                opacity: 0.95,
                borderWidth: 1,
            },
            smooth: true,
            type: 'bar',
        },
    ],
    textStyle: {
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 16.34,
        overflow: 'truncate',
        lineOverflow: 'truncate',
    },
    tooltip: {
        trigger: 'axis',
    },
};

export const cashVolChartOptions = {
    color: ['#078950', '#F5E479', '#45BB414F'],
    grid: {
        bottom: 70,
        containLabel: true,
        left: 20,
        right: 20,
        top: 25,
    },
    xAxis: {
        axisTick: {
            show: false,
        },
        type: 'category',
        data: totalCashVolChartData.map(volume => volume.month),
    },
    yAxis: {
        axisTick: {
            show: false,
        },
        type: 'value',
    },
    series: [
        {
            data: totalCashVolChartData.map(volume => volume.stocks),
            name: 'Stocks',
            smooth: true,
            type: 'line',
        },
        {
            data: totalCashVolChartData.map(volume => volume.moneyMarket),
            name: 'Money Market',
            smooth: true,
            type: 'line',
        },
        {
            data: totalCashVolChartData.map(volume => volume.bonds),
            name: 'Bonds',
            smooth: true,
            type: 'line',
        },
    ],
    tooltip: {
        trigger: 'axis',
    },
    legend: {
        bottom: 10,
        left: 0,
        icon: 'circle',
        data: ['Stocks', 'Money Market', 'Bonds'],
        itemStyle: {
            borderStyle: 'solid',
            borderCap: 'round',
        },
        textStyle: {
            color: '#67748E',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    textStyle: {
        color: '#828282',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 16.34,
        overflow: 'truncate',
        lineOverflow: 'truncate',
    },
};
