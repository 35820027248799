import React from 'react';
import {useQuery} from '@apollo/client';
import KYCItem from '../KYCItem/KYCItem';
import SectionWrapper from '../../../../../utils/Section/SectionWrapper/SectionWrapper';
import {GET_NEXT_OF_KIN} from 'api/queries/customers';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';

const NextOfKinInfo = ({customerId}) => {
    const getNextOfKinQuery = useQuery(GET_NEXT_OF_KIN, {
        variables: {customerId},
    });

    const {next_of_kin} = getNextOfKinQuery?.data || {};

    const data = next_of_kin?.length ? next_of_kin[0] : {};

    return (
        <SectionWrapper>
            <ContentWrapper
                errorTitle="Error fetching customer next of kin"
                currentQuery={getNextOfKinQuery}
            >
                <div className="d-flex flex-wrap">
                    <KYCItem cls="wrapper__width_31_perc" item={data?.name || 'Name'} />
                    <KYCItem cls="wrapper__width_31_perc" item={data?.email || 'Name'} />
                    <KYCItem cls="wrapper__width_31_perc" item={data?.phone || 'Phone Number'} />
                    <KYCItem
                        cls="wrapper__width_31_perc"
                        item={data?.relationship || 'Relationship'}
                    />
                    <KYCItem cls="wrapper__width_31_perc" item={data?.address || 'Address'} />
                </div>
            </ContentWrapper>
        </SectionWrapper>
    );
};

export default NextOfKinInfo;
