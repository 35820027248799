import {useState} from 'react';
import DeactivateProfileModal from 'components/Dashboard/common/modals/DeactivateProfileModal/DeactivateProfileModal';

const DeactivateProfileButton = ({data}) => {
    const [showModal, setShowModal] = useState(false);
    const toggle = () => setShowModal(prev => !prev);

    const active = !data?.blocked;

    return (
        <>
            <div className="wrapper__width_31_perc ms-3">
                <button
                    onClick={toggle}
                    type="button"
                    className="btn btn--lg btn--danger--bordered text--large-xs btn--form py-4"
                >
                    {active ? 'Deactivate Profile' : 'Activate Profile'}
                </button>
            </div>

            {showModal && <DeactivateProfileModal id={data?.id} active={active} toggle={toggle} />}
        </>
    );
};

export default DeactivateProfileButton;
