// Headings

export const benefitsHeading = [
    'Company',
    'Dividend Declared',
    'Bonus',
    'Div. Field',
    'Interim/Final',
    'Declaration Date',
    'Qualification Date',
    'Closure Date',
    ' ',
];

export const reportsHeading = [' ', 'Report ID', 'Title', 'Date Posted', 'File Attached'];

// Form Defaults

export const reportsFormDefaults = {
    title: '',
    file: null,
};

export const benefitsFormDefaults = {
    bonus: '',
    closure_date: '',
    stock: null,
    declaration_date: '',
    dividend_declared: '',
    dividend_yield: '',
    final: '',
    qualification_date: '',
};

// Options

export const tabList = ['corporate_benefits', 'portfolio_reports'];

export const userOptions = [
    {label: null, value: null},
    {label: 'User Admin', value: 'User Admin'},
    {label: 'James Ibeh', value: 'James Ibeh'},
    {label: 'Zakiraya Eze', value: 'Zakiraya Eze'},
    {label: 'Sarah Maduechi', value: 'Sarah Maduechi'},
    {label: 'Bashorun Sarah', value: 'Bashorun Sarah'},
    {label: 'Abayomi Olorundotun', value: 'Abayomi Olorundotun'},
    {label: 'Rvr. Bamisaye Emoruwa', value: 'Rvr. Bamisaye Emoruwa'},
];

export const categoryOptions = [
    {label: null, value: null},
    {label: 'Stock Recommendation', value: 'Stock Recommendation'},
    {label: 'Earnings Update', value: 'Earnings Update'},
    {
        label: 'Corporate Benefit Triggers',
        value: 'Corporate Benefit Triggers',
    },
    {label: 'Market Reports', value: 'Market Reports'},
];

export const companyOptions = [
    {label: null, value: null},
    {label: 'Arla', value: 'Arla'},
    {label: 'Dano', value: 'Dano'},
    {label: 'Milo', value: 'Milo'},
    {label: 'Airtel', value: 'Airtel'},
    {label: 'Indomie', value: 'Indomie'},
    {label: "Domino's", value: "Domino's"},
    {label: 'First Bank', value: 'First Bank'},
    {label: 'Business Day', value: 'Business Day'},
    {label: 'Spotify Nigeria', value: 'Spotify Nigeria'},
];

export const benefitsFilterParams = [
    {
        name: 'company',
        label: 'Company',
        query: '_iregex',
    },
    {
        name: 'final',
        label: 'Final',
        placeholder: 'Type Here',
        options: [
            {
                label: 'True',
                value: true,
            },
            {
                label: 'False',
                value: false,
            },
        ],
    },
    {
        name: 'bonus',
        label: 'Bonus',
    },
];

export const reportsFilterParams = [
    {
        name: 'title',
        label: 'Title',
        query: '_iregex',
    },
    {
        name: 'from',
        label: 'Start Date',
        query: '_gte',
        isDate: true,
    },
    {
        name: 'to',
        label: 'End Date',
        query: '_lte',
        isDate: true,
    },
];

export const selectOptions = [
    'Stock Recommendation',
    'Earnings Update',
    'Corporate Benefit Triggers',
    'Market Reports',
];

export const interimOptions = [
    {label: 'True', value: true},
    {label: 'False', value: false},
];

export const corporateBenefitsReportHeadings = {
    id: 'Research ID',
    'stock.label': 'Company',
    dividend_declared: 'Dividend Cleared',
    bonus: 'Bonus',
    dividend_yield: 'Dividend Yield',
    final: 'Interim',
    declaration_date: 'Declaration Date',
    qualification_date: 'Qualification Date',
    closure_date: 'Closure Date',
};
