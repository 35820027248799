import React from 'react';
import Accordion from 'utils/Accordion/Accordion';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';
import RecentWalletTransactions from '../Transactions/RecentWalletTransactions';
import UserWalletInfo from './UserWalletInfo/UserWalletInfo';

export const bankingAccordionEnums = data => [
    {
        title: 'Wallet Information',
        body: <UserWalletInfo hideMore wallets={data?.settlement_details} />,
    },
    {
        title: 'Recent Wallet Transactions',
        body: <RecentWalletTransactions hideTitle user_id={data?.id} />,
    },
];

const Banking = ({data = {}}) => (
    <SectionContainer cls="mx-4 mt-4">
        <Accordion
            cls="customer-accordion"
            id="customer-accordion"
            itemsList={bankingAccordionEnums(data)}
        />
    </SectionContainer>
);

export default Banking;
