import Login from './Login/Login';

const defaultPath = '/auth';

const routes = [
    {
        path: `${defaultPath}/login`,
        component: Login,
        exact: true,
    },
];

export default routes;
