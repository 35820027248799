import React from 'react';
import SectionWrapper from '../Section/SectionWrapper/SectionWrapper';
import SectionContainer from '../Section/SectionContainer/SectionContainer';

import {toLocaleString} from '../helpers';

const Stat = ({cls = 'wrapper__stat flex__center', data}) => {
    const {title, icon, value} = data || {};

    return (
        <SectionContainer cls={cls}>
            <SectionWrapper>
                <div className="flex__between">
                    <div className="col-9">
                        <div className="text--gray">{title}</div>
                        <div className="fw-bold text--large">{toLocaleString(value)}</div>
                    </div>
                    <div className="bg__primary col-3 wrapper__stats-icon flex__center">{icon}</div>
                </div>
            </SectionWrapper>
        </SectionContainer>
    );
};

export default Stat;
