import React, {useState} from 'react';

import {ReactComponent as CircledArrowDownIcon} from 'assets/images/icons/circled-arrow-down.svg';

import Table from 'utils/Table/Table';
import StatusPill from 'utils/Pills/StatusPill/StatusPill.jsx';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SearchForm from 'utils/CustomInputBox/SearchForm/SearchForm';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';

import {FUND, TRADE, WITHDRAWAL, WALLET} from '../../../constants';
import {displayOrderType, toLocaleString} from '../../../../utils/helpers';
import {tradeData, walletFundData, walletWithdrawalData} from '../helpers/data';
import {
    transactionsTabList,
    transactionsTradeFilterParams,
    transactionsWalletFilterParams,
    tradeHeading,
    tradeOptions,
    walletHeading,
    walletOptions,
} from '../helpers/enums';

const Transactions = ({tableCls, title, titleChildren, showDownload, showFilter, showRecent}) => {
    const [currentTab, setCurrentTab] = useState(TRADE);
    const [selected, setSelected] = useState({
        trade: 'All',
        wallet: FUND,
    });

    const setCurrentTabToTrade = () => setCurrentTab(TRADE);
    const setCurrentTabToWallet = () => setCurrentTab(WALLET);

    const actionList = [setCurrentTabToTrade, setCurrentTabToWallet];
    const selectName = currentTab === TRADE ? 'trade' : 'wallet';
    const filterParams =
        currentTab === TRADE ? transactionsTradeFilterParams : transactionsWalletFilterParams;
    const selectOptions = currentTab === TRADE ? tradeOptions : walletOptions;
    const selectValue = currentTab === TRADE ? selected?.trade : selected?.wallet;
    const parsedTradeData =
        selected?.trade === '' || selected?.trade === 'All'
            ? tradeData
            : tradeData?.filter(trade => trade?.marketStatus === selected.trade);

    const filteredFundData = showRecent ? walletFundData.slice(0, 6) : walletFundData;
    const filteredTradeData = showRecent ? parsedTradeData.slice(0, 6) : parsedTradeData;
    const filteredWithdrawalData = showRecent
        ? walletWithdrawalData.slice(0, 6)
        : walletWithdrawalData;

    const Cell = ({cls, data}) => <div className={cls}>{data}</div>;

    const getTradeData = () =>
        filteredTradeData
            ?.sort((a, b) => (a.term < b.term ? 1 : b.term < a.term ? -1 : 0))
            ?.map(trade => {
                return {
                    orderNo: trade?.orderNo,
                    date: trade?.date,
                    orderType: displayOrderType(trade?.orderType),
                    quantity: trade?.quantity,
                    symbol: trade?.symbol,
                    priceType: trade?.priceType,
                    term: `Good for ${trade?.term} Days`,
                    orderStatus: displayOrderType(trade?.orderStatus),
                    marketStatus: (
                        <StatusPill
                            fail="Cancelled"
                            status={trade?.marketStatus}
                            success="Success"
                        />
                    ),
                };
            });

    const getWalletFundData = () =>
        filteredFundData?.map(wallet => ({
            icon: (
                <CircledArrowDownIcon
                    className="primary transition-linear"
                    height="25px"
                    width="25px"
                />
            ),
            name: wallet?.name,
            id: wallet?.id,
            amountFunded: (
                <Cell cls="text--primary" data={`₦${toLocaleString(wallet?.amountFunded)}`} />
            ),
            accountFunded: wallet?.accountFunded,
            transactionType: wallet?.transactionType,
            date: wallet?.date,
        }));

    const getWalletWithdrawalData = () =>
        filteredWithdrawalData?.map(wallet => ({
            icon: <CircledArrowDownIcon className="danger rot-180" height="25px" width="25px" />,
            name: wallet?.name,
            id: wallet?.id,
            amountFunded: (
                <Cell cls="text--danger" data={`₦${toLocaleString(wallet?.amountFunded)}`} />
            ),
            accountFunded: wallet?.accountFunded,
            transactionType: wallet?.transactionType,
            date: wallet?.date,
        }));

    const getWallet = () => {
        switch (selected?.wallet) {
            case FUND:
            default:
                return getWalletFundData().sort((a, b) =>
                    a.date < b.date ? 1 : b.date < a.date ? -1 : 0
                );

            case WITHDRAWAL:
                return getWalletWithdrawalData().sort((a, b) =>
                    a.date < b.date ? 1 : b.date < a.date ? -1 : 0
                );

            case '':
                return [...getWalletFundData(), ...getWalletWithdrawalData()]
                    .sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0))
                    .slice(0, 6);
        }
    };

    const HeaderChildren = () => (
        <div className="me-4">
            <SearchForm name="transactions-search" placeholder="Search Transactions" smallSearch />
        </div>
    );

    return (
        <SectionContainer>
            <SectionWrapper
                actionList={actionList}
                allowSelectInMultiTab
                filterParams={showFilter && filterParams}
                headerChildren={<HeaderChildren />}
                headerChildrenCls="col-md-4"
                largeSelectMobile
                selectCustomHandlerFn={setSelected}
                selectCustomValue={selected}
                selectCustomDispValue={selectValue}
                selectInputOptions={selectOptions}
                selectCustomName={selectName}
                showDownload={showDownload}
                tabList={transactionsTabList}
                title={title}
                titleChildren={titleChildren}
                navId="transactionsTab"
            >
                <div className="tab-content" id="transactionsTab">
                    <div
                        className="tab-pane fade show active"
                        id="trade"
                        role="tabpanel"
                        aria-labelledby="trade-tab"
                    >
                        <Table
                            cls={tableCls}
                            data={getTradeData()}
                            headings={tradeHeading}
                            rowClick={() => {}}
                        />
                    </div>
                    <div
                        className="tab-pane fade"
                        id="wallet"
                        role="tabpanel"
                        aria-labelledby="wallet-tab"
                    >
                        <Table
                            cls={tableCls}
                            data={getWallet()}
                            headings={walletHeading}
                            rowClick={() => {}}
                        />
                    </div>
                </div>
            </SectionWrapper>
        </SectionContainer>
    );
};

export default Transactions;
