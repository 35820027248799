import React from 'react';
import {Accordion} from 'react-bootstrap';

import AccordionTableCell from './AccordionTableCell';

const AccordionTableRow = ({accHeader, accBody, headings, id}) => {
    const rowData = accHeader || headings;
    const tag = headings ? 'th' : 'td';
    const textWidth = headings
        ? `${100 / (rowData.length - 1)}%`
        : `${100 / (Object.keys(rowData).length - 1)}%`;

    return (
        <Accordion.Item
            className="accordion-header border__bottom__gray__light accordion-table-header"
            eventKey={`${id}`}
        >
            <div className="d-flex accordion-table-row">
                {Object.values(rowData).map((data, index) => {
                    const cellWidth = index === 0 ? '7%' : textWidth;

                    return (
                        <AccordionTableCell
                            style={{width: cellWidth}}
                            tag={tag}
                            data={data}
                            key={index}
                        />
                    );
                })}
            </div>

            {accBody && <Accordion.Body>{accBody}</Accordion.Body>}
        </Accordion.Item>
    );
};

export default AccordionTableRow;
