import React from 'react';

import SectionHeader from '../extras/SectionHeader';

const SectionContainer = ({
    actionList,
    allowSelectInMultiTab,
    children,
    cls,
    filterId,
    filterParams,
    headerChildren,
    headerChildrenCls,
    headerChildrenNoWrap,
    iconAreaWidthMax,
    largeSelectMobile,
    navId,
    navWideMobile,
    placeholder,
    rawOptions,
    selectCustomDispValue,
    selectCustomHandlerFn,
    selectCustomValue,
    selectCustomName,
    selectInputOptions,
    selectInputWidth,
    showDownload,
    tabList,
    title,
    titleChildren,
}) => (
    <div className={`d-flex px-4 py-4 ${cls}`}>
        {(title || tabList) && (
            <div className="mb-3">
                <SectionHeader
                    actionList={actionList}
                    allowSelectInMultiTab={allowSelectInMultiTab}
                    filterId={filterId}
                    filterParams={filterParams}
                    headerChildren={headerChildren}
                    headerChildrenCls={headerChildrenCls}
                    headerChildrenNoWrap={headerChildrenNoWrap}
                    iconAreaWidthMax={iconAreaWidthMax}
                    largeSelectMobile={largeSelectMobile}
                    navId={navId}
                    navWideMobile={navWideMobile}
                    placeholder={placeholder}
                    selectCustomDispValue={selectCustomDispValue}
                    selectCustomHandlerFn={selectCustomHandlerFn}
                    selectCustomValue={selectCustomValue}
                    selectCustomName={selectCustomName}
                    selectInputOptions={selectInputOptions}
                    selectInputWidth={selectInputWidth}
                    showDownload={showDownload}
                    rawOptions={rawOptions}
                    tabList={tabList}
                    title={title}
                    titleChildren={titleChildren}
                />
            </div>
        )}
        {children}
    </div>
);

export default SectionContainer;
