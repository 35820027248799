import {Route, Redirect, withRouter} from 'react-router-dom';
import {fetchUserData} from 'storage/sessionStorage';

const PrivateRoutes = ({component: Component, ...rest}) => {
    const {token} = fetchUserData();

    return (
        <Route
            {...rest}
            render={props => (token ? <Component {...props} /> : <Redirect to="/auth/login" />)}
        />
    );
};

export default withRouter(PrivateRoutes);
