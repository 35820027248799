import Documents from './Documents/Documents';
import Investment from './Investment/Investment';
import Accordion from 'utils/Accordion/Accordion';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';

const Profile = ({data}) => {
    const profileAccordionEnums = [
        {
            title: 'Personal Information',
            body: <PersonalInfo data={data} />,
        },
        {
            title: 'Documents',
            body: <Documents data={data} />,
        },
        {
            title: 'Investments',
            body: <Investment user={data} />,
        },
    ];
    return (
        <SectionContainer>
            <SectionWrapper>
                <Accordion
                    cls="profile-accordion"
                    id="profile-accordion"
                    data={data}
                    itemsList={profileAccordionEnums}
                />
            </SectionWrapper>
        </SectionContainer>
    );
};

export default Profile;
