import React, {useState} from 'react';
import {ReactComponent as AddNewIcon} from 'assets/images/icons/add-new.svg';
import AddNewReportModal from '../../modals/AddNewReportModal/AddNewReportModal';

const AddReportButton = () => {
    const [openModal, setOpenModal] = useState(false);

    const toggleModal = () => setOpenModal(prev => !prev);

    return (
        <>
            <AddNewIcon className="pointer me-4" height="25px" onClick={toggleModal} width="25px" />

            {openModal && <AddNewReportModal toggle={toggleModal} />}
        </>
    );
};

export default AddReportButton;
