import Table from 'utils/Table/Table';
import EmptyData from 'utils/Table/EmptyData';
import CBActions from '../../cells/CBActions/CBActions';
import TableFooter from 'utils/Table/TableFooter/TableFooter';
import AddBenefitButton from '../../buttons/AddNewButton/AddBenefitButton';
import {toLocaleString} from 'utils/helpers';
import {benefitsHeading} from '../../helpers/enums';
import AccessControl from 'components/App/AccessControl/AccessControl';
import {allPermissions} from 'components/App/AccessControl/permissions';

const CorporateBenefitsTable = ({
    data,
    limit,
    currentPage,
    handleLimitChange,
    handlePagination,
}) => {
    const {
        corporate_benefit,
        corporate_benefit_aggregate: {
            aggregate: {count: total},
        },
    } = data;

    const len = total === 0 || !Boolean(corporate_benefit.length);

    const totalPages = Math.ceil(total / limit) || 1;

    const getBenefits = () =>
        corporate_benefit?.map(data => ({
            company: data?.stock?.label,
            dividend_declared: `${data?.stock?.currency} ${toLocaleString(
                data?.dividend_declared
            )}`,
            bonus: data?.bonus,
            divYield: `${data?.dividend_yield}%`,
            interim: String(data?.final),
            declarationDate: data?.declaration_date,
            qualificationDate: data?.qualification_date,
            AGMDate: <CBActions date={data?.closure_date} data={data} />,
        }));
    return (
        <div
            className="tab-pane fade show active"
            id="corporate_benefits"
            role="tabpanel"
            aria-labelledby="corporate_benefits-tab"
        >
            {!len ? (
                <>
                    <Table
                        cls="mt-3"
                        data={getBenefits()}
                        headings={benefitsHeading}
                        rowClick={() => {}}
                    />
                    <TableFooter
                        currentPage={currentPage || 0}
                        totalPages={totalPages}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                    />
                </>
            ) : (
                <EmptyData
                    title="No Corporate Benefits Research Yet"
                    subtitle="All Corporate Benefits Research will be displayed here"
                    btn={
                        <AccessControl
                            allowedPermissions={[allPermissions.CAN_ADD_CORPORATE_BENEFITS]}
                        >
                            <AddBenefitButton />
                        </AccessControl>
                    }
                />
            )}
        </div>
    );
};

export default CorporateBenefitsTable;
