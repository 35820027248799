import {gql} from '@apollo/client';

export const DELETE_MARGIN_REQUESTS = gql`
    mutation deleteMarginRequest($id: uuid!) {
        delete_margin_request(where: {id: {_eq: $id}}) {
            returning {
                id
            }
        }
    }
`;

export const UPDATE_MARGIN_REQUESTS = gql`
    mutation updateMarginRequest($id: Int!, $status: margin_request_status_enum!) {
        update_margin_request(where: {id: {_eq: $id}}, _set: {status: $status}) {
            returning {
                id
            }
        }
    }
`;
