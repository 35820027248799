import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import Modal from 'utils/Modal/Modal';
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';
import FileUpload from 'utils/FileUpload/FileUpload';
import CustomInputBox from 'utils/CustomInputBox/CustomInputBox';
import {formValidator} from 'utils/FormValidator/FormValidator';
import Toast from 'utils/Alert/Toast/Toast';
import API from 'api/rest';
import {ADD_PORTFOLIO_REPORT, EDIT_PORTFOLIO_REPORT} from 'api/mutations/PortfolioReport';
import FilePreviewButton from '../../buttons/FilePreviewButton/FilePreviewButton';
import {SECONDARY, SUCCESS} from '../../../../constants';
import {reportsFormDefaults} from '../../helpers/enums';
import {getError} from 'utils/helpers';
import {useEffect} from 'react';

const AddNewReportModal = ({toggle, report = {}}) => {
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(reportsFormDefaults);
    const [uploadPercent, setUploadPercent] = useState(0);

    useEffect(() => {
        if (report.title) {
            setRequest({title: report?.title});
        }
    }, [report.title]);

    const setError = (name, value = '') => {
        setFormErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSelect = (name, value) => {
        setRequest(prev => ({
            ...prev,
            [name]: value,
        }));
        setError(name);
    };

    const handleChange = ({target: {name, value}}) => handleSelect(name, value);

    const handleUpload = files => {
        const file = files[0];
        if (file && file.type === 'application/pdf') {
            handleSelect('file', file);
            setUploadPercent(0);
        } else setError('file', 'Please upload a valid document (pdf only)');
    };

    const handleToggle = () => {
        setRequest(reportsFormDefaults);
        setFormErrors({});
        toggle();
    };

    const [addReport, reportQuery] = useMutation(ADD_PORTFOLIO_REPORT, {
        refetchQueries: ['PortfolioAggregate'],
        onCompleted: () => {
            setLoading(false);
            Toast({
                msg: 'Portfolio report created successfully',
                position: 'top-right',
                variant: SUCCESS,
            });
            toggle();
        },
        onError: error => handleApiError(getError(error)),
    });

    const [updateReport, updateReportQuery] = useMutation(EDIT_PORTFOLIO_REPORT, {
        refetchQueries: ['PortfolioAggregate'],
        onCompleted: () => {
            setLoading(false);
            Toast({
                msg: 'Portfolio report updated successfully',
                position: 'top-right',
                variant: SUCCESS,
            });
            toggle();
        },
        onError: error => handleApiError(getError(error)),
    });

    const handleUploadApi = async () => {
        const formData = new FormData();
        formData.append('porfolio_report', request.file);

        setLoading(true);
        setUploadPercent(0);

        await API.post('/files/upload-portfolio-report', formData)
            .then(({data, status}) => {
                if ((status === 201 || status === 200) && data.status) {
                    setUploadPercent(100);
                    report.title
                        ? updateReport({
                              variables: {
                                  id: report?.id,
                                  file_url: data?.data?.url,
                                  title: request?.title,
                              },
                          })
                        : addReport({
                              variables: {
                                  object: {
                                      file_url: data?.data?.url,
                                      title: request?.title,
                                  },
                              },
                          });
                } else handleApiError('Error uploading portfolio report');
            })
            .catch(error => handleApiError(getError(error)));
    };

    const handleApiError = msg => {
        setLoading(false);
        Toast({msg, position: 'top-right'});
    };

    const handleSubmit = e => {
        e.preventDefault();
        const {id, file_url} = report;
        const {formErrors, isValid} = formValidator(request);
        console.log(formErrors);
        if (isValid) {
            if (report && !request.file)
                updateReport({variables: {id, file_url, title: request?.title}});
            else handleUploadApi();
        } else setFormErrors(formErrors);
    };

    return (
        <Modal show={true} size="lg" title="New Report" toggle={handleToggle} type={SECONDARY}>
            <form onSubmit={handleSubmit}>
                <di className="flex__between__end flex-wrap text--normal">
                    <CustomInputBox
                        cls="w-100"
                        error={formErrors?.title}
                        label="Report Title"
                        name="title"
                        onChange={handleChange}
                        placeholder="Type Here"
                        value={request?.title}
                    />
                </di>
                <div className="py-4">
                    <FileUpload
                        id="reportUpload"
                        error={formErrors?.file}
                        file={request?.file}
                        label="Upload Report File"
                        name="file"
                        percent={uploadPercent}
                        onChange={handleUpload}
                        validFileTypes={['application/pdf']}
                        required
                    />
                </div>

                {report?.file_url && !request.file && <FilePreviewButton file={report} />}

                <div className="flex__between flex-wrap">
                    <div
                        className="wrapper__width_47_perc wrapper__col-12_mobile mt-5"
                        onClick={handleSubmit}
                    >
                        <button className="btn btn--lg btn--primary text--large-sm py-5">
                            {loading || reportQuery.loading || updateReportQuery.loading ? (
                                <CustomSpinner />
                            ) : (
                                'Confirm'
                            )}
                        </button>
                    </div>
                    <div
                        className="wrapper__width_47_perc wrapper__col-12_mobile mt-5"
                        onClick={handleToggle}
                    >
                        <button className="btn btn--lg btn--danger--bordered text--large-sm py-5">
                            Decline
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default AddNewReportModal;
