import React, {useState} from 'react';
import {ALL} from 'components/constants';
import NxNavTabs from 'utils/NavTabs/NxNavTabs';
import {BUY, SELL, CASH} from 'components/constants';
import CustomSelect from 'utils/Select/CustomSelect/CustomSelect';
import {ReactComponent as FilterIcon} from 'assets/images/icons/filter.svg';
import CustomerOrdersStatementContent from './CustomerOrdersStatementContent';
import CustomerCashStatement from './CustomerCashStatement';

const tabs = ['Buy', 'Sell', 'Cash'];
const all = {label: ALL, value: 'All'};

const CustomerOrderStatement = ({customerId, accounts = [], cash_accounts = []}) => {
    const [account, setAccount] = useState(all);
    const [total, setTotal] = useState(0);
    const [isToday, setIsToday] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [currentTab, setCurrentTab] = useState(BUY);
    const toggleFilter = () => setShowFilter(prev => !prev);

    const components = [
        {
            component: CustomerOrdersStatementContent,
            id: BUY,
            type: 'BUY',
        },
        {
            component: CustomerOrdersStatementContent,
            id: SELL,
            type: 'SELL',
        },
        {
            component: CustomerCashStatement,
            id: CASH,
        },
    ];

    const handleSelect = selected => {
        setAccount(selected);
        // handleFetch(selected?.id);
    };

    return (
        <div className="col-12 px-4 py-4">
            <div className="flex__between">
                <div className="d-flex align-items-center">
                    <NxNavTabs tabList={tabs} rawTitle action={setCurrentTab} />
                    <h4 className="fw-bold ms-5 mb-0">
                        {isToday ? '(Today)' : '(Date Range)'} -- ({total})
                    </h4>
                </div>

                <div className="flex__between" style={{minWidth: '350px'}}>
                    <div className="flex__between" style={{minWidth: '300px'}}>
                        <CustomSelect
                            name="portfolio"
                            formGroupCls="w-100 zero-height"
                            onChange={value => handleSelect(value)}
                            options={[all, ...(currentTab === CASH ? cash_accounts : accounts)]}
                            placeholder="Select Portfolio"
                            value={account}
                            getOptionLabel={({label}) => label}
                        />
                    </div>
                    <FilterIcon
                        height="20px"
                        width="17px"
                        className="pointer me-4 my-auto"
                        onClick={toggleFilter}
                    />
                </div>
            </div>

            <div className="tab-content bg--gray--light">
                {components.map(({component: Component, type, id}, index) => (
                    <div
                        key={id}
                        className={`tab-pane fade show ${index === 0 ? 'active' : ''}`}
                        id={id}
                        role="tabpanel"
                    >
                        <Component
                            customerId={customerId}
                            type={type}
                            currentTab={currentTab}
                            account={account}
                            setIsToday={setIsToday}
                            showFilter={showFilter}
                            setTotal={setTotal}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomerOrderStatement;
