import React, {useState} from 'react';
import {ReactComponent as EditIcon} from 'assets/images/icons/edit.svg';
import AddNewReportModal from '../../modals/AddNewReportModal/AddNewReportModal';

const EditPortfolioReportButton = ({data}) => {
    const [openModal, setOpenModal] = useState(false);
    const toggleModal = () => setOpenModal(!openModal);

    return (
        <>
            <EditIcon className="me-5 pointer" onClick={toggleModal} />
            {openModal && <AddNewReportModal toggle={toggleModal} report={data} />}
        </>
    );
};

export default EditPortfolioReportButton;
