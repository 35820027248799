import {gql} from '@apollo/client';

export const DEACTIVATE_USER = gql`
    mutation DeleteAdminUser($id: Int!) {
        admin_deactivate_user(id: $id) {
            message
            status
        }
    }
`;

export const UPDATE_USER_PROFILE = gql`
    mutation UpdateUserProfile($userId: Int!, $user: user_set_input) {
        update_user_by_pk(pk_columns: {id: $userId}, _set: $user) {
            id
            first_name
            email
            last_name
            phone
            username
        }
    }
`;

export const UPDATE_INVESTMENT_PROFILE = gql`
    mutation UpdateInvestmentInformation($userId: Int, $profile: investment_profile_set_input) {
        update_investment_profile(where: {user: {id: {_eq: $userId}}}, _set: $profile) {
            affected_rows
        }
    }
`;
