import {Modal as BootstrapModal, ModalBody} from 'reactstrap';
import ModalHeader from './ModalHeader/ModalHeader';
import './Modal.scss';

const Modal = ({show, type, toggle, children, title, text, size = 'md', onEnter, onHide}) => (
    <BootstrapModal isOpen={show} size={size} onEnter={onEnter} onHide={onHide} toggle={toggle}>
        {type && <ModalHeader type={type} toggle={toggle} title={title} text={text} />}
        <ModalBody>{children}</ModalBody>
    </BootstrapModal>
);

export default Modal;
