import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {ReactComponent as ChevronDownIcon} from 'assets/images/icons/chevron-down.svg';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';
import {GET_ACCOUNT_DETAILS} from 'api/queries/wallet';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import {toCurrency} from 'utils/helpers';

const WalletMetadata = () => {
    let {user_id} = useParams();
    user_id = user_id ? parseInt(user_id) : '';

    const getAccountQuery = useQuery(GET_ACCOUNT_DETAILS, {
        variables: {user_id},
    });

    let {cash_balance, account} = getAccountQuery?.data || {};
    account = account ? account[0] : {};

    return (
        <>
            <SectionContainer cls="heading5">
                <Link to="/wallets">
                    <ChevronDownIcon
                        className="rot-90 primary pointer me-4 mb-1"
                        height="16px"
                        width="16px"
                    />
                </Link>
                <span>{account?.account_name || 'Wallet Name ...'}</span>
            </SectionContainer>

            <ContentWrapper errorTitle="Error fetching wallet" currentQuery={getAccountQuery}>
                <div className="d-flex flex-wrap col-12">
                    <SectionContainer cls="wrapper__wallet_stat">
                        <SectionWrapper>
                            <div className="ps-2 fw-bold">Wallet Balance</div>
                            <div
                                className={`fw-bold wrapper__pill px-4 py-2 mt-2 text--large primary__light`}
                            >
                                <div className="wrapper__overflow ">
                                    {account?.currency}
                                    {toCurrency(cash_balance?.balance)}
                                </div>
                            </div>
                        </SectionWrapper>
                    </SectionContainer>

                    <SectionContainer cls="col-md-8 col-12 fw-bold">
                        <SectionWrapper dashed>
                            <div className="pb-4 text--gray">Linked Bank Information</div>
                            <div className="pb-2">Account Name - {account?.account_name}</div>
                            <div className="pb-2">Account Number - {account?.account_number}</div>
                            <div>Bank Name - {account?.bank_name}</div>
                        </SectionWrapper>
                    </SectionContainer>
                </div>
            </ContentWrapper>
        </>
    );
};

export default WalletMetadata;
