import {gql} from '@apollo/client';

export const GET_ADMIN_USERS = gql`
    query getAdminUsers(
        $limit: Int
        $offset: Int
        $role: String_comparison_exp = {}
        $email: String_comparison_exp = {}
        $first_name: String_comparison_exp = {}
        $last_name: String_comparison_exp = {}
        $created_at: order_by
    ) {
        admin_user(
            limit: $limit
            offset: $offset
            where: {role: $role, email: $email, first_name: $first_name, last_name: $last_name}
            order_by: {created_at: $created_at}
        ) {
            id
            first_name
            created_at
            last_name
            email
            admin_role {
                comment
                value
            }
        }

        admin_user_aggregate {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ADMIN_ROLES = gql`
    query getAdminRoles {
        admin_role {
            comment
            value
        }
    }
`;
