import React, {useEffect, useState} from 'react';
import CustomInputBox from '../CustomInputBox';

import {ReactComponent as SearchIcon} from '../../../assets/images/icons/search.svg';

import useDebounce from 'hooks/useDebounce';

const SearchForm = ({
    cls,
    name = 'search',
    placeholder = 'Search',
    smallSearch,
    handleSearch = () => {},
    reset,
}) => {
    const [search, setSearch] = useState('');
    const [smallSearchState, setSmallSearchState] = useState(smallSearch);

    const showSmall = smallSearchState ? 'small__search__close' : '';
    const smallSearchCls = smallSearch ? 'small__search__open' : '';

    const debounced = useDebounce(search, 600);

    const handleChange = ({target: {value}}) => {
        setSearch(value);
    };

    useEffect(() => {
        handleSearch(debounced);
        // eslint-disable-next-line
    }, [debounced]);

    const toggleSmallSearch = () => setSmallSearchState(!smallSearchState);

    return (
        <div className={`search__form ${showSmall} ${smallSearchCls} ${cls}`}>
            <CustomInputBox
                name={name}
                type="search"
                value={search}
                placeholder={placeholder}
                hide={showSmall}
                icon={
                    <SearchIcon
                        className={`mx-0 ${smallSearch ? 'pointer my-3' : ''}`}
                        onClick={smallSearch ? toggleSmallSearch : null}
                    />
                }
                onChange={handleChange}
            />
        </div>
    );
};

export default SearchForm;
