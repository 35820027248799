import React, {useState, useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import Table from 'utils/Table/Table';
import EmptyData from 'utils/Table/EmptyData';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import {timeDateFormat} from 'utils/helpers';
import {ALL} from 'components/constants';
import {orderStatementHeadings} from '../../helpers/enums';
import {GET_ORDER_STATEMENT} from 'api/queries/statements';
import {toCurrency} from 'utils/helpers';
import DownloadContract from './DownloadContract';
import CustomerStatementFilters from './CustomerStatementFilters';
import {defaultDateFiltersNew} from 'components/constants';

const CustomerOrdersStatementContent = ({
    customerId,
    account,
    setIsToday,
    type,
    currentTab,
    showFilter,
    setTotal,
}) => {
    const [currentFilters, setCurrentFilters] = useState({
        ...defaultDateFiltersNew,
        type,
    });

    const portfolio_id = account?.value === ALL ? '' : account?.value || account?.id;

    const [getStatements, getStatementQuery] = useLazyQuery(GET_ORDER_STATEMENT, {
        fetchPolicy: 'no-cache',
        onCompleted: ({order_statement}) => setTotal(order_statement?.length || 0),
    });
    const {order_statement} = getStatementQuery?.data || {};

    useEffect(() => {
        currentTab.toUpperCase() === type.toUpperCase() &&
            getStatements({
                variables: {
                    ...currentFilters,
                    type: currentFilters.type,
                    portfolio_id,
                    admin_account_id: parseInt(customerId),
                },
            });
        // eslint-disable-next-line
    }, [customerId, account, currentTab]);

    const getTableData = () =>
        order_statement?.map(v => ({
            contractNo: v?.name,
            orderNo: v?.id,
            date: timeDateFormat(parseInt(v?.transactionDate)).date,
            price: toCurrency(v?.contractPrice),
            quality: v?.quantity,
            symbol: v?.symbol,
            amount: toCurrency(v?.contractAmount),
            download: <DownloadContract id={v?.id} />,
        }));

    return (
        <div className="col-12 px-4 py-4">
            {showFilter && (
                <CustomerStatementFilters
                    getStatements={getStatements}
                    setIsToday={setIsToday}
                    currentFilters={currentFilters}
                    customerId={customerId}
                    portfolio_id={portfolio_id}
                    setCurrentFilters={setCurrentFilters}
                />
            )}

            <ContentWrapper
                errorTitle="Error fetching customer's orders statements"
                currentQuery={getStatementQuery}
            >
                <>
                    {order_statement?.length ? (
                        <Table
                            headings={orderStatementHeadings}
                            data={getTableData()}
                            rowClick={() => {}}
                        />
                    ) : (
                        <EmptyData
                            title="No Order's Statement Found"
                            subtitle="All customer's orders statement will be displayed here"
                        />
                    )}
                </>
            </ContentWrapper>
        </div>
    );
};

export default CustomerOrdersStatementContent;
