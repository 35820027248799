import React from 'react';

const MarketVolChartTab = ({icon, progress, title, value}) => (
    <div>
        <div>
            {icon}
            <span className="ms-2 text--normal">{title}</span>
        </div>
        <div className="pt-3">
            <div className="text--primary text-start text--large-xs text--poppins-bold">
                {value}m
            </div>
            <div className="progress chart-progress mt-2">
                <div
                    className={`progress-bar bg__primary`}
                    role="progressbar"
                    style={{width: `${progress}%`}}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
        </div>
    </div>
);

export default MarketVolChartTab;
