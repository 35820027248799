import React from 'react';
import _ from 'lodash';
import {Scrollbars} from 'react-custom-scrollbars-2';

import AccordionTableHead from './AccordionTableHead';
import AccordionTableBody from './AccordionTableBody';

const AccordionTable = ({accBody, cls, data, defaultActiveKey = undefined, headings, indexRow}) => {
    const sortedData = _.sortBy(data, val => (indexRow ? val[indexRow] : ''));

    const groupedData = _.groupBy(sortedData, val => {
        return indexRow ? val[indexRow]?.charAt(0) : '';
    });

    const indexedData = _.map(groupedData, (_, key) => (
        <span onClick={() => scroll(key)}>{key}</span>
    ));

    let tableData = _.flatMapDeep(
        _.map(groupedData, (value, key) => value.map(val => ({value: val, id: key})))
    );

    tableData = indexRow ? tableData : data;

    const scroll = id => {
        const section = document.querySelector(`#${id}`);
        section.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    return (
        <div className={`table__wrapper ${cls}`}>
            <Scrollbars autoHide autoHeight autoHeightMax={550}>
                <table className="table">
                    <AccordionTableHead headings={headings} />
                    <AccordionTableBody
                        accBody={accBody}
                        data={tableData}
                        defaultActiveKey={defaultActiveKey}
                    />
                </table>
            </Scrollbars>

            {indexRow && (
                <div className="table__index">
                    <Scrollbars autoHide autoHeight autoHeightMax={550}>
                        {indexedData?.map(val => (
                            <p className="text-capitalize text--gray pointer">{val}</p>
                        ))}
                    </Scrollbars>
                </div>
            )}
        </div>
    );
};

export default AccordionTable;
