import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';
import WalletTable from 'components/Dashboard/Transactions/WalletContent/WalletTable/WalletTable';
import {PAYMENT_TRANSACTIONS} from 'api/queries/transactions';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import {walletOptions} from '../helpers/enums';

const RecentWalletTransactions = ({limit = 10, hideTitle, user_id}) => {
    const [query, setQuery] = useState(walletOptions[0]);

    const queryParams = {
        variables: {
            created_at: 'desc',
            limit,
            offset: 0,
            type: query === walletOptions[0] ? {} : {_eq: query.toLowerCase()},
            user_id: user_id ? {_eq: parseInt(user_id) || 0} : {},
        },
    };

    const getPaymentTransactionsQuery = useQuery(PAYMENT_TRANSACTIONS, queryParams);

    return (
        <SectionContainer>
            <SectionWrapper
                title={
                    hideTitle
                        ? ''
                        : `Recent Transactions (${
                              getPaymentTransactionsQuery?.data?.payment_transaction?.length || 0
                          })`
                }
                rawOptions
                selectInputOptions={walletOptions}
                selectCustomHandlerFn={setQuery}
                selectCustomValue={query}
            >
                <ContentWrapper
                    errorTitle="Error fetching wallet transactions"
                    currentQuery={getPaymentTransactionsQuery}
                >
                    <WalletTable
                        limit={limit}
                        data={getPaymentTransactionsQuery?.data || {}}
                        hideFooter
                    />
                </ContentWrapper>
            </SectionWrapper>
        </SectionContainer>
    );
};

export default RecentWalletTransactions;
