export const allPermissions = {
    CAN_VIEW_CUSTOMERS: '01',
    CAN_VIEW_REQUESTS: '02',
    CAN_APPROVE_REQUEST: '03',
    CAN_VIEW_TRANSACTIONS: '04',
    CAN_VIEW_ORDERS: '05',
    CAN_ADD_CORPORATE_BENEFITS: '06',
    CAN_VIEW_CORPORATE_BENEFITS: '07',
    CAN_MODIFY_CORPORATE_BENEFITS: '08',
    CAN_ADD_PORTFOLIO_REPORTS: '09',
    CAN_VIEW_PORTFOLIO_REPORTS: '10',
    CAN_MODIFY_PORTFOLIO_REPORTS: '11',
    CAN_ADD_ADMIN: '12',
    CAN_VIEW_ADMIN: '13',
    CAN_MODIFY_ADMIN: '14',
    CAN_VIEW_WALLETS: '15',
    CAN_VIEW_PORTFOLIO: '16',
    CAN_VIEW_DASHBOARD: '17',
    CAN_MODIFY_CUSTOMERS: '18',
};

export const allAccessLevel = Object.values(allPermissions);

export const customer_support = ['01', '02', '04', '05'];

export const operations = [...customer_support, '03', '06', '07', '08', '09', '10', '11', '18'];

export const super_admin = allAccessLevel;
