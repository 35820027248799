import React from 'react';

//Components
import Modal from '../../../../../utils/Modal/Modal';
import EditBenefitForm from './EditBenefitForm/EditBenefitForm';

//Utils
import {SECONDARY} from '../../../../constants';

const EditBenefitModal = ({show, data, toggle, headerType = SECONDARY}) => {
    return (
        <Modal show={show} size="xl" title={`Edit Benefit`} toggle={toggle} type={headerType}>
            <EditBenefitForm data={data} toggle={toggle} />
        </Modal>
    );
};

export default EditBenefitModal;
