import React, {useState} from 'react';
import {ReactComponent as TrashIcon} from 'assets/images/icons/trash.svg';
import DeletePortfolioReportModal from '../../modals/DeletePortfolioReportModal/DeletePortfolioReportModal';

const DeletePortfolioReportButton = ({id}) => {
    const [openModal, setOpenModal] = useState(false);
    const toggleModal = () => setOpenModal(prev => !prev);

    return (
        <>
            <TrashIcon className="pointer danger" onClick={toggleModal} />

            {openModal && <DeletePortfolioReportModal id={id} toggle={toggleModal} />}
        </>
    );
};

export default DeletePortfolioReportButton;
