import {Route, Switch, withRouter} from 'react-router';
import Routes from './routes';
import {Redirect} from 'react-router-dom';
import './Auth.scss';

const getRoutes = () => (
    <Switch>
        {Routes.map((route, i) => (
            <Route key={i} path={route.path} exact={route.exact} component={route.component} />
        ))}
        <Route exact path="*" component={() => <Redirect to="/auth/login" />} />
    </Switch>
);

const Authentication = () => (
    <div className="row auth">
        <div className="col-lg-6 d-lg-block d-none auth__left__view" />
        <div className="col-lg-6 col-12 h-100 flex__center">{getRoutes()}</div>
    </div>
);

export default withRouter(Authentication);
