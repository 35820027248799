const FailedState = ({errorMsg, onRetry, hideBtn}) => {
    return (
        <div className="d-flex justify-content-center p-5 mt-4">
            <div className="text-center">
                <p className="heading3 text-danger"> {errorMsg}</p>
                <br />
                {!hideBtn && (
                    <button
                        className="btn btn--sm btn--primary px-5 mx-auto fw-bold"
                        onClick={() => onRetry()}
                    >
                        Retry
                    </button>
                )}
            </div>
        </div>
    );
};

export default FailedState;
