import React, {useState} from 'react';

//SVG Icons
import {ReactComponent as AddNewIcon} from '../../../../../assets/images/icons/add-new.svg';

//Components
import AddBenefitModal from '../../modals/AddBenefitModal/AddBenefitModal';

const AddBenefitButton = () => {
    const [openModal, setOpenModal] = useState(false);

    const toggleModal = () => setOpenModal(prev => !prev);
    return (
        <>
            <AddNewIcon className="pointer me-4" height="25px" onClick={toggleModal} width="25px" />

            {openModal && <AddBenefitModal show={openModal} toggle={toggleModal} />}
        </>
    );
};

export default AddBenefitButton;
