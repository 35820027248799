import React from 'react';

import {ReactComponent as Logo} from '../../assets/images/icons/logo.svg';

const AuthHeader = ({title}) => (
    <div className="row flex__center flex-wrap px-5 mx-lg-4">
        <Logo height="53px" width="219px" className="mb-4" />
        <div className="px-5 col-12 heading1 mt-5 pt-lg-5 flex__center_mobile">{title}</div>
    </div>
);

export default AuthHeader;
