import {ReactComponent as Logo} from '../../../assets/images/icons/logo.svg';
import './MeriLoader.scss';

const MeriLoader = ({showLogo = true, cls = ''}) => {
    return (
        <div className={`loading ${cls}`}>
            <div className="text-center">
                {showLogo && <Logo className="" />}
                <h2> </h2>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
};

export default MeriLoader;
