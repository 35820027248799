import {useMutation} from '@apollo/client';

// Components
import Modal from 'utils/Modal/Modal';
import Toast from 'utils/Alert/Toast/Toast';
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';

// Utils
import {getError} from 'utils/helpers';
import {UPDATE_MARGIN_REQUESTS} from 'api/mutations/request';
import {ACCEPT, APPROVED, SECONDARY, SUCCESS} from 'components/constants';
import {DECLINED} from 'components/constants';
import {titleCase} from 'utils/helpers';

const ActionRequestModal = ({show, toggle, data, type}) => {
    const [updateMarginRequest, {loading}] = useMutation(UPDATE_MARGIN_REQUESTS, {
        refetchQueries: ['GetMarginRequest'],
        onCompleted: () => {
            Toast({
                msg: `Request ${
                    type === ACCEPT ? titleCase(APPROVED) : titleCase(DECLINED)
                } successfuly`,
                position: 'top-right',
                variant: SUCCESS,
            });
            toggle();
        },
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    return (
        <Modal
            type={SECONDARY}
            show={show}
            headerTitle={`${titleCase(type)} Request?`}
            toggle={toggle}
        >
            <div className="text-center text--large-sm text--gray px-5">
                Are you sure you want to {type?.toLowerCase()} this Request?
            </div>
            <div className="flex__between flex-wrap pt-5">
                <div className="col-sm-6 col-12 mt-3">
                    <div className="col-sm-11 col-12">
                        <button
                            disabled={loading}
                            onClick={toggle}
                            className="btn btn--lg btn--danger--bordered fw-bold"
                        >
                            No
                        </button>
                    </div>
                </div>
                <div className="col-sm-6 col-12 flex__end mt-3">
                    <div className="col-sm-11 col-12">
                        <button
                            disabled={loading}
                            onClick={() =>
                                updateMarginRequest({
                                    variables: {
                                        id: data?.id,
                                        status: type === ACCEPT ? APPROVED : DECLINED,
                                    },
                                })
                            }
                            className="btn btn--lg btn--primary  fw-bold"
                        >
                            {loading ? <CustomSpinner cls="btn--danger--bordered" /> : 'Yes'}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ActionRequestModal;
