import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Auth from '../Auth/Auth';
import Dashboard from '../Dashboard/Dashboard';
import PrivateRoutes from '../Routes/PrivateRoutes';
import PublicRoutes from '../Routes/PublicRoutes';

const App = () => (
    <>
        <BrowserRouter>
            <Switch>
                <PublicRoutes path="/auth" component={Auth} />
                <PrivateRoutes path="/" component={Dashboard} />
                <Route exact path="*" component={() => <h1>Page Not Found</h1>} />
            </Switch>
        </BrowserRouter>
        <ToastContainer />
    </>
);

export default App;
