import React, {useState} from 'react';
import {ReactComponent as DocumentIcon} from 'assets/images/icons/document.svg';
import FilePreviewModal from '../../modals/FilePreviewModal/FilePreviewModal';

const FilePreviewButton = ({request}) => {
    const [openModal, setOpenModal] = useState(false);
    const toggleModal = () => setOpenModal(prev => !prev);

    return (
        <>
            <div className="wrapper__small_pill wrapper__file_pill me-4" onClick={toggleModal}>
                <DocumentIcon className="me-3" />
                <div className="wrapper__overflow">{request?.agreement_url?.split('/')[1]}</div>
            </div>

            {openModal && (
                <FilePreviewModal
                    request={request}
                    headerTitle="Request Information"
                    show={openModal}
                    showFileOptions
                    toggle={toggleModal}
                />
            )}
        </>
    );
};

export default FilePreviewButton;
