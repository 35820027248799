import {useLazyQuery} from '@apollo/client';
import FileUpload from 'utils/FileUpload/FileUpload';
import {getError} from 'utils/helpers';
import Toast from 'utils/Alert/Toast/Toast';
import {GET_DOWNLOAD_URL} from 'api/queries/customers';
import EmptyData from 'utils/Table/EmptyData';

const Documents = ({data}) => {
    const handleFinalDownload = url => window.open(url);

    const [getUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
        onCompleted: data => handleFinalDownload(data?.admin_get_document?.url),
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    const handleDownload = link => {
        if (link) {
            getUrl({
                variables: {path: link},
            });
        }
    };

    const filesList = [
        {name: 'Passport Photo', value: data?.kyc_document?.passport},
        {
            name: "National Passport/Driver's License",
            value: data?.kyc_document?.identity,
        },
        {name: 'Scanned Utility Bill', value: data?.kyc_document?.utility},
        {name: 'Signature', value: data?.kyc_document?.signature},
    ];

    return (
        <>
            <div className="flex-wrap">
                {Object.keys(data?.kyc_document || {})?.length > 0 ? (
                    filesList.map((fileName, index) => (
                        <FileUpload
                            btnText="Download"
                            docIconCls="gray-border"
                            label={fileName?.name}
                            key={index}
                            onClick={() => handleDownload(fileName?.value)}
                            isDownload
                            noArrowIcon
                            percent={fileName?.value ? '100' : '0'}
                        />
                    ))
                ) : (
                    <EmptyData
                        title="No KYC Documents"
                        subtitle="All KYC Documents will be displayed here"
                    />
                )}
            </div>
        </>
    );
};

export default Documents;
