import React from 'react';
import {ReactComponent as ArrowLongUpIcon} from 'assets/images/icons/arrow-long-up.svg';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';
import {toLocaleString} from 'utils/helpers';

const Stat = ({stat, showCTA}) => (
    <SectionContainer cls={showCTA ? 'col-md-4 col-12' : 'wrapper__wallet_stat'}>
        <SectionWrapper>
            <div className="ps-2 fw-bold">{stat?.title}</div>
            <div className={`fw-bold wrapper__pill px-4 py-2 mt-2 text--large ${stat.pillCls}`}>
                <div className="wrapper__overflow ">₦{toLocaleString(stat?.total)}</div>
            </div>
            {!showCTA && (
                <div className={`mt-2 ${stat.textCls}`}>
                    <ArrowLongUpIcon className={`ms-2 me-3 ${stat.arrowCls}`} height="16px" />₦
                    {toLocaleString(stat.today)} Today
                </div>
            )}
            {showCTA && (
                <div className="flex__between">
                    <button className="btn text--normal text--primary py-2">Fund Wallet</button>
                    <button className="btn text--normal text--danger py-2">Debit Wallet</button>
                </div>
            )}
        </SectionWrapper>
    </SectionContainer>
);

export default Stat;
