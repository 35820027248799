import React, {useState} from 'react';
import API from 'api/rest';
import {downloadPDF, getError} from 'utils/helpers';
import Toast from 'utils/Alert/Toast/Toast';
import {downloadContract} from 'api/restRoutes';
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';
import {ReactComponent as DownloadIcon} from 'assets/images/icons/download.svg';

const DownloadContract = ({portfolio_id = '', id}) => {
    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
        try {
            setLoading(true);

            const response = await API.get(downloadContract, {
                responseType: 'blob',
                params: {portfolio_id, id},
            });
            const {status, data} = response || {};

            if (status === 200) {
                setLoading(false);
                downloadPDF(data);
            }
        } catch (error) {
            setLoading(false);
            Toast({msg: getError(error), position: 'top-right'});
        }
    };
    return (
        <>
            {loading ? (
                <CustomSpinner cls="text-succuss" />
            ) : (
                <DownloadIcon onClick={handleDownload} />
            )}
        </>
    );
};

export default DownloadContract;
