import React from 'react';

import AccordionTableRow from './AccordionTableRow';

const AccordionTableHead = ({headings}) => (
    <thead className="table__head">
        <AccordionTableRow headings={headings} id="Header" />
    </thead>
);

export default AccordionTableHead;
