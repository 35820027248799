import React, {useState} from 'react';

import {ReactComponent as EditIcon} from '../../../../../assets/images/icons/edit.svg';

import EditBenefitModal from '../../modals/EditBenefitModal/EditBenefitModal';

const EditCorporateBenefitButton = ({data}) => {
    const [openModal, setOpenModal] = useState(false);
    const toggleModal = () => setOpenModal(!openModal);
    return (
        <>
            <EditIcon className="me-5 pointer" onClick={toggleModal} />
            {openModal && <EditBenefitModal data={data} show={openModal} toggle={toggleModal} />}
        </>
    );
};

export default EditCorporateBenefitButton;
