import {Route} from 'react-router-dom';
import AccessControl from 'components/App/AccessControl/AccessControl';

const Subroutes = ({routes}) =>
    routes?.map((route, i) => {
        if (route.dropDowns && route.dropDowns.length > 0) {
            return route.dropDowns.map(dropdown => (
                <AccessControl
                    isPage
                    key={dropdown?.path}
                    allowedPermissions={dropdown?.accessLevel}
                    page={dropdown}
                >
                    <Route
                        path={dropdown.path}
                        exact={dropdown.exact}
                        component={props => <dropdown.component {...props} />}
                    />
                </AccessControl>
            ));
        }

        return (
            <AccessControl isPage key={route?.path} allowedPermissions={route?.accessLevel}>
                <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    component={props => <route.component {...props} />}
                />
            </AccessControl>
        );
    });

export default Subroutes;
