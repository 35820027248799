import React, {useEffect, useState} from 'react';
import CustomDatePicker from 'utils/CustomDatePicker/CustomDatePicker';
import CustomInputBox from '../CustomInputBox/CustomInputBox';
import CustomSelect from '../Select/CustomSelect/CustomSelect';
import {checkSameDay} from 'utils/helpers';
import {defaultFilterValues} from 'utils/helpers';

const Filter = ({
    id,
    params,
    filters,
    handleFilters,
    clearFilters,
    defaultFilters = {},
    setIsToday,
}) => {
    const [query, setQuery] = useState(defaultFilterValues(params, defaultFilters));
    const [isApplied, setIsApplied] = useState(false);

    useEffect(() => {
        const isEmpty = !Object.values(filters).some(val => val);
        if (!isEmpty) {
            setQuery({...filters});
            setIsApplied(true);
        }
        // eslint-disable-next-line
    }, []);

    const clearFilter = () => {
        setQuery(defaultFilterValues(params, defaultFilters));
        clearFilters();
        setIsApplied(false);
    };

    const handleChange = ({target: {name, value}}) => handleSelect(name, value);

    const handleSelect = (name, value) => {
        setQuery(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDate = (name, value) => {
        handleSelect(name, value);
        setIsToday && checkSameDay(new Date(), value) ? setIsToday(true) : setIsToday(false);
    };

    const handleSubmit = e => {
        e.preventDefault();
        handleFilters({data: {...query}});
        setIsApplied(true);
    };

    return (
        <form
            className="col-12 d-flex flex__between_tab flex-wrap pt-3 transition--ease"
            id={id}
            onSubmit={handleSubmit}
        >
            {params.map(param => {
                const {
                    name,
                    label,
                    required,
                    disabled,
                    options,
                    placeholder,
                    isDate,
                    hide,
                    minDate,
                    maxDate,
                } = param;

                if (hide) {
                    return <></>;
                }

                if (options)
                    return (
                        <CustomSelect
                            key={name}
                            disabled={disabled}
                            formGroupCls="me-4 col-12 col-sm-5 col-lg-2"
                            label={label}
                            name={name}
                            onChange={value => handleSelect(name, value)}
                            options={options}
                            placeholder={placeholder ? placeholder : 'Type Here'}
                            required={required}
                            value={query[name]}
                        />
                    );
                if (isDate)
                    return (
                        <CustomDatePicker
                            cls="me-4 col-12 col-sm-5 col-lg-2"
                            label={label}
                            name={name}
                            onChange={value => handleDate(name, value)}
                            placeholder={placeholder ? placeholder : 'Type Here'}
                            value={query[name]}
                            minDate={minDate}
                            maxDate={maxDate || new Date()}
                            key={name}
                        />
                    );
                return (
                    <CustomInputBox
                        key={name}
                        cls="me-lg-4 col-12 col-sm-5 col-lg-2"
                        disabled={disabled}
                        label={label}
                        name={name}
                        onChange={handleChange}
                        placeholder={placeholder}
                        required={required}
                        value={query[name]}
                    />
                );
            })}
            <div className="col-6 col-sm-5 col-lg-2 me-4 flex__start__vertical__center">
                <button className="btn btn--lg btn--form btn--primary">Search</button>
            </div>
            {isApplied && (
                <div className="col flex__start__vertical__center">
                    <button
                        onClick={clearFilter}
                        className="btn btn--sm btn--form btn--danger px-4"
                    >
                        Clear
                    </button>
                </div>
            )}
        </form>
    );
};

export default Filter;
