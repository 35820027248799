import {ReactComponent as Toggler} from 'assets/images/icons/toggler.svg';

const Header = ({headerName}) => (
    <div className="header">
        <div className="d-flex align-items-center">
            <Toggler id="sidebar-toggler" className="pointer d-lg-none d-inline-block" />
            <h4 className="ms-4 fw-bold mb-0">{headerName}</h4>
        </div>
    </div>
);

export default Header;
