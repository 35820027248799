import $ from 'jquery';
import {Link, matchPath} from 'react-router-dom';
import {ReactComponent as Logo} from '../../../../assets/images/icons/logo.svg';
import {ReactComponent as SmallLogo} from '../../../../assets/images/icons/meri-logo.svg';
import {ReactComponent as Logout} from '../../../../assets/images/icons/sidebar/log-out.svg';
import AccessControl from 'components/App/AccessControl/AccessControl';
import SidebarDropdown from './SidebarDropdown/SidebarDropdown';
import Routes from '../../routes';
import './Sidebar.scss';

const Sidebar = ({location, history, handleLogout}) => {
    const activeRoute = (path, dropDowns) => {
        const {pathname} = location;
        const matchFunction = url =>
            matchPath(pathname, {
                path: url,
                exact: false,
                strict: false,
            });
        let match = matchFunction(path);
        let isActive = dropDowns
            ? dropDowns.find(x => x.path === pathname || matchFunction(x.path))
            : false;
        return path === pathname || isActive || match ? true : false;
    };

    const hideSideBar = () => {
        $('.sidebar').removeClass('active');
        $('.small-sidebar').removeClass('active');
    };

    const getIconSidebar = () => {
        return (
            <>
                <div className="sidebar__header" onClick={() => history.push('/')}>
                    <SmallLogo width={40} height={40} />
                </div>
                <ul className="list-unstyled" id="sidebar__nav">
                    {Routes.map(
                        (route, i) =>
                            !route.hide && (
                                <AccessControl
                                    key={route?.path}
                                    allowedPermissions={route?.accessLevel}
                                >
                                    <li className="sidebar__nav__item" key={route?.path}>
                                        <Link
                                            to={route.path}
                                            className="sidebar__nav__item__link"
                                            data-active={
                                                (route.path === Routes[0].path &&
                                                    location?.pathname === Routes[0].path) ||
                                                (location?.pathname !== '/' &&
                                                    route.path !== Routes[0].path &&
                                                    location?.pathname.startsWith(route.path))
                                            }
                                        >
                                            <route.icon />
                                        </Link>
                                    </li>
                                </AccessControl>
                            )
                    )}
                    <li className="sidebar__nav__item">
                        <div className="sidebar__nav__item__link" onClick={handleLogout}>
                            <Logout />
                        </div>
                    </li>
                </ul>
            </>
        );
    };

    return (
        <div className="sidebar">
            <div className="sidebar__header" onClick={() => history.push('/')}>
                <Logo />
            </div>

            <ul className="list-unstyled accordion" id="sidebar__nav">
                {Routes?.map((route, i) =>
                    route?.dropDowns && route?.dropDowns.length && route?.showDropDown ? (
                        <SidebarDropdown
                            key={i}
                            id={i}
                            route={route}
                            currentPath={location?.pathname}
                            activeRoute={activeRoute}
                            hideSideBar={hideSideBar}
                        />
                    ) : (
                        !route.hide && (
                            <AccessControl
                                key={route?.path}
                                allowedPermissions={route?.accessLevel}
                            >
                                <li className="sidebar__nav__item" onClick={hideSideBar}>
                                    <Link
                                        to={route.path}
                                        className="sidebar__nav__item__link"
                                        data-active={
                                            (route.path === Routes[0].path &&
                                                location?.pathname === Routes[0].path) ||
                                            (location?.pathname !== '/' &&
                                                route.path !== Routes[0].path &&
                                                location?.pathname.startsWith(route.path))
                                        }
                                    >
                                        <route.icon className="me-4" />
                                        {route.name}
                                    </Link>
                                </li>
                            </AccessControl>
                        )
                    )
                )}
                <li className="sidebar__nav__item" onClick={handleLogout}>
                    <div className="sidebar__nav__item__link">
                        <Logout />
                        <span className="ms-4">Logout</span>
                    </div>
                </li>
            </ul>

            <div className="small-sidebar">{getIconSidebar()}</div>
        </div>
    );
};

export default Sidebar;
