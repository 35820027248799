import {gql} from '@apollo/client';

export const GET_ACCOUNT = gql`
    query GetAccount(
        $limit: Int
        $offset: Int
        $order_by: order_by
        $reference: String_comparison_exp = {}
        $account_name: String_comparison_exp = {}
        $account_number: String_comparison_exp = {}
        $bank_name: String_comparison_exp = {}
    ) {
        account(
            limit: $limit
            offset: $offset
            where: {
                reference: $reference
                account_name: $account_name
                account_number: $account_number
                bank_name: $bank_name
            }
            order_by: {account_name: $order_by}
        ) {
            account_name
            account_number
            bank_name
            reference
            user_id
        }
        account_aggregate(
            where: {
                reference: $reference
                account_name: $account_name
                account_number: $account_number
                bank_name: $bank_name
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ACCOUNT_DETAILS = gql`
    query GetAccountDetails($user_id: Int) {
        account(where: {user_id: {_eq: $user_id}}) {
            user_id
            account_name
            bank_name
            account_number
            currency
        }
        cash_balance(admin_account_id: $user_id) {
            balance
        }
    }
`;
