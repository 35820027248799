import {useQuery} from '@apollo/client';
import {GET_CORPORATE_BENEFITS} from 'api/queries/corporate';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import AddBenefitButton from '../buttons/AddNewButton/AddBenefitButton';
import CorporateBenefitsTable from './CorporateBenefitTable/CorporateBenefitsTable';
import SectionWrapper from '../../../../utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from '../../../../utils/Section/SectionContainer/SectionContainer';
import routes from 'components/Dashboard/routes';
import {benefitsFilterParams, corporateBenefitsReportHeadings} from '../helpers/enums';
import usePageProps from 'hooks/usePageProps';
import AccessControl from 'components/App/AccessControl/AccessControl';
import {allPermissions} from 'components/App/AccessControl/permissions';

const CorporateBenefitContent = () => {
    const researchRoutes = routes.find(route => route.name === 'Research');

    const {
        limit,
        query,
        filters,
        currentPage,
        handlePagination,
        handleFilters,
        clearFilters,
        handleLimitChange,
    } = usePageProps({
        filterParams: benefitsFilterParams,
    });

    const queryParams = {
        variables: {
            limit: parseInt(limit),
            offset: currentPage * limit,
            ...query,
        },
    };

    const getCorporateBenefitsQuery = useQuery(GET_CORPORATE_BENEFITS, {
        ...queryParams,
    });

    return (
        <SectionContainer>
            <SectionWrapper
                filters={filters}
                clearFilters={clearFilters}
                handleFilters={handleFilters}
                routes={researchRoutes}
                headerChildrenCls="col-lg-6"
                headerChildren={
                    <AccessControl allowedPermissions={[allPermissions.CAN_ADD_CORPORATE_BENEFITS]}>
                        <AddBenefitButton />
                    </AccessControl>
                }
                navWideMobile
                placeholder="Select Categories"
                rawOptions
                selectInputWidth="large"
                showDownload
                filterParams={benefitsFilterParams}
                filterId="research-filter"
                query={GET_CORPORATE_BENEFITS}
                downloadKey={'corporate_benefit'}
                queryParams={queryParams}
                reportHeadings={corporateBenefitsReportHeadings}
            >
                <ContentWrapper
                    errorTitle="Error fetching corporate benefits"
                    currentQuery={getCorporateBenefitsQuery}
                >
                    <CorporateBenefitsTable
                        limit={limit}
                        currentPage={currentPage}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                        data={getCorporateBenefitsQuery?.data}
                    />
                </ContentWrapper>
            </SectionWrapper>
        </SectionContainer>
    );
};

export default CorporateBenefitContent;
