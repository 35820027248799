import {defaultDateFiltersNew} from 'components/constants';
import React, {useState} from 'react';
import CustomDatePicker from 'utils/CustomDatePicker/CustomDatePicker';
import {formValidator} from 'utils/FormValidator/FormValidator';
import {checkSameDay} from 'utils/helpers';

const CustomerStatementFilters = ({
    currentFilters,
    setCurrentFilters,
    getStatements,
    setIsToday,
    customerId,
    portfolio_id,
}) => {
    const [isFilter, setIsFilter] = useState(false);
    const [filters, setFilters] = useState(currentFilters);

    const handleDate = (name, value) => {
        handleForm(name, value);
        checkSameDay(new Date(), value) ? setIsToday(true) : setIsToday(false);
    };

    const handleForm = (name, value) => {
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (filters) {
            setIsFilter(true);
            const {isValid} = formValidator(filters);
            if (isValid) {
                setIsFilter(true);
                getStatements({
                    variables: {...getAllFilters(filters)},
                });
                setCurrentFilters(filters);
            }
        }
    };

    const getAllFilters = allFilters => {
        const {type} = allFilters || {};
        return {
            ...allFilters,
            type,
            portfolio_id,
            admin_account_id: parseInt(customerId),
        };
    };

    const clearFilter = () => {
        setIsFilter(false);
        setIsToday(true);
        setFilters({...currentFilters, ...defaultDateFiltersNew});
        getStatements({
            variables: {
                ...getAllFilters({
                    ...currentFilters,
                    ...defaultDateFiltersNew,
                }),
            },
        });
    };

    return (
        <form
            className="col-12 d-flex flex__between_tab flex-wrap pt-3 transition--ease"
            onSubmit={handleSubmit}
        >
            <CustomDatePicker
                cls="me-4 col-12 col-sm-5 col-lg-2"
                label="Start Date"
                name="start_date"
                placeholder="Start"
                max={new Date()}
                value={filters?.start_date}
                onChange={e => handleDate('start_date', e)}
            />

            <CustomDatePicker
                label="End Date"
                name="end_date"
                placeholder="End"
                min={filters?.start_date}
                max={new Date()}
                value={filters?.end_date}
                cls="me-4 col-12 col-sm-5 col-lg-2"
                onChange={e => handleDate('end_date', e)}
            />

            <div className="col-6 col-sm-5 col-lg-2 me-4 flex__start__vertical__center">
                <button className="btn btn--lg btn--form btn--primary">Search</button>
            </div>
            {isFilter && (
                <div className="col flex__start__vertical__center">
                    <button
                        onClick={clearFilter}
                        className="btn btn--sm btn--form btn--danger px-4"
                    >
                        Clear
                    </button>
                </div>
            )}
        </form>
    );
};

export default CustomerStatementFilters;
