import React from 'react';
import Modal from 'utils/Modal/Modal';
import AddBenefitForm from './AddBenefitForm/AddBenefitForm';
import {SECONDARY} from '../../../../constants';

const AddBenefitModal = ({show, toggle, headerType = SECONDARY}) => {
    return (
        <Modal show={show} size="xl" title={`New Benefits`} toggle={toggle} type={headerType}>
            <AddBenefitForm toggle={toggle} />
        </Modal>
    );
};

export default AddBenefitModal;
