import {gql} from '@apollo/client';

export const GET_ORDERS = gql`
    query GetOrders($arg: QueryInput!) {
        order(arg: $arg) {
            id
            statusDate
            orderType
            orderTotal
            label
            priceType
            orderTermName
            orderStatus
            quantityFilled
            quantityPending
            portfolioLabel
            securityLabel
            securityName
            instrumentType
        }
    }
`;
