import {gql} from '@apollo/client';

export const DELETE_PORTFOLIO_REPORT = gql`
    mutation DeletePortfolioReport($id: uuid!) {
        delete_portfolio_report_by_pk(id: $id) {
            title
            id
        }
    }
`;

export const ADD_PORTFOLIO_REPORT = gql`
    mutation AddPortfolioReport($object: portfolio_report_insert_input!) {
        insert_portfolio_report_one(object: $object) {
            created_at
            file_url
            id
            thumbnail_url
            title
            updated_at
        }
    }
`;

export const EDIT_PORTFOLIO_REPORT = gql`
    mutation UpdatePortfolioReport($id: uuid!, $file_url: String, $title: String) {
        update_portfolio_report_by_pk(
            pk_columns: {id: $id}
            _set: {file_url: $file_url, title: $title}
        ) {
            id
            title
        }
    }
`;
