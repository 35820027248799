import {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';

//SVG Icons
import {ReactComponent as MailIcon} from 'assets/images/icons/mail.svg';
import {ReactComponent as AuthorIcon} from 'assets/images/icons/author.svg';
import {ReactComponent as UserOutlineIcon} from 'assets/images/icons/user-outline.svg';
import {ReactComponent as GreenCalenderIcon} from 'assets/images/icons/green-calendar.svg';

//Components
import FileViewer from './FileViewer';
import Modal from 'utils/Modal/Modal';
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import AccessControl from 'components/App/AccessControl/AccessControl';

//Utils
import {
    ACCEPT,
    CREATED,
    DECLINE,
    SUCCESS,
    DECLINED,
    APPROVED,
    SECONDARY,
} from 'components/constants';
import {getError} from 'utils/helpers';
import {titleCase} from 'utils/helpers';
import Toast from 'utils/Alert/Toast/Toast';
import {timeDateFormat} from 'utils/helpers';
import {GET_DOWNLOAD_URL} from 'api/queries/customers';
import {UPDATE_MARGIN_REQUESTS} from 'api/mutations/request';
import {allPermissions} from 'components/App/AccessControl/permissions';

const FilePreviewModal = ({
    request,
    headerText,
    headerTitle,
    headerType = SECONDARY,
    onEnter,
    onHide,
    show,
    showFileOptions,
    toggle,
}) => {
    const {
        created_at,
        user: {id, email, first_name, last_name},
    } = request || {};

    const [action, setAction] = useState();

    const [updateMarginRequest, {loading}] = useMutation(UPDATE_MARGIN_REQUESTS, {
        refetchQueries: ['GetMarginRequest'],
        onCompleted: () => {
            Toast({
                msg: `Request ${
                    action === ACCEPT ? titleCase(APPROVED) : titleCase(DECLINED)
                } successfuly`,
                position: 'top-right',
                variant: SUCCESS,
            });
            toggle();
        },
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    const getUrl = useQuery(GET_DOWNLOAD_URL, {
        variables: {path: request?.agreement_url},
    });

    const handleSubmit = type => {
        setAction(type);
        updateMarginRequest({
            variables: {
                id: request?.id,
                status: type === ACCEPT ? APPROVED : DECLINED,
            },
        });
    };

    return (
        <Modal
            onEnter={onEnter}
            onHide={onHide}
            show={show}
            size="lg"
            text={headerText}
            title={headerTitle}
            toggle={toggle}
            type={headerType}
        >
            <div className="wrapper__file_section">
                <div className="flex__between flex-wrap">
                    <div className="col-md-4 col-12 pb-4 text-break">
                        <AuthorIcon className="me-4" />
                        {`${first_name} ${last_name}`}
                    </div>
                    <div className="col-md-8 col-12 pb-4 text-break">
                        <GreenCalenderIcon className="me-3" />
                        {`${timeDateFormat(created_at).date} ${timeDateFormat(created_at).time}`}
                    </div>
                </div>
                <div className="flex__center flex-wrap">
                    <div className="col-md-4 col-12 pt-4 text-break">
                        <MailIcon className="me-3" />
                        {email}
                    </div>
                    <div className="col-md-8 col-12 pt-4 text-break">
                        <UserOutlineIcon className="me-3" />
                        {id}
                    </div>
                </div>
            </div>
            <ContentWrapper currentQuery={getUrl} errorTitle="Error fetching document">
                {getUrl?.data?.admin_get_document?.url && (
                    <FileViewer url={getUrl?.data?.admin_get_document?.url} cls="mt-5" />
                )}
            </ContentWrapper>
            <AccessControl allowedPermissions={[allPermissions.CAN_APPROVE_REQUEST]}>
                {showFileOptions && request?.status === CREATED && (
                    <div className="flex__between flex-wrap">
                        <div className="col-sm-6 col-12 mt-5">
                            <div className="col-sm-11 col-12">
                                <button
                                    className="btn btn--lg btn--primary text--large-sm"
                                    disabled={loading}
                                    onClick={() => handleSubmit(ACCEPT)}
                                >
                                    {loading && action === ACCEPT ? (
                                        <CustomSpinner cls="btn--danger--bordered" />
                                    ) : (
                                        'Accept'
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-5 flex__end">
                            <div className="col-sm-11 col-12">
                                <button
                                    className="btn btn--lg btn--danger--bordered text--large-sm"
                                    disabled={loading}
                                    onClick={() => handleSubmit(DECLINE)}
                                >
                                    {loading && action === DECLINE ? (
                                        <CustomSpinner cls="btn--danger--bordered" />
                                    ) : (
                                        'Decline'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </AccessControl>
        </Modal>
    );
};

export default FilePreviewModal;
