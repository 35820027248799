import {useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useApolloClient, useMutation} from '@apollo/client';
import {LOGIN_USER} from 'api/mutations/auth';
import Toast from 'utils/Alert/Toast/Toast';
import {resetApp} from 'redux/actions/appActions';
import AuthHeader from 'utils/AuthHeader/AuthHeader';
import CustomInputBox from 'utils/CustomInputBox/CustomInputBox';
import CustomPassword from 'utils/CustomInputBox/CustomPassword/CustomPassword';
import {formValidator} from 'utils/FormValidator/FormValidator';
import Spinner from 'utils/CustomSpinner/CustomSpinner';
import {storeUserData} from 'storage/sessionStorage';
import {getError} from 'utils/helpers';

const Login = ({history}) => {
    const client = useApolloClient();
    const dispatch = useDispatch();

    const [formErrors, setFormErrors] = useState({});
    const [request, setRequest] = useState({
        email: '',
        pass: '',
    });

    const [loginUser, {loading}] = useMutation(LOGIN_USER, {
        onCompleted: ({admin_login}) => {
            client.clearStore();
            dispatch(resetApp());
            storeUserData(admin_login);
            history.replace('/');
        },
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    const handleChange = ({target: {name, value}}) => {
        setRequest(prev => ({
            ...prev,
            [name]: value,
        }));
        setFormErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();

        const {email, pass: password} = request || {};
        const {formErrors, isValid} = formValidator(request);

        if (isValid) loginUser({variables: {data: {email, password}}});
        else setFormErrors(formErrors);
    };

    return (
        <div className="px-sm-5 px-0 login">
            <AuthHeader title="Welcome back Admin, Login" />

            <form onSubmit={handleSubmit}>
                <div className="row justify-content-center pt-4 px-5 mx-lg-4">
                    <div className="col-12 px-5">
                        <CustomInputBox
                            type="email"
                            name="email"
                            label="Email"
                            placeholder="Type here"
                            value={request?.email}
                            onChange={handleChange}
                            error={formErrors?.email}
                            required
                        />
                    </div>

                    <div className="col-12 mb-4 px-5">
                        <CustomPassword
                            name="pass"
                            label="Password"
                            placeholder="Type here"
                            value={request?.pass}
                            onChange={handleChange}
                            error={formErrors?.pass}
                            required
                        />
                    </div>

                    <div className="col-12 px-5 mb-5">
                        <button
                            type="submit"
                            disabled={loading}
                            className="btn btn--lg btn--primary"
                        >
                            {loading ? <Spinner /> : 'login'}
                        </button>
                    </div>
                    <Link
                        className="text--primary text-center fw-bold text--large-xs"
                        to="/auth/login"
                    >
                        Forgot Password?
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default Login;
