import React, {useState, useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import {GET_ORDERS} from 'api/queries/orders';
import Table from 'utils/Table/Table';
import EmptyData from 'utils/Table/EmptyData';
import SectionHeader from 'utils/Section/extras/SectionHeader';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import CustomerOrderFilters from './CustomerOrderFilters';
import {timeRangeFormat, timeDateFormat} from 'utils/helpers';
import {ALL} from 'components/constants';
import {ordersHeadings} from '../../helpers/enums';

const all = {label: ALL, value: 'All'};

const defaultFilters = {
    start_date: timeRangeFormat(new Date(), true),
    end_date: timeRangeFormat(new Date()),
    status: all,
    type: all,
    portfolio_id: all,
};

const CustomerOrders = ({customerId, accounts = []}) => {
    const [isToday, setIsToday] = useState(true);

    const [getOrders, getOrdersQuery] = useLazyQuery(GET_ORDERS);
    const {order} = getOrdersQuery?.data || {};

    useEffect(() => {
        const {type, status, portfolio_id} = defaultFilters;
        getOrders({
            variables: {
                arg: {
                    ...defaultFilters,
                    admin_account_id: parseInt(customerId),
                    type: type?.value === ALL ? '' : type,
                    status: status?.value === ALL ? '' : status,
                    portfolio_id: portfolio_id?.value === ALL ? '' : portfolio_id,
                },
            },
        });
        // eslint-disable-next-line
    }, [customerId]);

    const getTableData = () =>
        order?.map(v => ({
            orderNo: v?.id,
            date: timeDateFormat(v?.statusDate)?.date,
            type: v?.orderType,
            total: v.orderTotal,
            label: v.securityLabel,
            priceType: v.priceType,
            orderTermName: v.orderTermName,
            orderStatus: v.orderStatus,
            quantity: v.quantityFilled + v.quantityPending,
            quantityFilled: v.quantityFilled,
            quantityPending: v.quantityPending,
        }));

    return (
        <div className="col-12 px-4 py-4">
            <SectionHeader
                title={`Order History (${order?.length || 0}) ${
                    isToday ? ' - (Today)' : ' - (Date Range)'
                }`}
                customFilters={
                    <CustomerOrderFilters
                        getOrders={getOrders}
                        setIsToday={setIsToday}
                        currentFilters={defaultFilters}
                        accounts={accounts}
                        customerId={customerId}
                    />
                }
            />

            <ContentWrapper
                errorTitle="Error fetching customer's orders"
                currentQuery={getOrdersQuery}
            >
                <>
                    {order?.length ? (
                        <Table
                            headings={ordersHeadings}
                            data={getTableData()}
                            rowClick={() => {}}
                        />
                    ) : (
                        <EmptyData
                            title="No Order Found"
                            subtitle="All customer's orders will be displayed here"
                        />
                    )}
                </>
            </ContentWrapper>
        </div>
    );
};

export default CustomerOrders;
