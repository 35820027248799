import {Chip} from '@material-ui/core';
import {useQuery} from '@apollo/client';
import React, {useEffect, useRef, useState} from 'react';

//SVG Icons
import {ReactComponent as ChevronDown} from '../../../assets/images/icons/chevron-down.svg';

//Components
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';

export default function CustomBuiltSelect({
    cls,
    required,
    createButton,
    queryName,
    placeholder,
    selected,
    error,
    label,
    getLabel,
    handleSelect,
    query,
    isMulti,
    setData,
    infoText,
    baseAPI,
}) {
    const isError = error ? ' error' : '';
    const isRequired = required ? 'required' : '';

    const searchBox = useRef(null);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [allData, setAllData] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        setValue(getLabel(selected));
        // eslint-disable-next-line
    }, [selected]);

    const close = () => setOpen(false);

    const toggle = e => {
        if (
            (e.target === searchBox.current || e.target.id === 'close-pill') &&
            isMulti &&
            selectedList.length > 0
        ) {
            return;
        }
        setOpen(prev => !prev);

        if (open) {
            searchBox.current.focus();
        }
    };

    const removeFromSelectedList = option => {
        setSelectedList(selectedList.filter(item => item.reference !== option.reference));
        handleSelect(selectedList);
    };

    const renderSelectedList = () => {
        return selectedList.map(option => (
            <Chip
                key={getLabel(option)}
                className="my-4 mx-2"
                label={getLabel(option)}
                variant="outlined"
                onDelete={() => removeFromSelectedList(option)}
            />
        ));
    };

    const handleClick = value => {
        let newValue = [...selectedList];
        if (isMulti) {
            const exist = selectedList.find(option => option.id === value.id);
            if (!exist) {
                newValue = [...newValue, value];
                setSelectedList(newValue);
            }
        }

        isMulti ? handleSelect(newValue) : handleSelect(value);
        setValue(getLabel(value));
        setFilteredData(allData);
        close();
    };

    const {loading} = useQuery(query, {
        onCompleted: data => {
            const {[queryName]: options} = data;
            setData && setData(options);
            setFilteredData(options);
            setAllData(options);
        },
    });

    const handleChange = ({target: {value}}) => {
        setOpen(true);
        let newData = [...(allData || [])];
        if (value && getLabel) {
            setValue(value);
            newData = [...newData].filter(option =>
                getLabel(option).toLowerCase().includes(value.toLowerCase())
            );
        } else setValue('');
        setFilteredData(newData);
    };

    return (
        <div className={`form-group ${cls}`}>
            {label && <label className={`form-group-label ${isRequired}`}>{label}</label>}

            <div className="custom_select" tabIndex={0}>
                <div
                    className={`position-relative selected ${
                        open ? 'open' : ''
                    } input-wrapper ${isError}`}
                    onClick={toggle}
                >
                    {isMulti && <div className="mw-100 overflow-auto">{renderSelectedList()}</div>}
                    <input
                        ref={searchBox}
                        type="text"
                        value={value}
                        placeholder={placeholder}
                        className={`w-100 border-0 position-absolute ${
                            !open && selectedList.length > 0 && isMulti ? 'd-none' : ''
                        }`}
                        onInput={handleChange}
                    />

                    {loading && (
                        <span className="pe-3 pt-1">
                            <CustomSpinner cls="gray spinner-border-sm" />
                        </span>
                    )}

                    <ChevronDown className="position-absolute end-0 me-3 gray" />
                </div>
                <div className={`items ${open ? '' : 'd-none'}`}>
                    <div onClick={close} className="options">
                        {filteredData?.map((option, i) => (
                            <div
                                key={i}
                                className="option__list"
                                onClick={() => handleClick(option)}
                            >
                                {getLabel ? getLabel(option) : option}
                            </div>
                        ))}

                        {filteredData?.length === 0 && (
                            <div className="text-center mt-3 py-4 fw-bold">No Record Found</div>
                        )}
                    </div>
                    {createButton && <div className="custom_select_footer">{createButton}</div>}
                </div>
            </div>
            {infoText && <span className="auth-text-gray mt-1">{infoText}</span>}
            {error && (
                <small className="input-text-error">
                    {/* <InfoCircleError className="me-2" /> */}
                    <span className="text-danger error">{error}</span>
                </small>
            )}
        </div>
    );
}
