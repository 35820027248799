import {ROLES} from 'components/constants';
import {fetchUserData} from 'storage/sessionStorage';
import {super_admin, customer_support, operations} from './permissions';

const {SUPER_ADMIN, CUSTOMER_SUPPORT, OPERATIONS} = ROLES;

const getCurrentPermission = () => {
    const {role} = fetchUserData();
    if (role === SUPER_ADMIN) return super_admin;
    else if (role === CUSTOMER_SUPPORT) return customer_support;
    else if (role === OPERATIONS) return operations;
    return [];
};

const checkPermissions = allowedPermissions => {
    return allowedPermissions?.some(allowedPermission =>
        getCurrentPermission().includes(allowedPermission)
    );
};

const AccessControl = ({isPage, children, allowedPermissions = [], page}) => {
    const isValid = checkPermissions(allowedPermissions);
    if (isValid) return <>{children}</>;

    if (isPage && page)
        return (
            <div className="py-5 text-center text--primary">
                <h3 className="fw-bold">You don't have right permission to access this page</h3>
            </div>
        );

    return null;
};

export default AccessControl;
