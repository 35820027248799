// Headings

export const usersHeadings = [' ', 'Role', 'Email', 'Username', 'Date Created'];

// Form Defaults

export const addNewUserFormDefaults = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    role: {},
};

// Options

export const roleOptions = [
    {label: 'Super Admin', value: 'Super Admin'},
    {label: 'Admin', value: 'Admin'},
    {label: 'Operations', value: 'Operations'},
    {label: 'Sales', value: 'Sales'},
    {label: 'Customer Service', value: 'Customer Service'},
];

export const usersFilterParams = adminRoles => [
    {
        name: 'role',
        label: 'Role',
        options: adminRoles,
        query: '_iregex',
    },
    {
        name: 'email',
        label: 'Email',
        type: 'email',
        query: '_iregex',
    },
    {
        name: 'first_name',
        label: 'First Name',
        query: '_iregex',
    },
    {
        name: 'last_name',
        label: 'Last Name',
        query: '_iregex',
    },
];

export const selectInputOptions = ['New', 'Existing'];

export const usersReportHeadings = {
    id: 'ID',
    first_name: 'Firstname',
    last_name: 'Lastname',
    email: 'Email',
};
