import React, {useEffect} from 'react';
import ReactECharts from 'echarts-for-react';

import SectionWrapper from '../../../../../utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from '../../../../../utils/Section/SectionContainer/SectionContainer';

import {volumeChartsOptions} from '../../helpers/enums';
import {loadingOption, cashVolChartOptions} from '../../helpers/chartEnums';

const TotalCashVolume = () => {
    let timer;

    useEffect(() => {
        return () => clearTimeout(timer);
    });

    function onChartReady(echarts) {
        timer = setTimeout(function () {
            echarts.hideLoading();
        }, 3000);
    }

    return (
        <SectionContainer cls="flex__column col-md-6 col-12">
            <SectionWrapper
                largeSelectMobile
                selectInputOptions={volumeChartsOptions}
                title="Total Cash Volume"
            >
                <ReactECharts
                    className="wrapper__chart"
                    loadingOption={loadingOption}
                    onChartReady={onChartReady}
                    option={cashVolChartOptions}
                    opts={{renderer: 'svg'}}
                    showLoading={true}
                    style={{height: '360px', width: '100%'}}
                />
            </SectionWrapper>
        </SectionContainer>
    );
};

export default TotalCashVolume;
