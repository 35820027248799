import React, {useState, useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import Table from 'utils/Table/Table';
import EmptyData from 'utils/Table/EmptyData';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import {timeDateFormat} from 'utils/helpers';
import {ALL} from 'components/constants';
import {cashHeading} from '../../helpers/enums';
import {GET_CASH_STATEMENT} from 'api/queries/statements';
import CustomerStatementFilters from './CustomerStatementFilters';
import {defaultDateFiltersNew} from 'components/constants';
import ViewMore from 'utils/ViewMore/ViewMore';
import {CASH} from 'components/constants';

const CustomerCashStatement = ({
    customerId,
    account,
    setIsToday,
    showFilter,
    setTotal,
    currentTab,
}) => {
    const [currentFilters, setCurrentFilters] = useState(defaultDateFiltersNew);

    const account_id = account?.value === ALL ? '' : account?.value || account?.id;

    const [getStatements, getStatementQuery] = useLazyQuery(GET_CASH_STATEMENT, {
        fetchPolicy: 'no-cache',
        onCompleted: ({cash_statement}) => setTotal(cash_statement?.length || 0),
    });
    const {cash_statement} = getStatementQuery?.data || {};

    useEffect(() => {
        currentTab === CASH &&
            getStatements({
                variables: {
                    ...currentFilters,
                    account_id,
                    admin_account_id: parseInt(customerId),
                },
            });
        // eslint-disable-next-line
    }, [customerId, account, currentTab]);

    const getTableData = () =>
        cash_statement?.map(v => ({
            name: v?.name,
            desc: <ViewMore text={v?.description} />,
            currency: v?.currency,
            debit: <span className="text-danger">{v?.debitAmount}</span>,
            credit: <span className="text--primary">{v?.creditAmount}</span>,
            balance: v?.balance,
            transactDate: timeDateFormat(parseInt(v?.transactionDate)).date,
            valueDate: timeDateFormat(parseInt(v?.valueDate)).date,
        }));

    return (
        <div className="col-12 px-4 py-4">
            {showFilter && (
                <CustomerStatementFilters
                    getStatements={getStatements}
                    setIsToday={setIsToday}
                    currentFilters={currentFilters}
                    customerId={customerId}
                    portfolio_id={account_id}
                    setCurrentFilters={setCurrentFilters}
                />
            )}

            <ContentWrapper
                errorTitle="Error fetching customer's cash statements"
                currentQuery={getStatementQuery}
            >
                <>
                    {cash_statement?.length ? (
                        <Table headings={cashHeading} data={getTableData()} rowClick={() => {}} />
                    ) : (
                        <EmptyData
                            title="No Cash Statement Found"
                            subtitle="All customer's cash statement will be displayed here"
                        />
                    )}
                </>
            </ContentWrapper>
        </div>
    );
};

export default CustomerCashStatement;
