import {useEffect, useRef} from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import {getError} from 'utils/helpers';
import Toast from 'utils/Alert/Toast/Toast';

const FileViewer = ({url, cls}) => {
    const viewer = useRef(null);

    useEffect(() => {
        WebViewer(
            {
                path: '/webviewer',
                initialDoc: url,
                licenseKey: 'VMeLR5MsW5lX3X9YfqQF',
                css: '/assets/css/viewer.css',
            },
            viewer.current
        ).catch(error => Toast({msg: getError(error), position: 'top-right'}));
        // eslint-disable-next-line
    }, []);

    return <div className={`pdf_viewer wrapper__file_section ${cls}`} ref={viewer}></div>;
};

export default FileViewer;
