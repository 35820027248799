import {CURRENT_FILTERS, PAGE_INFO} from 'components/constants';

const CryptoJS = require('crypto-js');
const rowSize = 10;

const secretKey = process.env.REACT_APP_ENCRYPT_KEY;

export const fetchLocalUserData = (storeKey = 'localUserData') => {
    try {
        let userData = localStorage.getItem(storeKey);

        if (userData) {
            const bytes = CryptoJS.AES.decrypt(userData, secretKey);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decryptedData;
        }
        return {};
    } catch (error) {
        console.log(error);
        throw new Error('Local storage permission is needed');
    }
};

export const storeLocalUserData = (userData, storeKey = 'localUserData') => {
    let prevData = fetchLocalUserData(storeKey);
    const data = JSON.stringify({...prevData, ...userData});
    var ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
    try {
        localStorage.setItem(storeKey, ciphertext);
    } catch (error) {
        throw new Error('Local storage permission is needed');
    }
};

export const deleteLocalUserData = (storeKey = 'localUserData') => {
    try {
        localStorage.removeItem(storeKey);
    } catch (error) {
        throw new Error('Local storage permission is needed');
    }
};

export const storeRowSize = size => {
    try {
        storeLocalUserData({size}, PAGE_INFO);
    } catch (error) {
        storeLocalUserData({size: rowSize}, PAGE_INFO);
    }
};

export const getRowSize = () => {
    try {
        const {size} = fetchLocalUserData(PAGE_INFO);
        if (size === null || size === undefined) return rowSize;
        return size;
    } catch (err) {
        return rowSize;
    }
};

export const savePageInfo = data => {
    try {
        data?.page >= 0
            ? storeLocalUserData(data, PAGE_INFO)
            : storeLocalUserData(data, CURRENT_FILTERS);
    } catch (error) {
        throw new Error(error);
    }
};

export const getPageInfo = isPage => {
    const getValidDate = str => {
        const d = new Date(str);
        return str !== '' && str !== null && d instanceof Date && isFinite(d) ? d : str;
    };
    try {
        const pageFilters = {};
        const dataType = isPage ? PAGE_INFO : CURRENT_FILTERS;
        const pageInfo = fetchLocalUserData(dataType);

        if (!isPage) {
            for (const [key, value] of Object.entries(pageInfo?.filters)) {
                pageFilters[key] = getValidDate(value);
            }
        }

        if (pageInfo === null) return {};
        let filters = {...pageInfo?.filters, ...pageFilters};

        return isPage ? pageInfo : {...pageInfo, filters};
    } catch (error) {
        return {};
    }
};
