export const walletTransactionFilterParams = [
    {
        name: 'reference',
        label: 'Reference',
        query: '_eq',
    },
    {
        name: 'user_id',
        label: 'User Id',
        query: '_eq',
    },
    {
        name: 'type',
        label: 'Transaction Type',
        options: [
            {label: 'Fund', value: 'fund'},
            {label: 'Withdraw', value: 'withdraw'},
        ],
        query: '_eq',
    },
    {
        name: 'completed',
        label: 'Transaction Status',
        options: [
            {label: 'Successful', value: 'true'},
            {label: 'Pending', value: 'false'},
        ],
        query: '_eq',
    },
    {
        name: 'from',
        label: 'Start Date',
        query: '_gte',
        isDate: true,
    },
    {
        name: 'to',
        label: 'End Date',
        query: '_lte',
        isDate: true,
    },
];

export const customersExportHeadings = {
    id: 'ID',
    first_name: 'Firstname',
    last_name: 'Lastname',
    email: 'Email',
    phone: 'Phone Number',
    type_id: 'Account Type',
};

export const walletTransactionsExportHeadings = {
    reference: 'Transaction Reference',
    'user.first_name': 'First Name',
    'user.last_name': 'Last Name',
    amount: 'Amount Funded',
    type: 'Transaction Type',
    channel: 'Payment Method',
    completed: 'Transaction Status',
    created_at: 'Date',
};

export const walletExportHeadings = {
    user_id: 'User ID',
    reference: 'Wallet Reference',
    account_number: 'Account Number',
    account_name: 'Account Name',
    bank_name: 'Bank Name',
};
