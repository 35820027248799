import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import CustomInputBox from 'utils/CustomInputBox/CustomInputBox';
import CustomSelect from 'utils/Select/CustomSelect/CustomSelect';
import EditProfileButton from '../buttons/EditProfileButton/EditProfileButton';
import {GET_INVESTMENT_ENUMS} from 'api/queries/others/investmentEnums';
import AccessControl from 'components/App/AccessControl/AccessControl';
import {allPermissions} from 'components/App/AccessControl/permissions';
import {UPDATE_INVESTMENT_PROFILE} from 'api/mutations/customers';
import {SUCCESS} from 'components/constants';
import Toast from 'utils/Alert/Toast/Toast';
import {formValidator} from 'utils/FormValidator/FormValidator';
import {getError} from 'utils/helpers';

const Investment = ({user}) => {
    const [disabled, setDisabled] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [request, setRequest] = useState({});

    useEffect(() => {
        if (user.investment_profile) setRequest(user.investment_profile);
        // eslint-disable-next-line
    }, [user]);

    const toggleEdit = () => setDisabled(!disabled);

    const {data} = useQuery(GET_INVESTMENT_ENUMS);

    const handleChange = ({target: {name, value}}) => {
        handleSelect(name, value);
    };

    const handleSelect = (name, value) => {
        setRequest(prev => ({
            ...prev,
            [name]: value,
        }));

        setFormErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (disabled) {
            setFormErrors({});
            toggleEdit();
        } else {
            const payload = {
                investment_knowledge: request?.investment_knowledge?.value,
                net_worth: parseInt(request?.net_worth),
                investment_objective: request?.investment_objective?.value,
                risk_tolerance: request?.risk_tolerance?.value,
                source_of_wealth: request?.source_of_wealth?.value,
            };
            const {formErrors, isValid} = formValidator(payload);
            isValid
                ? updateProfile({
                      variables: {
                          userId: user?.id,
                          profile: {
                              knowledge_id: payload.investment_knowledge,
                              net_worth: payload.net_worth,
                              objective_id: payload.investment_objective,
                              risk_tolerance_id: payload.risk_tolerance,
                              source_of_wealth_id: payload.source_of_wealth,
                          },
                      },
                  })
                : setFormErrors(formErrors);
        }
    };

    const [updateProfile, {loading}] = useMutation(UPDATE_INVESTMENT_PROFILE, {
        onCompleted: () => {
            Toast({
                msg: 'Investment profile updated successfully',
                position: 'top-right',
                variant: SUCCESS,
            });
            toggleEdit();
        },
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    const investmentFormParams = [
        {
            name: 'investment_objective',
            label: 'Investment Objective',
            options: data?.investment_objective || [],
        },
        {
            name: 'risk_tolerance',
            label: 'Risk Tolerance',
            options: data?.risk_tolerance || [],
        },
        {
            name: 'investment_knowledge',
            label: 'Investment Knowledge',
            options: data?.investment_knowledge || [],
        },
        {
            name: 'net_worth',
            label: 'Estimated Net Worth',
        },
        {
            name: 'source_of_wealth',
            label: 'Source of Wealth',
            options: data?.source_of_wealth || [],
        },
    ];

    return (
        <div className="my-4 mx-4">
            <div className="flex__between flex-wrap">
                {investmentFormParams.map((param, i) => {
                    const {
                        label,
                        labelIcon,
                        name,
                        options,
                        placeholder = 'Type Here',
                        required,
                        disable,
                    } = param;

                    if (options) {
                        return (
                            <CustomSelect
                                key={i}
                                disabled={disable || disabled}
                                error={formErrors[name]}
                                formGroupCls="wrapper__width_31_perc mx-3"
                                label={label}
                                labelIcon={labelIcon}
                                name={name}
                                onChange={value => handleSelect(name, value)}
                                options={options}
                                placeholder={placeholder}
                                required={required}
                                value={request[name]}
                                getOptionLabel={({comment}) => comment}
                            />
                        );
                    }

                    return (
                        <CustomInputBox
                            key={i}
                            cls="wrapper__width_31_perc mx-3"
                            disabled={disabled}
                            error={formErrors[name]}
                            label={label}
                            name={name}
                            onChange={handleChange}
                            value={request[name]}
                        />
                    );
                })}
            </div>
            <AccessControl allowedPermissions={[allPermissions.CAN_MODIFY_CUSTOMERS]}>
                <div className="d-flex flex-wrap mt-4 ps-3 me-5">
                    <EditProfileButton
                        loading={loading}
                        formDisabled={disabled}
                        onClick={handleSubmit}
                        type="Investment"
                    />
                </div>
            </AccessControl>
        </div>
    );
};

export default Investment;
