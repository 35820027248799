import React from 'react';
import {useQuery} from '@apollo/client';
import usePageProps from 'hooks/usePageProps';
import WalletStats from './WalletStats/WalletStats';
import UserWalletInfo from '../common/Banking/UserWalletInfo/UserWalletInfo';
import {GET_ACCOUNT} from 'api/queries/wallet';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import {userWalletInfoFilterParams} from '../common/helpers/enums';

const Wallets = () => {
    const {
        limit,
        query,
        filters,
        currentPage = 0,
        handlePagination,
        handleFilters,
        clearFilters,
        handleLimitChange,
    } = usePageProps({
        filterParams: userWalletInfoFilterParams,
    });

    const queryParams = {
        variables: {
            order_by: 'asc',
            limit: parseInt(limit),
            offset: currentPage * limit,
            ...query,
        },
    };

    const getAccountQuery = useQuery(GET_ACCOUNT, {...queryParams});

    const {account, account_aggregate} = getAccountQuery?.data || {};

    const totalPages = Math.ceil(account_aggregate?.aggregate?.count / limit) || 1;

    const pageInfo = {
        currentPage,
        totalPages,
        handlePagination,
        handleLimitChange,
    };

    const filterProps = {
        filters,
        query: GET_ACCOUNT,
        queryParams,
        clearFilters,
        handleFilters,
    };

    return (
        <>
            <WalletStats />
            <ContentWrapper errorTitle="Error fetching wallet" currentQuery={getAccountQuery}>
                <UserWalletInfo
                    showFilter
                    title={`User Wallet Information (${account_aggregate?.aggregate?.count || 0})`}
                    wallets={account}
                    pageInfo={pageInfo}
                    filterProps={filterProps}
                />
            </ContentWrapper>
        </>
    );
};

export default Wallets;
