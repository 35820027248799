import React from 'react';
import WalletMetadata from './WalletMetadata/WalletMetadata';
import WalletContent from 'components/Dashboard/Transactions/WalletContent/WalletContent';

const WalletDetails = ({user_id}) => (
    <>
        <WalletMetadata user_id={user_id} />
        <WalletContent />
    </>
);

export default WalletDetails;
