import React, {useState} from 'react';
import {ReactComponent as FilterIcon} from 'assets/images/icons/filter.svg';
import Filter from '../../Filter/Filter';
import NavTabs from '../../NavTabs/NavTabs';
import SmallSelect from '../../Select/SmallSelect/SmallSelect';
import DownloadBtn from 'components/Dashboard/common/DownloadBtn/DownloadBtn';

const SectionHeader = ({
    filters,
    defaultFilters,
    handleFilters,
    clearFilters,
    allowSelectInMultiTab,
    filterId,
    filterParams,
    headerChildren,
    headerChildrenCls,
    headerChildrenNoWrap,
    iconAreaWidthMax,
    largeSelectMobile,
    navId,
    navWideMobile,
    placeholder,
    rawOptions,
    selectCustomDispValue,
    selectCustomHandlerFn,
    selectCustomValue,
    selectCustomName = 'section-header-select',
    selectInputWidth = 'small',
    selectInputOptions,
    showDownload,
    routes,
    title,
    titleChildren,
    query,
    downloadKey,
    reportHeadings,
    queryParams,
    setIsToday,
    customFilters,
}) => {
    const [showFilter, setShowFilter] = useState(false);
    const [selectDefaultValue, setSelectDefaultValue] = useState(
        selectInputOptions ? selectInputOptions[0] : ' '
    );

    const selectValue =
        selectCustomValue && !selectCustomDispValue
            ? selectCustomValue
            : selectCustomDispValue
            ? selectCustomDispValue
            : selectDefaultValue;

    const toggleFilter = () => setShowFilter(!showFilter);
    const handleCustomMultiSelect = (name, value) =>
        selectCustomHandlerFn(prev => ({
            ...prev,
            [name]: value,
        }));
    const handleSelect = ({target: {name, value}}) =>
        selectCustomHandlerFn && !allowSelectInMultiTab
            ? selectCustomHandlerFn(value)
            : allowSelectInMultiTab
            ? handleCustomMultiSelect(name, value)
            : setSelectDefaultValue(value);
    return (
        <div id="wrapper__section-header">
            <div className="flex__between pb-2 flex-wrap">
                {title && (
                    <div className={routes ? 'w-100 pb-2 flex__between flex-wrap' : ''}>
                        <span className="heading8">{title}</span>
                        {titleChildren}
                    </div>
                )}
                {routes && <NavTabs routes={routes} navId={navId} navWideMobile={navWideMobile} />}
                <span
                    className={`wrapper__col-12_mobile flex__between_tab ${
                        !headerChildrenNoWrap ? 'flex-wrap' : ''
                    } ${
                        !title && !routes ? 'w-100 flex__between' : 'flex__end'
                    } ${headerChildrenCls}`}
                >
                    {headerChildren}

                    <span
                        className={
                            !title && !routes && !headerChildren && iconAreaWidthMax
                                ? 'w-100 flex__between'
                                : 'flex__end'
                        }
                    >
                        <span className="d-flex">
                            {showDownload && query && reportHeadings && (
                                <DownloadBtn
                                    downloadKey={downloadKey}
                                    query={query}
                                    queryParams={queryParams}
                                    reportHeadings={reportHeadings}
                                />
                            )}

                            {(filterParams || customFilters) && (
                                <FilterIcon
                                    height="20px"
                                    width="17px"
                                    className="pointer me-4 my-auto"
                                    onClick={toggleFilter}
                                />
                            )}
                        </span>

                        {selectInputOptions && (
                            <SmallSelect
                                cls={`${
                                    selectInputWidth === 'large'
                                        ? 'large_select'
                                        : selectInputWidth === 'medium'
                                        ? 'medium_select'
                                        : 'small_select'
                                } wrapper__mt-3-mobile ${
                                    largeSelectMobile ? 'large_select_mobile' : ''
                                }`}
                                name={selectCustomName}
                                onChange={handleSelect}
                                options={selectInputOptions}
                                placeholder={placeholder}
                                value={selectValue}
                                rawOptions={rawOptions}
                            />
                        )}
                    </span>
                </span>
            </div>
            {filterParams && showFilter && (
                <Filter
                    filters={filters}
                    defaultFilters={defaultFilters}
                    clearFilters={clearFilters}
                    handleFilters={handleFilters}
                    id={filterId}
                    params={filterParams}
                    setIsToday={setIsToday}
                />
            )}

            {customFilters && showFilter && customFilters}
        </div>
    );
};

export default SectionHeader;
