import {useMutation} from '@apollo/client';
import Toast from 'utils/Alert/Toast/Toast';
import Modal from 'utils/Modal/Modal';
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';
import {getError} from 'utils/helpers';
import {SECONDARY, SUCCESS} from '../../../../constants';
import {DELETE_CORPORATE_BENEFITS} from 'api/mutations/corporate';

const DeleteCorporateBenefitModal = ({show, toggle, data}) => {
    const [deleteCorporateBenefits, {loading}] = useMutation(DELETE_CORPORATE_BENEFITS, {
        refetchQueries: ['GetCorporateBenefits'],
        onCompleted: () => {
            Toast({
                msg: 'Benefit deleted successfully',
                position: 'top-right',
                variant: SUCCESS,
            });
            toggle();
        },
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    return (
        <Modal type={SECONDARY} show={show} title="Remove Research?" toggle={toggle}>
            <div className="text-center text--large-sm text--gray px-5">
                Are you sure you want to remove this Research?
            </div>
            <div className="flex__between flex-wrap pt-5">
                <div className="col-sm-6 col-12 mt-3">
                    <div className="col-sm-11 col-12">
                        <button
                            disabled={loading}
                            onClick={toggle}
                            className="btn btn--lg btn--primary fw-bold"
                        >
                            No
                        </button>
                    </div>
                </div>
                <div className="col-sm-6 col-12 flex__end mt-3">
                    <div className="col-sm-11 col-12">
                        <button
                            disabled={loading}
                            onClick={() =>
                                deleteCorporateBenefits({
                                    variables: {id: data?.id},
                                })
                            }
                            className="btn btn--lg btn--danger--bordered fw-bold"
                        >
                            {loading ? <CustomSpinner cls="btn--danger--bordered" /> : 'Yes'}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteCorporateBenefitModal;
