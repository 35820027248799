import Select from 'react-select';

const CustomSelect = ({
    label,
    name,
    value,
    error,
    dashedWithIcon,
    isMulti,
    icon,
    inputCls,
    options,
    placeholder,
    required,
    disabled,
    getOptionLabel,
    getOptionValue,
    graySelect,
    formGroupCls,
    onChange,
}) => {
    const cls = `form-group-select${isMulti ? '--is-multi' : ''}`;
    const isRequired = required ? 'required' : '';
    const isError = error ? '_error' : '';
    const showDashedwithIcon = dashedWithIcon ? 'form-group-select__dashed' : '';

    return (
        <div className={`form-group ${formGroupCls}`}>
            {label && (
                <label htmlFor={name} className={`form-group-label ${isRequired}`}>
                    {label}
                </label>
            )}

            <div className={`d-flex align-items-center ${showDashedwithIcon}${isError}`}>
                {icon && <div className="px-3">{icon}</div>}
                <div className={`${inputCls} ${cls}${!dashedWithIcon ? isError : ''}`}>
                    <Select
                        inputId={name}
                        name={name}
                        placeholder={placeholder}
                        isMulti={isMulti}
                        options={options}
                        value={value}
                        isLoading={!options}
                        isDisabled={disabled}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        classNamePrefix={`${graySelect ? 'form-group-select__gray' : ''} ${cls}`}
                        onChange={onChange}
                    />
                </div>
            </div>
            {typeof error === 'string' && <small className="input-text-error">{error}</small>}
        </div>
    );
};

export default CustomSelect;
