import {ReactComponent as Snap} from '../../assets/images/icons/snap.svg';
import {ReactComponent as ArrowIcon} from '../../assets/images/icons/arrow-up.svg';
import {ReactComponent as DocumentIcon} from '../../assets/images/icons/document.svg';

const FileUpload = ({
    label,
    id,
    name,
    className = '',
    validFileTypes = [],
    percent = '0',
    file,
    error,
    isSnap,
    refFile,
    required,
    btnText,
    noArrowIcon,
    isScan,
    onChange,
    onSnapClick,
    docIconCls,
    click,
    onClick,
    isDownload,
}) => {
    const isRequired = required ? 'required' : ' ';
    return (
        <div className="flex__between file__upload flex-wrap-mobile flex__center_mobile">
            <div className="icon__wrapper">
                <DocumentIcon className={docIconCls} width="23px" height="30px" />
            </div>

            <div className="w-100 ps-2 pe-3 py-4">
                <small className={`fw-bold flex__between text--gray ${isRequired}`}>
                    {label}
                    {!isDownload && <strong className="ms-auto">{percent + '%'}</strong>}
                </small>
                {!isDownload && (
                    <div className="progress">
                        <div
                            className="progress-bar progress-bar-striped bg-success"
                            role="progressbar"
                            style={{width: `${percent}%`}}
                            aria-valuenow={percent}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                )}
                {error ? (
                    <small className="text-danger fw-bold d-block">{error}</small>
                ) : (
                    <small className="text--primary fw-bold d-block">{file?.name}</small>
                )}
            </div>

            <input
                type="file"
                id={id}
                name={name}
                ref={refFile}
                className={`file-field ${className}`}
                accept={validFileTypes.join(', ')}
                onChange={({target: {files}}) => onChange(files)}
            />

            {isSnap ? (
                <div className="text-center ms-3 wrapper__col-12_mobile" style={{width: '110px'}}>
                    <Snap className="pointer" width="30" onClick={onSnapClick} />
                </div>
            ) : isScan ? (
                <label
                    className="btn btn--xm btn--primary wrapper__col-12_mobile px-3 btn--file-upload-btn"
                    onClick={click}
                >
                    {btnText ? btnText : 'Upload/Scan'}
                </label>
            ) : isDownload ? (
                <button
                    className="btn btn--xm btn--primary wrapper__col-12_mobile px-3 py-3 btn--file-upload-btn"
                    // disabled={percent !== "100"}
                    onClick={onClick}
                >
                    {btnText ? btnText : 'Download'}
                </button>
            ) : (
                <label
                    htmlFor={id}
                    className="btn btn--xm btn--primary wrapper__col-12_mobile px-3 py-3 btn--file-upload-btn"
                >
                    {!noArrowIcon && <ArrowIcon height="10px" className="me-2" />}
                    {btnText ? btnText : 'Upload'}
                </label>
            )}
        </div>
    );
};

export default FileUpload;
