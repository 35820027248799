import React, {useState} from 'react';

import {ReactComponent as AddNewIcon} from '../../../../../assets/images/icons/add-new.svg';

import AddUserModal from '../../modals/AddUserModal/AddUserModal';

const AddNewButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);
    return (
        <>
            <AddNewIcon className="me-3 pointer" onClick={toggleModal} height="25px" width="25px" />

            {isOpen && <AddUserModal show={isOpen} toggle={toggleModal} />}
        </>
    );
};

export default AddNewButton;
