import {Parser} from 'json2csv';
import moment from 'moment';

export const getError = error => {
    const errorMessage =
        error && error.message
            ? titleCase(error.message?.replace(/[^a-z0-9+]+/gi, ' '))
            : 'Error Processing Request. Try again.';

    return errorMessage;
};

export const truncate = (str, len = 50) => {
    if (str?.length > len) {
        return str?.substring(0, len) + '...';
    } else {
        return str;
    }
};

export const titleCase = string => {
    if (string === null || string === undefined) {
        return '';
    } else {
        let text = string.toLowerCase().split('_');
        for (var i = 0; i < text.length; i++) {
            text[i] = text[i][0].toUpperCase() + text[i].slice(1);
        }
        return text.join(' ');
    }
};

export const kebabCase = str =>
    str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .join('-')
        .toLowerCase();

export const toCurrency = n => {
    if (n === null || n === undefined) return 0;
    return Number(n)
        .toFixed(2)
        .replace(/./g, function (c, i, a) {
            return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
        });
};

export const timeDateFormat = (obj, format = 'YYYY-MM-DD') => {
    if (!obj) return '';

    const newObj = new Date(obj);

    const time = moment(newObj).format('hh:mm:ss a');
    const timeOnly = moment(newObj).format('hh:mm:ss');
    const date = moment(newObj).format(format);
    return {time, date, timeOnly};
};

export const timeRangeFormat = (obj, init) => {
    if (!obj) return null;

    const newDate = new Date(obj);
    init ? newDate.setHours(0, 0, 0, 0) : newDate.setHours(23, 59, 59, 0);
    return newDate;
};

export const toPrice = price_change => {
    if (price_change < 0) {
        return <span className="text-danger">-₦{toCurrency(Math.abs(price_change))}</span>;
    } else return <span className="text--primary">+₦{toCurrency(Math.abs(price_change))}</span>;
};

export const toPercentagePrice = percent_price_change => {
    if (percent_price_change < 0) {
        return <span className="text-danger">-{toCurrency(Math.abs(percent_price_change))}%</span>;
    } else
        return (
            <span className="text--primary">+{toCurrency(Math.abs(percent_price_change))}%</span>
        );
};

export const toLocaleString = (value, min = 2, max = 2) =>
    value?.toLocaleString('en', {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    });

export const getCurrency = currency => {
    switch (currency) {
        case 'NGN':
            return '₦';

        case 'USD':
            return '$';

        default:
            return '';
    }
};

export const getDateRange = dateRange => {
    switch (dateRange) {
        case '1D':
            return moment().startOf('day').format();
        case '1W':
            return moment().startOf('week').format();
        case '1M':
            return moment().startOf('month').format();
        case '3M':
            return moment().subtract(3, 'months').format();
        case '6M':
            return moment().subtract(6, 'months').format();
        case '1Y':
            return moment().startOf('year').format();
        case '5Y':
            return moment().subtract(7, 'years').format();
        case 'All':
            return moment().subtract(60, 'years').format();
        default:
            return moment(new Date()).format();
    }
};

export const displayOrderType = type => {
    const cls =
        type === 'Buy' || type === 'Executed'
            ? 'text--primary'
            : type === 'Pending'
            ? 'text--warning'
            : 'text--danger';
    return <span className={cls}>{type}</span>;
};

export const transformFilters = (data = {}, filterParams = [], setQuery = () => {}) => {
    const transformed = Object.keys(data).reduce((r, v) => {
        if (
            !data?.[v] ||
            (data?.[v]?.trim && data?.[v]?.trim() === '') ||
            (data?.[v]?.value?.trim && data?.[v]?.value?.trim() === '')
        ) {
            r[v] = {};
            return r;
        }
        const param = filterParams.find(params => params?.name === v);
        const condition = param?.query ? param?.query : '_eq';
        const currentValue = data?.[v]?.label
            ? data?.[v]?.value?.trim
                ? data?.[v]?.value?.trim()
                : data?.[v]?.value
            : data?.[v]?.trim
            ? data?.[v]?.trim()
            : data?.[v];

        r[v] = {[condition]: currentValue};

        return r;
    }, {});
    setQuery?.(transformed);
    return transformed;
};

export const defaultFilterValues = (params, filter = {}) => {
    const filters = {};
    params?.forEach(({name, isDate}) => {
        filters[name] = isDate ? filter?.[name] || '' : '';
    });

    return filters;
};

export const downloadReport = async (tableHeadings, data) => {
    let fields = Object.entries(tableHeadings).map(val => ({
        label: val[1],
        value: val[0],
    }));

    const json2csvParser = new Parser({fields});
    const csv = json2csvParser.parse(data);
    let blob = new Blob([csv], {
            type: 'text/csv',
        }),
        url = window.URL.createObjectURL(blob);
    window.open(url);
};

export const checkSameDay = (first, second) =>
    first?.getFullYear() === second?.getFullYear() &&
    first?.getMonth() === second?.getMonth() &&
    first?.getDate() === second?.getDate();

export const downloadPDF = data => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.pdf');
    document.body.appendChild(link);
    link.click();
};
