import {
    HEADLESS,
    NORMAL,
    PRIMARY,
    SECONDARY,
    HEADLESS_CLOSE_ROUNDED,
} from '../../../components/constants';

import {ReactComponent as BackIcon} from '../../../assets/images/icons/back.svg';
import {ReactComponent as CloseIcon} from '../../../assets/images/icons/close.svg';
import {ReactComponent as CloseRoundedIcon} from '../../../assets/images/icons/closecover.svg';

const ModalHeader = ({type, toggle, prevStep, title, text}) => {
    const render = () => {
        switch (type) {
            case HEADLESS:
                return (
                    <div className="flex__end p-4">
                        <CloseIcon onClick={toggle} width="25px" />
                    </div>
                );

            case NORMAL:
                return (
                    <div className="m-4 flex__between">
                        <h3 className="fw-bold heading8 m-0">{title}</h3>
                        <CloseRoundedIcon onClick={toggle} />
                    </div>
                );

            case PRIMARY:
                return (
                    <div className="primary__header">
                        <p className="heading6 mb-0">{title}</p>
                        <h3>{text}</h3>
                    </div>
                );

            case SECONDARY:
                return (
                    <div className="text-center pb-4 position-relative">
                        {prevStep && (
                            <div
                                className="abs position-absolute pointer"
                                style={{left: '10px', top: '15px'}}
                            >
                                <BackIcon height="25px" onClick={prevStep} />
                            </div>
                        )}
                        <p className="heading6 border-bottom pb-4 pt-0">{title}</p>
                        {toggle && (
                            <div
                                className="abs position-absolute pointer"
                                style={{right: '0px', top: '0px'}}
                            >
                                <CloseRoundedIcon height="25px" onClick={toggle} />
                            </div>
                        )}
                    </div>
                );

            case HEADLESS_CLOSE_ROUNDED:
                return (
                    <div className="flex__end p-4">
                        <CloseRoundedIcon onClick={toggle} />
                    </div>
                );

            default:
                return '';
        }
    };

    return <>{render()}</>;
};

export default ModalHeader;
