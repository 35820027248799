import {gql} from '@apollo/client';

export const GET_PORTFOLIO_AGGREGATE = gql`
    query PortfolioAggregate(
        $limit: Int
        $offset: Int
        $id: uuid_comparison_exp = {}
        $title: String_comparison_exp = {}
        $time_range: timestamptz_comparison_exp = {}
    ) {
        portfolio_report(
            limit: $limit
            offset: $offset
            where: {id: $id, title: $title, created_at: $time_range}
            order_by: {created_at: desc}
        ) {
            created_at
            file_url
            id
            thumbnail_url
            title
        }
        portfolio_report_aggregate(where: {id: $id, title: $title, created_at: $time_range}) {
            aggregate {
                count
            }
        }
    }
`;
