import {useState} from 'react';
import {useMutation} from '@apollo/client';

//Components
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';
import CustomInputBox from 'utils/CustomInputBox/CustomInputBox';
import CustomBuiltSelect from 'utils/Select/CustomBuiltSelect/CustomBuiltSelect';

//Utils
import {getError} from 'utils/helpers';
import Toast from 'utils/Alert/Toast/Toast';
import {SUCCESS} from 'components/constants';
import {GET_ADMIN_ROLES} from 'api/queries/adminUsers';
import {CREATE_ADMIN_USER} from 'api/mutations/adminUsers';
import {formValidator} from 'utils/FormValidator/FormValidator';
import {addNewUserFormDefaults} from 'components/Dashboard/Users/helpers/enums';

const AddUserForm = ({toggle}) => {
    const [formErrors, setFormErrors] = useState({});
    const [form, setForm] = useState(addNewUserFormDefaults);

    const [createAdminUser, {loading}] = useMutation(CREATE_ADMIN_USER, {
        refetchQueries: ['getAdminUsers'],
        onCompleted: () => {
            Toast({
                msg: 'User created successfuly',
                position: 'top-right',
                variant: SUCCESS,
            });
            toggle();
        },
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    const handleSelect = (name, value) => {
        setForm(prev => ({
            ...prev,
            [name]: value,
        }));

        setFormErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const handleChange = ({target: {name, value}}) => handleSelect(name, value);

    const handleSubmit = e => {
        e.preventDefault();

        const {formErrors, isValid} = formValidator(form);

        if (isValid) {
            const payload = {...form};
            payload.role = payload?.role?.value;

            return createAdminUser({variables: {data: payload}});
        }
        return setFormErrors(formErrors);
    };

    return (
        <form className="col-12 flex__column flex__center" onSubmit={handleSubmit}>
            <div className="w-100">
                <div className="flex__between flex-wrap">
                    <CustomInputBox
                        cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                        error={formErrors?.first_name}
                        label="First Name"
                        name="first_name"
                        required
                        onChange={handleChange}
                        value={form?.first_name}
                    />
                    <CustomInputBox
                        cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                        error={formErrors?.last_name}
                        label="Last Name"
                        name="last_name"
                        required
                        onChange={handleChange}
                        value={form?.last_name}
                    />
                </div>
                <div className="flex__between flex-wrap">
                    <CustomInputBox
                        cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                        error={formErrors?.email}
                        label="Email"
                        name="email"
                        required
                        onChange={handleChange}
                        value={form?.email}
                    />
                    <CustomInputBox
                        cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                        error={formErrors?.password}
                        label="Password"
                        name="password"
                        required
                        type="password"
                        onChange={handleChange}
                        value={form?.password}
                    />
                </div>
                <div className="flex__between flex-wrap">
                    <CustomBuiltSelect
                        cls="wrapper__width_47_perc wrapper__col-12_mobile new-report-input"
                        query={GET_ADMIN_ROLES}
                        queryName="admin_role"
                        label="Role"
                        name="role"
                        placeholder="Choose a Role"
                        error={formErrors?.role}
                        selected={form?.role || ''}
                        handleSelect={value => handleSelect('role', value)}
                        getLabel={v => (v?.comment ? `${v?.comment}` : '')}
                        required
                    />
                </div>
            </div>

            <div className="col-12 col-sm-6 flex__center">
                <div className="mt-5 w-100">
                    <button
                        disabled={loading}
                        className="btn btn--lg btn--primary text--large-sm py-5"
                    >
                        {loading ? <CustomSpinner /> : 'Save'}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default AddUserForm;
