import React from 'react';
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';

const EditProfileButton = ({formDisabled, type, onClick, loading}) => {
    const btnName = formDisabled ? `Edit ${type}` : 'Submit';

    return (
        <div className="wrapper__width_31_perc me-5" onClick={onClick}>
            <button
                className="btn btn--lg btn--primary text--large-xs btn--form py-4"
                disabled={loading}
            >
                {loading ? <CustomSpinner /> : btnName}
            </button>
        </div>
    );
};

export default EditProfileButton;
