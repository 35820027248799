import {gql} from '@apollo/client';

export const GET_PORTFOLIOS = gql`
    query GetPortfolio($id: String, $customerId: Int) {
        portfolio(id: $id, admin_account_id: $customerId) {
            costBasis
            gain
            marketPrice
            marketValue
            percentGain
            quantityHeld
            securityExchange
            priceValueMultiple
            securityLabel
            securityName
            securitySector
            valuation
            valueDate
            stock {
                price_change
                price_change_p
                previous_close
                price
            }
        }
    }
`;

export const GET_PORTFOLIO_ACCOUNTS = gql`
    query GetPortfolioAccount($customerId: Int) {
        portfolio_accounts(admin_account_id: $customerId) {
            account_no
            exchange
            id
            margin
            portfolio_type
            valuation
            name
            label
            margin_details {
                call_value
                equity_value
                min_maintain_value
                portfolio_value
                trading_power
            }
        }
        cash_balance(admin_account_id: $customerId) {
            balance
        }
        cash_accounts(admin_account_id: $customerId) {
            id
            label
            name
            balance
            currency
        }
    }
`;

// query MyQuery {
//   portfolio_accounts(admin_account_id: 111) {
//     exchange
//     name
//     valuation
//     margin
//     margin_details {
//       call_value
//       equity_value
//       min_maintain_value
//       portfolio_value
//       trading_power
//     }
//     portfolio_type
//     id
//     account_no
//   }
//   valuation(admin_account_id: 111) {
//     nasd
//     portfolio_value
//   }
// }
