import {useMutation} from '@apollo/client';
import Toast from 'utils/Alert/Toast/Toast';
import Modal from 'utils/Modal/Modal';
import CustomSpinner from 'utils/CustomSpinner/CustomSpinner';
import {getError} from 'utils/helpers';
import {SECONDARY, SUCCESS} from '../../../../constants';
import {DEACTIVATE_USER, UPDATE_USER_PROFILE} from 'api/mutations/customers';

const DeactivateProfileModal = ({toggle, id, active}) => {
    const [deactivateUser, deactivateUserQuery] = useMutation(DEACTIVATE_USER, {
        refetchQueries: ['getAllUsers'],
        onCompleted: () => {
            Toast({
                msg: 'Account deactivated successfully',
                position: 'top-right',
                variant: SUCCESS,
            });
            toggle();
        },
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    const [updateProfile, activateUserQuery] = useMutation(UPDATE_USER_PROFILE, {
        onCompleted: () => {
            Toast({
                msg: 'Account activated successfully',
                position: 'top-right',
                variant: SUCCESS,
            });
            toggle();
        },
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    const loading = activateUserQuery?.loading || deactivateUserQuery?.loading;

    const handleProfileUpdate = () => {
        active
            ? deactivateUser({variables: {id}})
            : updateProfile({variables: {userId: id, user: {blocked: false}}});
    };

    return (
        <Modal
            type={SECONDARY}
            show={true}
            title={active ? 'Deactivate Profile' : 'Activate Profile'}
            toggle={toggle}
        >
            <div className="text-center text--large-sm text--gray px-5">
                Are you sure you want to {active ? 'DEACTIVATE' : 'ACTIVATE'} this account?
            </div>
            <div className="flex__between flex-wrap pt-5">
                <div className="col-sm-6 col-12 mt-3">
                    <div className="col-sm-11 col-12">
                        <button
                            disabled={loading}
                            onClick={toggle}
                            className="btn btn--lg btn--primary fw-bold"
                        >
                            No
                        </button>
                    </div>
                </div>
                <div className="col-sm-6 col-12 flex__end mt-3">
                    <div className="col-sm-11 col-12">
                        <button
                            disabled={loading}
                            onClick={handleProfileUpdate}
                            className="btn btn--lg btn--danger--bordered fw-bold"
                        >
                            {loading ? <CustomSpinner cls="btn--danger--bordered" /> : 'Yes'}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DeactivateProfileModal;
