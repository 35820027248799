import React, {useState} from 'react';
import {Menu, MenuItem} from '@material-ui/core';

import {filterOptions} from '../../components/Dashboard/common/helpers/enums';

const Dropdown = ({
    action = () => {},
    cls,
    icon,
    id = 'dropdown',
    options = filterOptions,
    showText,
    text = 'Filter By:',
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpen = e => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    return (
        <div>
            <div className={`pointer ps-3 ${cls}`} onClick={handleOpen}>
                {icon}
            </div>
            <Menu
                id={id}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{marginTop: '40px'}}
                className="pointer"
                disableScrollLock
            >
                {showText && <div className="px-4 pb-3 text--normal fw-bold">{text}</div>}
                {options?.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            handleClose();
                            action(index);
                        }}
                        className="text--normal"
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default Dropdown;
