import Modal from '../../../../../utils/Modal/Modal';

import {NORMAL} from '../../../../constants';
import AddUserForm from './AddUserForm/AddUserForm';

const AddUserModal = ({show, toggle}) => {
    return (
        <Modal show={show} size="lg" title="Add a New User" toggle={toggle} type={NORMAL}>
            <AddUserForm toggle={toggle} />
        </Modal>
    );
};

export default AddUserModal;
