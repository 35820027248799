import Modal from 'utils/Modal/Modal';
import FileViewer from 'components/Dashboard/Requests/modals/FilePreviewModal/FileViewer';
import {SECONDARY} from '../../../../constants';

const FilePreviewModal = ({
    file,
    headerText,
    headerTitle,
    headerType = SECONDARY,
    onEnter,
    onHide,
    show,
    toggle,
}) => {
    const {file_url} = file || {};

    return (
        <Modal
            onEnter={onEnter}
            onHide={onHide}
            show={show}
            size="lg"
            text={headerText}
            title={headerTitle}
            toggle={toggle}
            type={headerType}
        >
            {file_url && <FileViewer cls="mt-0" url={file_url} />}
        </Modal>
    );
};

export default FilePreviewModal;
