import {Link} from 'react-router-dom';
import AccessControl from 'components/App/AccessControl/AccessControl';
import {ReactComponent as ChevronDown} from 'assets/images/icons/chevron-down.svg';

const SidebarDropdown = ({id, route, currentPath, activeRoute, hideSideBar}) => {
    return (
        <AccessControl key={route?.path} allowedPermissions={route?.accessLevel}>
            <li className="sidebar__nav__item">
                <span
                    className="sidebar__nav__item__link collapsed"
                    data-active={activeRoute(route.path, route.dropDowns)}
                    data-toggle="collapse"
                    data-target={`#collapse${id}`}
                    aria-expanded="false"
                    aria-controls={`collapse${id}`}
                >
                    <span className="mr-3">{<route.icon />}</span>
                    {route.name}
                    <ChevronDown height={12} width={12} className="ms-auto me-3" />
                </span>

                <ul
                    className="sidebar__nav__dropdown list-unstyled collapse"
                    id={`collapse${id}`}
                    data-parent="#sidebar__nav"
                >
                    {route.dropDowns.map((dropDown, i) =>
                        dropDown.hide ? null : (
                            <AccessControl
                                key={dropDown?.path}
                                allowedPermissions={dropDown?.accessLevel}
                            >
                                <li
                                    key={dropDown?.path}
                                    className="sidebar__nav__dropdown__item"
                                    data-active={dropDown.path === currentPath}
                                    onClick={hideSideBar}
                                >
                                    <Link
                                        to={dropDown.path}
                                        className="sidebar__nav__dropdown__link"
                                        data-active={currentPath.includes(dropDown.path)}
                                    >
                                        {dropDown.name}
                                    </Link>
                                </li>
                            </AccessControl>
                        )
                    )}
                </ul>
            </li>
        </AccessControl>
    );
};

export default SidebarDropdown;
