import React, {useState} from 'react';

import {ReactComponent as TrashIcon} from '../../../../../assets/images/icons/trash.svg';

import DeleteUserModal from '../../modals/DeleteUserModal/DeleteUserModal';

const DeleteUserButton = ({data}) => {
    const [openModal, setOpenModal] = useState(false);
    const toggleModal = () => setOpenModal(prev => !prev);

    return (
        <>
            <TrashIcon className="danger ms-4 pointer" onClick={toggleModal} />

            {openModal && <DeleteUserModal data={data} show={openModal} toggle={toggleModal} />}
        </>
    );
};

export default DeleteUserButton;
