import {useState} from 'react';
import {useQuery} from '@apollo/client';

//Components
import UsersTable from './UsersTable/UsersTable';
import AddNewButton from '../buttons/AddNewButton/AddNewButton';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import SectionWrapper from '../../../../utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from '../../../../utils/Section/SectionContainer/SectionContainer';

//Utils
import {transformFilters} from 'utils/helpers';
import {getRowSize} from 'storage/localStorage';
import {defaultFilterValues} from 'utils/helpers';
import {usersFilterParams, usersReportHeadings} from '../helpers/enums';
import {GET_ADMIN_ROLES, GET_ADMIN_USERS} from 'api/queries/adminUsers';

const Users = () => {
    const [query, setQuery] = useState({});

    const [filters, setFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(() => getRowSize());

    const [adminRoles, setAdminRoles] = useState([]);

    useQuery(GET_ADMIN_ROLES, {
        onCompleted: ({admin_role}) => {
            setAdminRoles([
                ...admin_role?.map(role => ({
                    label: role?.comment,
                    value: role?.value,
                })),
            ]);
        },
    });

    const getAdminUsersQuery = useQuery(GET_ADMIN_USERS, {
        variables: {
            created_at: 'asc',
            limit: parseInt(limit),
            offset: currentPage * limit,
            ...query,
        },
    });

    const handlePagination = ({selected}) => setCurrentPage(selected);

    const handleFilters = (filter = {}) => {
        const {data = {}} = filter;
        const newFilters = {...filters, ...data};
        setCurrentPage(0);
        setFilters(prev => ({...prev, ...data}));
        transformFilters(newFilters, usersFilterParams(adminRoles), setQuery);
    };

    const clearFilters = () => {
        const defaultFilters = defaultFilterValues(usersFilterParams(adminRoles));
        setFilters(prev => ({...prev, ...defaultFilters}));
        transformFilters(defaultFilters, usersFilterParams(adminRoles), setQuery);
        setCurrentPage(0);
    };

    const handleLimitChange = limit => {
        setCurrentPage(0);
        setLimit(limit);
    };

    return (
        <SectionContainer>
            <SectionWrapper
                filters={filters}
                filterId="users-filter"
                filterParams={usersFilterParams(adminRoles)}
                headerChildren={<AddNewButton />}
                // selectInputOptions={selectInputOptions}
                showDownload
                rawOptions
                clearFilters={clearFilters}
                handleFilters={handleFilters}
                query={GET_ADMIN_USERS}
                reportHeadings={usersReportHeadings}
                downloadKey="admin_user"
                title={`Admins (${getAdminUsersQuery?.data?.admin_user?.length || 0})`}
            >
                <ContentWrapper errorTitle="Error fetching users" currentQuery={getAdminUsersQuery}>
                    <UsersTable
                        limit={limit}
                        currentPage={currentPage}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                        data={getAdminUsersQuery?.data}
                    />
                </ContentWrapper>
            </SectionWrapper>
        </SectionContainer>
    );
};

export default Users;
