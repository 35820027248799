import {useEffect, useMemo, useState} from 'react';
import {useMutation} from '@apollo/client';
import {ReactComponent as HelpIcon} from 'assets/images/icons/help.svg';
import EditProfileButton from '../buttons/EditProfileButton/EditProfileButton';
import CustomInputBox from 'utils/CustomInputBox/CustomInputBox';
import CustomSelect from 'utils/Select/CustomSelect/CustomSelect';
import DeactivateProfileButton from '../buttons/DeactivateProfileButton/DeactivateProfileButton';
import {formValidator} from 'utils/FormValidator/FormValidator';
import {UPDATE_USER_PROFILE} from 'api/mutations/customers';
import Toast from 'utils/Alert/Toast/Toast';
import {SUCCESS} from 'components/constants';
import {getError} from 'utils/helpers';
import AccessControl from 'components/App/AccessControl/AccessControl';
import {allPermissions} from 'components/App/AccessControl/permissions';

const PersonalInfo = ({data}) => {
    const personalInfoFormDefaults = useMemo(() => {
        return {...data};
    }, [data]);
    const [disabled, setDisabled] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [request, setRequest] = useState(personalInfoFormDefaults);

    const toggleEdit = () => setDisabled(!disabled);

    const handleChange = ({target: {name, value}}) => handleSelect(name, value);

    const handleSelect = (name, value) => {
        setRequest(prev => ({
            ...prev,
            [name]: value,
        }));

        setFormErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const [updateProfile, {loading}] = useMutation(UPDATE_USER_PROFILE, {
        onCompleted: () => {
            Toast({
                msg: 'Personal profile updated successfully',
                position: 'top-right',
                variant: SUCCESS,
            });
            toggleEdit();
        },
        onError: error => Toast({msg: getError(error), position: 'top-right'}),
    });

    const handleSubmit = e => {
        e.preventDefault();
        if (disabled) {
            setFormErrors({});
            toggleEdit();
        } else {
            const {first_name, last_name, username, phone, email} = request;
            const payload = {first_name, last_name, username, phone, email};
            const {formErrors, isValid} = formValidator(payload);
            isValid
                ? updateProfile({variables: {userId: data?.id, user: payload}})
                : setFormErrors(formErrors);
        }
    };

    useEffect(() => {
        if (data) {
            setRequest(personalInfoFormDefaults);
        }
        // eslint-disable-next-line
    }, [data]);

    const accountTypeOptions = [
        {label: 'Individual', value: 'INDIVIDUAL'},
        {label: 'Organization', value: 'ORGANIZATION'},
    ];

    const personalInfoFormParams = [
        {
            name: 'first_name',
            label: 'First Name',
            required: true,
            defaultValue: data?.first_name,
        },
        {
            name: 'last_name',
            label: 'Last Name',
            required: true,
            defaultValue: data?.last_name,
        },
        {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: data?.username,
        },
        {
            name: 'phone',
            label: 'Phone Number',
            required: true,
            defaultValue: data?.phone,
        },
        {
            name: 'email',
            label: 'Email',
            required: true,
            defaultValue: data?.email,
        },
        {
            name: 'type_id',
            label: 'Account Type',
            defaultValue: data?.type_id,
            disable: true,
        },
        {
            name: 'securityQuestion',
            label: 'Security Question',
            labelIcon: <HelpIcon height="13px" width="13px" />,
            defaultValue: '',
            disable: true,
        },
        {
            name: 'securityAnswer',
            label: 'Security Answer',
            labelIcon: <HelpIcon height="13px" width="13px" />,
            defaultValue: '',
            disable: true,
        },
    ];

    return (
        <form>
            <div className="my-4 mx-4">
                <div className="d-flex flex-wrap">
                    {personalInfoFormParams.map((param, i) => {
                        const {
                            label,
                            labelIcon,
                            name,
                            options,
                            placeholder = 'Type Here',
                            required,
                            disable,
                        } = param;

                        if (options) {
                            return (
                                <CustomSelect
                                    key={i}
                                    disabled={disable || disabled}
                                    error={formErrors[name]}
                                    formGroupCls="wrapper__width_31_perc mx-3"
                                    label={label}
                                    labelIcon={labelIcon}
                                    name={name}
                                    onChange={value => handleSelect(name, value)}
                                    options={options}
                                    placeholder={placeholder}
                                    required={required}
                                    value={request[name]}
                                />
                            );
                        }

                        return (
                            <CustomInputBox
                                key={i}
                                cls="wrapper__width_31_perc mx-3"
                                disabled={disable || disabled}
                                error={formErrors[name]}
                                label={label}
                                labelIcon={labelIcon}
                                name={name}
                                onChange={handleChange}
                                required={required}
                                value={request[name]}
                            />
                        );
                    })}
                </div>
                <AccessControl allowedPermissions={[allPermissions.CAN_MODIFY_CUSTOMERS]}>
                    <div className="d-flex flex-wrap mt-4 ps-3 me-5">
                        <EditProfileButton
                            loading={loading}
                            formDisabled={disabled}
                            onClick={handleSubmit}
                            type="Personal Information"
                        />

                        <DeactivateProfileButton data={data} />
                    </div>
                </AccessControl>
            </div>
        </form>
    );
};

export default PersonalInfo;
