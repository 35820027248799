import TableCell from './TableCell';

const TableRow = ({rowValues, header, id, rowClick}) => {
    const tag = header ? 'th' : 'td';
    return (
        <tr id={id} onClick={() => rowClick(rowValues?.id)} className={rowClick ? 'pointer' : ''}>
            {Object.values(rowValues).map((cellValues, i) => (
                <TableCell key={i} cellValues={cellValues} tag={tag} />
            ))}
        </tr>
    );
};

export default TableRow;
