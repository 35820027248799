import {gql} from '@apollo/client';

export const GET_WATCHLIST = gql`
    query getWatchList($id: Int) {
        watchlist(where: {user_id: {_eq: $id}}) {
            name
            watchlist_items {
                created_at
                id
                stock_name
                user_id
                stock {
                    currency
                    exchange
                    label
                    logo_url
                    name
                    price_change
                    price_change_p
                    price
                }
            }
        }
    }
`;
