import React from 'react';
import SectionWrapper from 'utils/Section/SectionWrapper/SectionWrapper';
import SectionContainer from 'utils/Section/SectionContainer/SectionContainer';
import {useQuery} from '@apollo/client';
import usePageProps from 'hooks/usePageProps';
import routes from 'components/Dashboard/routes';
import PortfolioReportTable from './PortfolioReportTable/PortfolioReportTable';
import AddReportButton from '../buttons/AddNewButton/AddReportButton';
import {GET_PORTFOLIO_AGGREGATE} from 'api/queries/report';
import {reportsFilterParams} from '../helpers/enums';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import {defaultDateFilters} from 'components/constants';
import AccessControl from 'components/App/AccessControl/AccessControl';
import {allPermissions} from 'components/App/AccessControl/permissions';

const PortfolioReportContent = () => {
    const researchRoutes = routes.find(route => route.name === 'Research');

    const {
        limit,
        query,
        filters,
        currentPage,
        handlePagination,
        handleFilters,
        clearFilters,
        handleLimitChange,
    } = usePageProps({
        filterParams: reportsFilterParams,
        defaultFilters: defaultDateFilters,
    });

    const queryParams = {
        variables: {
            limit: parseInt(limit),
            offset: currentPage * limit,
            ...query,
            time_range: {...query?.from, ...query?.to},
        },
    };

    const getReportQuery = useQuery(GET_PORTFOLIO_AGGREGATE, {
        ...queryParams,
    });

    return (
        <SectionContainer>
            <SectionWrapper
                filters={filters}
                clearFilters={clearFilters}
                handleFilters={handleFilters}
                routes={researchRoutes}
                headerChildrenCls="col-lg-6"
                headerChildren={
                    <AccessControl allowedPermissions={[allPermissions.CAN_ADD_PORTFOLIO_REPORTS]}>
                        <AddReportButton />
                    </AccessControl>
                }
                navWideMobile
                showDownload
                defaultFilters={defaultDateFilters}
                filterParams={reportsFilterParams}
                filterId="research-filter"
            >
                <ContentWrapper
                    errorTitle="Error fetching portfolio report"
                    currentQuery={getReportQuery}
                >
                    <PortfolioReportTable
                        limit={limit}
                        currentPage={currentPage}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                        data={getReportQuery?.data}
                    />
                </ContentWrapper>
            </SectionWrapper>
        </SectionContainer>
    );
};

export default PortfolioReportContent;
