import React from 'react';
import {toCurrency} from 'utils/helpers';

const ValuationBalance = ({portfolios = [], account = {}, cash_balance}) => {
    const isMargin = account?.margin === true;
    const margin = account?.margin_details || {};

    const totalBalance = cash_balance?.balance + account?.valuation;

    const totalGain = portfolios?.reduce((acc, arr, i) => {
        return acc + arr['gain'];
    }, 0);

    const balances = [
        {
            title: 'Total Valuation',
            value: account?.valuation,
        },
        {
            title: 'Total P/L',
            value: Math.abs(totalGain).toFixed(2),
            valueCls: totalGain < 0 ? 'text--danger' : 'text--success',
        },
        {
            title: 'Available Balance',
            value: cash_balance?.balance || 0,
        },
        {
            title: 'Total Balance',
            value: totalBalance || 0,
        },
    ];

    const marginBalances = [
        {
            title: 'Total Valuation',
            value: account?.valuation,
        },
        {
            title: 'Purchasing Power',
            value: margin?.trading_power,
        },
        {
            title: 'Equity Value',
            value: margin?.equity_value,
        },
        {
            title: 'Maintenance Value',
            value: margin?.min_maintain_value,
        },
        {
            title: 'Available Balance',
            value: cash_balance?.balance,
        },
        {
            title: 'Total Balance',
            value: totalBalance,
        },
    ];

    const currentBalance = isMargin ? marginBalances : balances;

    return (
        <>
            {currentBalance?.map(({title, value, valueCls}) => (
                <div
                    key={title}
                    className={`fw-bold wrapper__mt-4-mobile col-2 flex__center flex__column wrapper__mb-4-mobile ${
                        isMargin ? 'col-2' : 'col-3'
                    }`}
                >
                    <div className="pb-2">{title}</div>
                    <div className={`text--large-md ${valueCls}`}>₦{toCurrency(value)}</div>
                </div>
            ))}
        </>
    );
};

export default ValuationBalance;
