import {STOCK_NGX, STOCK_NASD, MARGIN, ACCEPT, DECLINE} from '../../../constants';

// TabLists

export const requestsTabList = ['pending', 'approved'];

// Headings

export const requestsHeading = [' ', 'Name', 'Email', 'User ID', 'Date Sent', 'File Attached'];

// Options

export const selectOptions = [STOCK_NGX, STOCK_NASD, MARGIN];

export const requestOptions = [ACCEPT, DECLINE];

export const filterOptions = ['Equity', 'Quality', 'Company'];

export const requestFilterParams = [
    {
        name: 'name',
        label: 'Name',
        query: '_iregex',
    },
    {
        name: 'email',
        label: 'Email',
        query: '_iregex',
    },
    {
        name: 'fileAttachedName',
        label: 'File Attached Name',
        query: '_iregex',
    },
    {
        name: 'from',
        label: 'Start Date',
        query: '_gte',
        isDate: true,
    },
    {
        name: 'to',
        label: 'End Date',
        query: '_lte',
        isDate: true,
    },
];

export const requestsReportHeadings = {
    id: 'Request ID',
    'user.first_name': 'Firstname',
    'user.last_name': 'Lastname',
    created_at: 'Date Sent',
    status: 'Status',
};
