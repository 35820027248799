import {gql} from '@apollo/client';

export const GET_MARGIN_REQUESTS = gql`
    query GetMarginRequest(
        $limit: Int
        $offset: Int
        $name: String_comparison_exp = {}
        $email: String_comparison_exp = {}
        $time_range: timestamptz_comparison_exp = {}
        $fileAttachedName: String_comparison_exp = {}
        $status: margin_request_status_enum_comparison_exp = {}
    ) {
        margin_request(
            limit: $limit
            offset: $offset
            where: {
                status: $status
                agreement_url: $fileAttachedName
                created_at: $time_range
                user: {email: $email, _or: [{first_name: $name}, {last_name: $name}]}
            }
        ) {
            id
            message
            status
            agreement_url
            created_at
            user {
                id
                first_name
                last_name
                email
            }
        }
        margin_request_aggregate(
            where: {
                status: $status
                agreement_url: $fileAttachedName
                created_at: $time_range
                user: {email: $email, _or: [{first_name: $name}, {last_name: $name}]}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;
