//SVG Icons
import {ReactComponent as UserIcon} from '../../../../../assets/images/icons/user.svg';

//Components
import Table from 'utils/Table/Table';
import EmptyData from 'utils/Table/EmptyData';
import TableFooter from 'utils/Table/TableFooter/TableFooter';
import AddNewButton from '../../buttons/AddNewButton/AddNewButton';
import AccessControl from 'components/App/AccessControl/AccessControl';
import DeleteUserButton from '../../buttons/DeleteUserButton/DeleteUserButton';

//Utils
import {timeDateFormat} from 'utils/helpers';
import {usersHeadings} from '../../helpers/enums';
import {allPermissions} from 'components/App/AccessControl/permissions';
import EditUserButton from '../../buttons/EditUserButton/EditUserButton';

const DateCreatedCell = ({dateCreated, data}) => (
    <div className="flex__between">
        <div>{`${timeDateFormat(dateCreated).date} ${timeDateFormat(dateCreated).time}`}</div>

        <div className="ms-5">
            <AccessControl allowedPermissions={[allPermissions.CAN_MODIFY_ADMIN]}>
                <EditUserButton className="me-3 pointer" data={data} />
                <DeleteUserButton data={data} />
            </AccessControl>
        </div>
    </div>
);

const UsersTable = ({data, limit, currentPage, handleLimitChange, handlePagination}) => {
    const {
        admin_user,
        admin_user_aggregate: {
            aggregate: {count: total},
        },
    } = data;

    const len = total === 0 || !Boolean(admin_user.length);

    const totalPages = Math.ceil(total / limit) || 1;

    const getAdminsData = () =>
        admin_user?.map(admin => ({
            icon: <UserIcon height="32px" width="32px" />,
            role: admin?.admin_role?.comment,
            email: admin?.email,
            username: `${admin?.first_name} ${admin?.last_name}`,
            date: <DateCreatedCell dateCreated={admin?.created_at} id={admin?.id} data={admin} />,
        }));

    return (
        <div
            className="tab-pane fade show active"
            id="corporate_benefits"
            role="tabpanel"
            aria-labelledby="corporate_benefits-tab"
        >
            {!len ? (
                <>
                    <Table
                        cls="mt-3"
                        data={getAdminsData()}
                        headings={usersHeadings}
                        rowClick={() => {}}
                    />
                    <TableFooter
                        currentPage={currentPage || 0}
                        totalPages={totalPages}
                        handlePagination={handlePagination}
                        handleLimitChange={handleLimitChange}
                    />
                </>
            ) : (
                <EmptyData
                    title="No Users Yet"
                    subtitle="All Users will be displayed here"
                    btn={<AddNewButton />}
                />
            )}
        </div>
    );
};

export default UsersTable;
