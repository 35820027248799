import {gql} from '@apollo/client';

export const GET_USERS = gql`
    query getAllUsers(
        $limit: Int
        $offset: Int
        $created_at: order_by
        $where: user_bool_exp
        $val: String_comparison_exp = {}
        $email: String_comparison_exp = {}
        $username: String_comparison_exp = {}
        $accountType: user_type_enum_comparison_exp = {}
    ) {
        user(
            limit: $limit
            offset: $offset
            where: {
                username: $username
                email: $email
                type_id: $accountType
                _or: [{first_name: $val}, {last_name: $val}]
            }
            order_by: {created_at: $created_at}
        ) {
            email
            id
            blocked
            username
            phone
            title
            first_name
            last_name
            type_id
            secret_question_id
            investment_profile_id
            kyc_information {
                mothers_maiden_name
                nationality
                lga_of_origin
                state_of_origin
                residence_city
                residence_state
                residence_street
                postal_code
                employer_address
                employer_name
                profession
                marital_status
                religion
                facial_comparison_confidence
            }
            kyc_document {
                id
                identity
                passport
                signature
                utility
            }
            account {
                account_name
                account_number
                bank_name
                currency
                margin
            }
            investment_profile {
                investment_knowledge {
                    comment
                    value
                }
                investment_objective {
                    comment
                    value
                }
                source_of_wealth {
                    comment
                    value
                }
                risk_tolerance {
                    comment
                    value
                }
                net_worth
                risk_tolerance_id
                annual_income
            }
            user_identity {
                bvn
                nin
            }
            settlement_details {
                account_name
                account_number
                bank_name
                reference
                active
                id
                user_id
            }
            middle_name
            dob
            gender
        }

        user_aggregate(
            where: {
                username: $username
                email: $email
                type_id: $accountType
                _or: [{first_name: $val}, {last_name: $val}]
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_DOWNLOAD_URL = gql`
    query getDownloadUrl($path: String!) {
        admin_get_document(path: $path) {
            url
        }
    }
`;

export const GET_NEXT_OF_KIN = gql`
    query getNextOfKin($customerId: Int) {
        next_of_kin(where: {user_id: {_eq: $customerId}}) {
            name
            email
            phone
            relationship
            address
        }
    }
`;
