// Options

export const filterOptions = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Annually'];

export const walletOptions = ['Cash Wallet', 'Available Wallet'];

// Component Enums

export const walletStats = [
    {
        title: 'Total Wallet Outflow',
        total: 86493441098.64,
        today: 983373.5,
        pillCls: 'danger__light',
        textCls: 'text--danger',
        arrowCls: 'danger',
    },
    {
        title: 'Total Wallet Balance',
        total: 8547369432.23,
        today: 9583207.65,
        pillCls: 'gray__light',
        textCls: 'text--gray',
        arrowCls: 'gray',
    },
];

export const walletMeta = {
    title: 'Wallet Balance',
    total: 4601088.5,
    pillCls: 'primary__light',
};
