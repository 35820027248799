import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {titleCase} from '../helpers';

const NavTabs = ({routes, cls, iconList, id, navId = 'myTab', navWideMobile}) => (
    <span className={`nav-tabs-container ${navWideMobile ? 'nav-wide-mobile' : ''}`}>
        <ul className={`nav nav-tabs wrapper__overflow ${cls}`} id={navId} role="tablist">
            {routes?.dropDowns?.map(({name, path}, i) => {
                const icon = iconList ? iconList[i] : '';
                const indexedID = id ? `${name}${id}` : name;
                const tab = titleCase(name);
                return <Tab id={indexedID} tab={tab} Icon={icon} key={i} path={path} />;
            })}
        </ul>
    </span>
);

const Tab = ({id, tab, Icon, path}) => {
    const {pathname} = useLocation();

    return (
        <NavLink className="nav-item " to={path} role="presentation">
            {/* pe-sm-5 pe-4 */}
            <li className="nav-item" role="presentation">
                <button
                    className={`nav-link ${pathname === path ? 'active' : ''}`}
                    id={`${id}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${id}`}
                    role="tab"
                    aria-controls={id}
                >
                    {Icon && <Icon className="me-1 mb-1" />} {tab}
                </button>
            </li>
        </NavLink>
    );
};

// const NavTabs = ({
//   actionList,
//   btnList,
//   cls,
//   rawTitle,
//   iconList,
//   id,
//   navId = "myTab",
//   navWideMobile,
//   tabList = [],
// }) => (
//   <span
//     className={`nav-tabs-container ${navWideMobile ? "nav-wide-mobile" : ""}`}
//   >
//     <ul
//       className={`nav nav-tabs wrapper__overflow ${cls}`}
//       id={navId}
//       role="tablist"
//     >
//       {tabList?.map((name, i) => {
//         const icon = iconList ? iconList[i] : "";
//         const action = actionList ? actionList[i] : null;
//         const indexedID = id ? `${name}${id}` : name;
//         const tab = btnList
//           ? btnList[i]
//           : `${rawTitle ? name : titleCase(name)}`;
//         return i === 0 ? (
//           <Tab
//             active="active"
//             id={indexedID}
//             tab={tab}
//             Icon={icon}
//             key={i}
//             onClick={action}
//           />
//         ) : (
//           <Tab id={indexedID} tab={tab} Icon={icon} key={i} onClick={action} />
//         );
//       })}
//     </ul>
//   </span>
// );

// const Tab = ({ active, id, tab, Icon, onClick }) => (
//   <li className="nav-item" role="presentation">
//     <button
//       className={`nav-link ${active}`}
//       id={`${id}-tab`}
//       data-bs-toggle="tab"
//       data-bs-target={`#${id}`}
//       role="tab"
//       aria-controls={id}
//       onClick={onClick}
//     >
//       {Icon && <Icon className="me-1 mb-1" />} {tab}
//     </button>
//   </li>
// );

export default NavTabs;
