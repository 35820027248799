import {Route, Redirect, withRouter} from 'react-router-dom';
import {fetchUserData} from '../../../storage/sessionStorage';

const PublicRoutes = ({component: Component, ...rest}) => {
    const {token} = fetchUserData();

    return (
        <Route
            {...rest}
            render={props => (token ? <Redirect to="/" /> : <Component {...props} />)}
        />
    );
};

export default withRouter(PublicRoutes);
