import React from 'react';
import {Accordion} from 'react-bootstrap';

const CustomAccordion = ({
    btnList,
    cls,
    defaultActiveKey = undefined,
    id = 'meritrade-admin-accordion',
    itemsList,
}) => (
    <Accordion className="w-100" defaultActiveKey={defaultActiveKey} id={id}>
        {itemsList.map((item, index) => {
            const customBodyCls = cls ? `${cls}-body` : '';
            const customHeaderCls = cls ? `${cls}-header` : '';
            const borderBottomCls =
                index !== itemsList.length - 1 ? 'border__bottom__gray__light' : '';
            return (
                <Accordion.Item className="custom-accordion-item" eventKey={`${index}`}>
                    <div className={`d-flex py-4 ${borderBottomCls} ${customHeaderCls}`}>
                        <div className="flex__between w-100">
                            <span className="fw-bold ms-4">{item?.title}</span>
                            {btnList && <div>{btnList.map(btn => btn)}</div>}
                        </div>

                        <Accordion.Header></Accordion.Header>
                    </div>
                    <Accordion.Body className={`accordion-body ${customBodyCls}`}>
                        {item?.body}
                    </Accordion.Body>
                </Accordion.Item>
            );
        })}
    </Accordion>
);

export default CustomAccordion;
