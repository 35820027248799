import {useState} from 'react';

import {ReactComponent as EditIcon} from 'assets/images/icons/edit.svg';

import EditUserModal from '../../modals/EditUserModal/EditUserModal';

const EditUserButton = ({data}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);
    return (
        <>
            <EditIcon className="me-3 pointer" onClick={toggleModal} />
            {isOpen && <EditUserModal show={isOpen} toggle={toggleModal} data={data} />}
        </>
    );
};

export default EditUserButton;
