import React from 'react';
import {useQuery} from '@apollo/client';
import {AGGREGATE_COUNT} from 'api/queries/dashboard';
import ContentWrapper from 'utils/ContentWrapper/ContentWrapper';
import Stat from 'utils/Stat/Stat';
import {Icons} from '../helpers';
import {toCurrency} from 'utils/helpers';

const StatsRow = () => {
    const getCountAggregateQuery = useQuery(AGGREGATE_COUNT);

    const {allUsers, organizationUsers, payment_transaction_aggregate} =
        getCountAggregateQuery?.data || {};

    return (
        <div className="d-flex wrapper__overflow wrapper__stats-row">
            <ContentWrapper errorTitle="Error fetching data" currentQuery={getCountAggregateQuery}>
                <Stat
                    data={{
                        title: 'Total Customers',
                        icon: <Icons.CustomerIcon />,
                        progress: 65,
                        value: allUsers?.aggregate?.count,
                    }}
                />
                <Stat
                    data={{
                        title: 'Total Companies',
                        icon: <Icons.CompanyIcon />,
                        progress: 30,
                        value: organizationUsers?.aggregate?.count,
                    }}
                />
                <Stat
                    data={{
                        title: 'Total Transactions',
                        icon: <Icons.SwapIcon />,
                        progress: 87,
                        value: `₦${toCurrency(
                            payment_transaction_aggregate?.aggregate?.sum?.amount
                        )}`,
                    }}
                />
                <Stat
                    data={{
                        title: 'Total Cash Volume',
                        icon: <Icons.CustomerIcon />,
                        progress: 54,
                        value: `₦${toCurrency(35658473)}`,
                    }}
                />
            </ContentWrapper>
        </div>
    );
};

export default StatsRow;
